import request from "../../utils/request";
// 获取预约配置

/**
 * 获取预约配置
 * @param {object} params
 * @param {string} params.unitId  -单位ID 必填
 */
export function getOrderSetList(params) {
  return request("/orderSet/getOrderSetList", {
    method: "get",
    params: params
  });
}


/**
 * 新增预约配置
 * @param {object} params
 * @param {int} params.ShowAndriod   -是否在安卓APP显示预约信息 0 显示 1 不显示   必填
 * @param {int} params.ShowDays      -可展示的预约天数,1为当天                   必填
 * @param {int} params.ShowIOS       -是否在苹果显示预约信息 0 显示 1 不显示      必填
 * @param {int} params.ShowNet       -是否是否在网页上显示预约信息 0 显示 1 不显示 必填
 * @param {int} params.ShowWeixin    -是否在安卓APP显示预约信息 0 显示 1 不显示   必填
 * @param {int} params.ShowAndriod   -是否在微信显示预约信息 0 显示 1 不显示      必填
 */
export function modifyOrderSet(params) {
    return request("/orderSet/modifyOrderSet", {
      method: "post",
      params: params
    });
  }
  

/**
 * 新增预约配置
 * @param {object} params
 * @param {int} params.DenyDateBegin -锁定日期 如20190308必填
 * @param {int} params.DenyDateEnd   -锁定日期止 如20190308必填
 * @param {int} params.UserId        -用户ID(18)      必填
 * @param {int} params.status        -状态 0 正常 1 注销
 * @param {string} params.unitId  -单位ID 必填
 */
export function addOrderDenySet(params) {
    return request("/orderSet/addOrderDenySet", {
      method: "post",
      params: params
    });
  }  

/**
 * 解锁（删除）预约锁定配置日期
 * @param {object} params
 * @param {int} params.rowId  -行号必填
 * @param {int} params.unitId -单位ID（int）必填
 */
export function delOrderDenySet(params) {
    return request("/orderSet/delOrderDenySet", {
      method: "post",
      params: params
    });
  }  


/**
 * 解锁（删除）预约锁定配置日期
 * @param {object} params
 * @param {int} params.unitId -单位ID（int）必填
 */
export function getOrderDenySetList(params) {
    return request("/orderSet/getOrderDenySetList", {
      method: "get",
      params: params
    });
  }

/**
 *设置参与预约的车辆
 * @param {object} params
 * @param {int} params.unitId -单位ID（int）必填
 */
export function postSetJoinOrder(params) {
    return request("/orderSet/setJoinOrder", {
        method: "post",
        params: params
    });
}