import {
    getList,
    getCount,
    devData,
    changeSim,
    getparameterSetting,
    setDevParams,
    devAdd
} from "@/service/dev/index";
import {
    getLastLocation
} from "@/service/vehicleStatus/index";
// import moment from 'moment';
export default {
    name: "devManagemen",
    data() {
        return {
            //列表显示
            devManagemen: {
                add: {
                    unitId: sessionStorage.getItem('unitId'),
                    factory: '成都网阔信息技术股份有限公司',
                },
                list: {
                    query: {
                        imei: '',
                        pageIndex: 1,
                        pageSize: 10,
                        sim: '',
                        status: 0,
                        unitId: sessionStorage.getItem('unitId'),
                        //session 取
                        // unitId: 1,
                        veh: '',
                        devId: '',
                        vehNo: 0,
                    },
                    total: 0,
                    results: []
                },
                parameterSetting: {
                    devNum: '',
                    imei: '',
                    efence: '',
                    face: '',
                    locationSpan: '',
                    returnTime: '',
                    shotSpan: '',
                    speedLimit: '',
                    workBegin: '',
                    workEnd: '',
                    workTimeout: '',
                    eFenceStart: ''
                },
                onlineArr: [],
                modifyEquipment: false,
                parameterSettingVisible: false,
                addModData: false
            },
            basicTwoForm: this.$form.createForm(this),
            basicOneForm: this.$form.createForm(this),
            basicForm: this.$form.createForm(this),
            //表头
            columns: [
                {
                    width: 75,
                    title: "序号",
                    align: "center",
                    scopedSlots: {
                        customRender: "SerialNumber"
                    }
                },
                {
                    title: "设备号",
                    dataIndex: "imei",
                },
                {
                    title: "手机号",
                    dataIndex: "sim",
                },
                {
                    title: "车牌号",
                    dataIndex: "veh",
                },
                {
                    title: "车号",
                    dataIndex: "vehNo",
                },
                {
                    title: "科目",
                    dataIndex: "stage",
                },
                {
                    title: "车辆状态",
                    dataIndex: "status",
                    customRender: (item) => {
                        return this.$enums.devStatus[item]
                    }

                },
                {
                    title: "操作",
                    scopedSlots: {
                        customRender: "operation"
                    }
                }
            ],
            isOpen: {
                eFenceStart: false,
                faceStart: false
            }
        }
    },
    methods: {
        moment() {

        },
        onSearchh(e) {
            this.devManagemen.list.query.vehNo = e.target.value ? e.target.value : 0;
        },
        querydevManag(queryBy) {
            let querys = this.devManagemen.list.query;
            // 判断是否为点击页码查询
            if (queryBy !== "pagination") {
                querys.pageIndex = 1;
            }
            getCount(querys).then((res) => {
                if (res.data.rowCount) {
                    this.devManagemen.list.total = res.data.rowCount;
                    getList(querys).then((res) => {
                        if (res.data.length) {
                            this.$nextTick(() => {
                                this.devManagemen.list.results = res.data;
                            })
                        }
                    }).catch((err) => {
                        console.log('err', err)
                    })
                } else {
                    this.$nextTick(() => {
                        this.devManagemen.list.results = [];
                        this.devManagemen.list.total = 0;
                    })
                }
            }).catch((err) => {
                console.log('err', err)
            })
        },
        devdelData(record) {
            let { devId } = record;
            let query = { devId, unitId: this.devManagemen.list.query.unitId }
            // console.log('query',query);
            devData(query).then((res) => {
                if (res.message) {
                    this.$message.error(res.message)
                } else {
                    this.$message.success('注销成功');
                }
            }).catch((err) => {
                console.log('err', err);
            })
        },
        async  parameterSetting(record) {
            await this.getLastLocation();
            let arr = this.devManagemen.onlineArr.filter(item => {
                return item.imei == record.imei
            });
            if(arr.length){
                if(arr[0].online == 0){
                    this.$message.info('此车不在线,可能导致参数设置失败');
                }
            }
            this.devManagemen.parameterSetting.imei = record.imei;
            this.devManagemen.parameterSetting.devNum = record.unifyCode;
            let query = { imei: record.imei, devNum: record.unifyCode }
            getparameterSetting(query).then((res) => {
                if(res.data){
                    if (res.data.workBegin == 86400) {
                        res.data.workBegin = 86340;
                    }
                    if (res.data.workEnd == 86400) {
                        res.data.workEnd = 86340;
                    }
                    let workBegin = this.showTime(res.data.workBegin);
                    let workEnd = this.showTime(res.data.workEnd);
                    this.basicOneForm.setFieldsValue({
                        locationSpan: res.data.locationSpan / 60,
                        returnTime: res.data.returnTime / 60,
                        cameralSpan: res.data.cameralSpan / 60,
                        limitSpeed: res.data.limitSpeed,
                        workBegin: this.$moment(workBegin, 'HH:mm'),
                        workEnd: this.$moment(workEnd, 'HH:mm'),
                        workTimeOut: res.data.workTimeOut,
                        // eFenceStart:res.data.eFenceStart? true : false,
                        // faceStart:res.data.faceStart? true : false,
                    });
                    this.isOpen.eFenceStart = res.data.eFenceStart ? true : false;
                    this.isOpen.faceStart = res.data.faceStart ? true : false;
                }
            })
            this.devManagemen.parameterSettingVisible = true
        },
        // 获取车辆在线数
        async getLastLocation() {
            let query = { unitId: sessionStorage.getItem('unitId') }
            await getLastLocation(query).then(res => {
                if (res) {
                    this.devManagemen.onlineArr = [...res.data]
                }
            });
        },
        showTime(val) {
            if (val < 60) {
                if (val > 10) {
                    return `00:${val}`;
                } else {
                    return `00:0${val}`;
                }
            } else {
                var min_total = Math.floor(val / 60); // 分钟
                var sec = Math.floor(val % 60); // 余秒
                if (min_total < 60) {
                    if (min_total >= 10) {
                        return `00:${min_total}`
                    } else {
                        return `00:0${min_total}`
                    }
                } else {
                    var hour_total = Math.floor(min_total / 60); // 小时数
                    var min = Math.floor(min_total % 60); // 余分钟
                    if (hour_total >= 10) {
                        if (min >= 10) {
                            return `${hour_total}:${min}`;
                        } else {
                            return `${hour_total}:0${min}`;
                        }
                    } else {
                        if (min >= 10) {
                            return `0${hour_total}:${min}`;
                        } else {
                            return `0${hour_total}:0${min}`;
                        }
                    }
                }
            }
        },
        okParameterSetting() {
            this.basicOneForm.validateFields((err, value) => {
                if (!err) {
                    this.devManagemen.parameterSetting.imei;
                    this.devManagemen.parameterSetting.devNum;
                    let querys = { ...value, ...{ faceStart: this.isOpen.faceStart ? 1 : 0, imei: this.devManagemen.parameterSetting.imei, devNum: this.devManagemen.parameterSetting.devNum, eFenceStart: this.isOpen.eFenceStart ? 1 : 0 } }
                    querys.workBegin = this.formatSec(querys.workBegin);
                    if (querys.workBegin == 86340) {
                        querys.workBegin = 86400;
                    }
                    querys.workEnd = this.formatSec(querys.workEnd);
                    if (querys.workEnd == 86340) {
                        querys.workEnd = 86400;
                    }
                    querys.locationSpan = value.locationSpan * 60;
                    querys.returnTime = value.returnTime * 60;
                    querys.cameralSpan = value.cameralSpan * 60;
                    querys.workTimeOut = value.workTimeOut * 60;
                    // querys.eFenceStart=querys.eFenceStart?1:0;
                    // querys.faceStart=querys.faceStart?1:0;
                    // querys.shotSpan=querys.cameralSpan;
                    // querys.speedLimit =querys.limitSpeed;
                    setDevParams(querys).then(res => {
                        if (!res.message) {
                            this.$message.success('参数设置成功');
                            this.devManagemen.parameterSettingVisible = false;
                            this.handleFormFieldsReset(this.basicOneForm);
                        }
                        else {
                            this.$message.error(res.message)
                        }
                    })
                }
            })
        },
        //转换时间
        formatSec(data) {
            let hour = this.$moment(data).format('HH');
            let min = this.$moment(data).format('mm');
            let transh = hour.toString().substr(0, 1) === '0' ? hour.toString().substr(1, 1) : hour.toString();
            let workBegin = Number(transh) * 3600 + Number(min) * 60;
            return workBegin;
        },
        modifyData(record) {
            this.devManagemen.modifyEquipment = true;
            this.devManagemen.optionItem = 'modifyData';
            this.$nextTick(() => {
                this.devManagemen.list.query.devId = record.devId;
                this.basicForm.setFieldsValue({
                    imei: record.imei,
                    sim: record.sim,
                });
            })
        },
        handleOk() {
            let querys = this.devManagemen.list.query;
            this.basicForm.validateFields((err, values) => {
                if (!err) {
                    values.unitId = querys.unitId;
                    values.devId = querys.devId;
                    // values.sim =querys.sim;
                    changeSim(values).then((res) => {
                        if (res.message.length) {
                            this.$message.error(res.message)
                        } else {
                            this.resetInfo();
                            this.$message.success('修改成功');
                        }
                    }).catch((error) => {
                        console.log(error);
                    })
                }
            });
        },
        resetInfo() {
            this.querydevManag();
            this.devManagemen.modifyEquipment = false;
            this.handleFormFieldsReset(this.basicForm);
        },
        //设备注册
        addModifyData() {
            this.devManagemen.addModData = true;
        },
        addModify() {
            let querys = this.devManagemen.add;
            this.basicTwoForm.validateFields((err, values) => {
                if (!err) {
                    values.unitId = sessionStorage.getItem('unitId');
                    values.factory = querys.factory;
                    devAdd(values).then((res) => {
                        if (!res.message) {
                            this.$message.success("设备注册成功");
                            this.basicForm = '',
                                this.devManagemen.addModData = false;
                            this.querydevManag();
                        }
                        else {
                            this.$message.error(res.message);
                        }
                    })
                }
            })
        },
    },
    mounted() {
        this.querydevManag();
        this.isFav();
    },
    activated() {
        this.isFav();
    }
}
