export default {
    name: "shortTrainMonth",
    data() {
        return {
            shortTrainMonth: {
                list: {
                    query: {
                        projectItem: undefined,
                        trainingGround: undefined,
                        unitId:sessionStorage.getItem('unitId'),
                        userId: sessionStorage.getItem('userId')
                    },
                    total: 0,
                    results: [{
                        play: '塘汛',
                        busNum: '350005616',
                        school: '久安',
                        safer: '陈刚强',
                        carNumber: '川A123456',
                        gmtStart: '10：00',
                        gmtEnd: '11：00',
                        money: '35',
                        status: '已完成',
                        maker: '张琳',
                        remark: 'xx',
                    }],
                },
                daterange: [],
                columns: [
                    {
                        width: 75,
                        title: "序号",
                        align: "center",
                        scopedSlots: {
                            customRender: "SerialNumber"
                        },
                    },
                    {
                        title: "训练场地",
                        dataIndex: "play",
                        width: 150,
                    },
                    {
                        title: "业务编号",
                        dataIndex: "busNum",
                        width: 150,
                    },
                    {
                        title: "驾校",
                        dataIndex: "school",
                        width: 150,
                    },
                    {
                        title: "安全员",
                        dataIndex: "coachName",
                        width: 150,
                    },
                    {
                        title: "车牌号",
                        dataIndex: "carNumber",
                        width: 150,
                    },
                    {
                        title: "开始时间",
                        dataIndex: "gmtStart",
                        width: 150,
                    },
                    {
                        title: "结束时间",
                        dataIndex: "gmtEnd",
                        width: 150,
                    },
                    {
                        title: "短训金额",
                        dataIndex: "money",
                        width: 150,
                    },
                    {
                        title: "状态",
                        dataIndex: "status",
                        width: 150,
                    },
                    {
                        title: "制票人",
                        dataIndex: "maker",
                        width: 150,
                    },
                    {
                        title: "备注",
                        dataIndex: "remark",
                        width: 150,
                    }
                ]
            },
            userId:sessionStorage.getItem('userId')
        }
    },
    methods: {
        queryShortTrain() {
            let querys = this.shortTrainMonth.list.query;
            // console.log('querys in querys', querys)
        }
    },
    created() {
   
    },
    mounted() {
        // if(!this.$route.meta.keepAlive){
        //     this.isFav();
        //     this.queryShortTrain();
        //   }
          this.isFav();
          this.queryShortTrain();
    },
    activated(){
         this.isFav();
        //  this.queryShortTrain();
      }
    
};