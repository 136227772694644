import {
    getAuditList,
    getAuditCount,
    deny,
    pass
} from "@/service/charge/index";
import {
    allocateVeh,
    getInCompleteWork,
    getTodayWork,
    getVehListInnerDev,
} from "@/service/vehicleStatus/index";
import {
    getTeaList,
    getCount
} from "@/service/resManagement/index";
import { mapActions } from 'vuex';

export default {
    //新增订单
    name: "businessAudit",
    data() {
        return {
            vehicleStatus: {
                list: {
                    query: {
                        pageIndex: 1,
                        pageSize: 500,
                        //stage 默认0为全部
                        stage: 0,
                        //starId 默认-1为全部
                        starId: -1,
                        status: '',
                        unitId: sessionStorage.getItem('unitId'),
                        veh: '',
                        vehNo: '',
                        vehType: '',
                        tongguo: '通过'
                    },
                    total: 0,
                    results: [],
                    stage2res: [],
                    stage3res: [],
                },
                stage2: {
                    checkedList: ['C1', 'C2', 'B1', 'B2', 'A2', 'A1'],
                    indeterminate: true,
                    checkAll: true,
                    plainOptions: ['C1', 'C2', 'B1', 'B2', 'A2', 'A1'],
                },
                stage3: {
                    checkedList: ['C1', 'C2', 'B1', 'B2', 'A2', 'A1'],
                    indeterminate: true,
                    checkAll: true,
                    plainOptions: ['C1', 'C2', 'B1', 'B2', 'A2', 'A1'],
                },
                modal: {
                    deployvisible: false,
                    viewvisible: false,
                    screen: false,
                    sendText: {}
                },
                todayWork: {
                    total: 0,
                    columns: [{
                        width: 75,
                        title: "序号",
                        align: "center",
                        scopedSlots: {
                            customRender: "SerialNumber"
                        },
                    },
                    {
                        title: "业务编号",
                        dataIndex: "stageMark",
                        width: 150,
                    }, {
                        title: "轮次",
                        dataIndex: "turnCnt",
                        width: 150,
                    }, {
                        title: "科目",
                        dataIndex: "stage",
                        width: 150,
                    }, {
                        title: "联系电话",
                        dataIndex: "phone",
                        width: 150,
                    },
                    {
                        title: "购买时长",
                        dataIndex: "costTime",
                        customRender: item => {
                            return `${item / 60}小时`;
                        },
                        width: 150,
                    },
                    {
                        title: "业务状态",
                        dataIndex: "status",
                        customRender: item => {
                            return this.$enums.stageStatus[item];
                        },
                        width: 150,
                    },
                    {
                        title: "完成情况",
                        dataIndex: "complete",
                        width: 150,
                    },
                    ],
                    results: [],
                    item: {}
                },
                allocateVeh: {
                    total: 0,
                    columns: [{
                        title: "操作",
                        dataIndex: "option",
                        width: 30,
                        scopedSlots: {
                            customRender: "option"
                        },
                    },
                    {
                        title: "增加分钟",
                        dataIndex: "addSec",
                        width: 20,
                        align: 'center',
                        scopedSlots: {
                            customRender: "addSec"
                        },
                    },
                    {
                        title: "业务编号",
                        dataIndex: "stageMark",
                        width: 20,
                    }, {
                        title: "轮次",
                        dataIndex: "turnCnt",
                        width: 10,
                    }, {
                        title: "科目",
                        dataIndex: "stage",
                        width: 10,
                    }, {
                        title: "联系电话",
                        dataIndex: "phone",
                        width: 12,
                    }, {
                        title: "时长",
                        dataIndex: "costTime",
                        width: 40,
                        customRender: item => {
                            return `${item / 60}小时`;
                        },
                    },
                    {
                        title: "已训",
                        dataIndex: "alxun",
                        width: 75,
                    },
                    {
                        title: "未训",
                        dataIndex: "rexun",
                        width: 75,
                    }, {
                        title: "业务状态",
                        dataIndex: "status",
                        width: 55,
                        customRender: item => {
                            return this.$enums.stageStatus[item];
                        },
                    },
                    ],
                    results: [],
                    arr: [],
                    item: {},
                    newVehNo: '',
                    newStage:0,
                    // 调配数组
                    changeRrr: [],
                    // 未完成数组
                    inCompleteArr: [],
                    checkTea: false,
                    allocate: {
                        newTeaId: 0,
                        newVehId: ''
                    }
                },
                seltable: {
                    columns: [{
                        width: 75,
                        title: "序号",
                        align: "center",
                        scopedSlots: {
                            customRender: "SerialNumber"
                        },
                    },
                    {
                        title: "业务编号",
                        dataIndex: "stageMark",
                        width: 100,
                    }, {
                        title: "轮次",
                        dataIndex: "turnCnt",
                        width: 75,
                    }, {
                        title: "科目",
                        dataIndex: "stage",
                        width: 75,
                    }, {
                        title: "联系电话",
                        dataIndex: "phone",
                        width: 100,
                    }, {
                        title: "购买时长",
                        dataIndex: "costTime",
                        width: 100,
                        customRender: item => {
                            return `${item / 60}小时`;
                        },
                    }, {
                        title: "业务状态",
                        dataIndex: "status",
                        width: 100,
                        customRender: item => {
                            return this.$enums.stageStatus[item];
                        },
                    },
                    ],
                    results: [],
                },
                changeState: [],
                progress: {
                    percent: 0,
                    showProgress: false,
                    step: 0,
                    spinning: false,
                    err: [],
                }
            },
            saferManagement: {
                list: {
                    query: {
                        name: '',
                        phone: '',
                        starId: -1,
                        pageIndex: 1,
                        pageSize: 500,
                        unitId: sessionStorage.getItem('unitId'),
                        teaId: '',
                        vehType: '',
                        userId: sessionStorage.getItem('userId')
                    },
                    total: 0,
                    results: []
                }
            },
            basicForm: this.$form.createForm(this),
            userId: sessionStorage.getItem('userId'),
            //编辑页面数据
            businessAudit: {
                list: {
                    query: {
                        applyDate: this.$moment().format('YYYY-MM-DD 00:00:00'),
                        applyDateEnd: this.$moment().add(2, 'days').format('YYYY-MM-DD 00:00:00'),
                        pageIndex: 1,
                        pageSize: 10,
                        unitId: sessionStorage.getItem('unitId'),
                        stageMark: 0,
                        auditStatus: -1,
                        userId: sessionStorage.getItem('userId')
                    },
                    total: 0,
                    results: []
                },
                daterange: [this.$moment(),this.$moment().add(1, 'days')],
            },
            oldParams: {},
            //表头
            columns: [
                {
                    width: 75,
                    title: "序号",
                    align: "center",
                    scopedSlots: {
                        customRender: "SerialNumber"
                    }
                }, {
                    title: "业务编号",
                    dataIndex: "stageMark",
                },
                {
                    title: "业务类型",
                    dataIndex: "cType",
                    customRender: item => {
                        return this.$enums.cType[item];
                    }
                },
                {
                    title: "指派车辆",
                    dataIndex: "veh",
                }, {
                    title: "车号",
                    dataIndex: "vehNo",
                }, {
                    title: "科目",
                    dataIndex: "stage",
                    customRender: (item => {
                        return `科目${item}`
                    })
                }, {
                    title: "申请人",
                    dataIndex: "applyUserName",
                }, {
                    title: "原因",
                    dataIndex: "applyNote",
                },
                {
                    title: "审核人",
                    dataIndex: "auditName",
                },
                {
                    title: "审核意见",
                    dataIndex: "auditNote",
                    customRender: (item => {
                        return item ? item : '暂无意见'
                    })
                },
                {
                    title: "审核状态",
                    dataIndex: "auditStatus",
                    customRender: (item => {
                        return this.$enums.auditStatus[item];
                    })
                }, {
                    title: "提交时间",
                    dataIndex: "applyDate",
                    customRender: (item => {
                        return this.transForm(item);
                        // return item.substring(0,item.indexOf('T',0))+' '+item.substring(item.indexOf('T',0)+1,item.indexOf('.',0))
                    })
                },
                {
                    title: "操作",
                    scopedSlots: {
                        customRender: "operation"
                    }
                }
            ],
            excelName: ['业务审核'],
            selName: [],
            excelData: [],
            saferSorce: [],
            carSorce:[]
        };
    },
    methods: {
        //   ...mapActions(['setQueryByNews']),
        // 查询列表
        queryAudit(queryBy) {
            let querys = this.businessAudit.list.query;
            //判断是否为点击页码查询
            if (queryBy !== "pagination") {
                querys.pageIndex = 1;
            }
            getAuditCount(querys).then(res => {
                this.resetExport();
                this.businessAudit.list.total = res.data.rowCount;
                if (res.data.rowCount) {
                    getAuditList(querys).then(res => {
                        this.businessAudit.list.results = [...res.data];
                    })
                } else {
                    this.businessAudit.list.results = [];
                    this.businessAudit.list.total = 0;
                }
            }).catch(err => {
                console.log('err', err)
            });
        },
        getDate(){
            this.resetExport();
            let querys = this.businessAudit.list.query;
            let serch ={...querys};
            serch.pageIndex = 1;
            serch.pageSize = this.businessAudit.list.total + 100;
            getAuditList(serch).then(res =>{
                this.selName = [`业务编号：${this.businessAudit.list.query.stageMark}`, `查询日期：${this.businessAudit.list.query.applyDate}-${this.businessAudit.list.query.applyDateEnd}`, `审核状态：${this.$rformat.methods.r_auditStatus(this.businessAudit.list.query.auditStatus)}`, " ", " ", " ", " ", " ", " "," "," ", " "];
                let excelsource = this.comexport(this.columns, res.data);
                excelsource = this.exportDate(excelsource);
                this.excelData = [... this.excelData, ...excelsource];
                this.$refs.export.getExport(this.selName,this.excelName,this.excelData);
            })
        },
        //获取所有车号列表
        getVehListInnerDev() {
            let query = this.vehicleStatus.list.query;
            getVehListInnerDev(query).then(res => {
                // console.log('实时状态res', res)
                this.vehicleStatus.list.results = res.data;
            }).catch(err => {
                console.log('实时状态err', err)
            })

        },
        // 今日情况查看
        getTodayWork(query) {
            this.vehicleStatus.todayWork.item = query;
            getTodayWork(query).then(res => {
                this.vehicleStatus.todayWork.results = res.data;
            }).catch(err => {
                console.log('err', err)
            })
        },
        transmenu(item, option, index) {
            //   console.log('item', item)
            // 隐藏小菜单
            this.getVehListInnerDev();
            this.vehicleStatus.modal.deployvisible = true;
            //数据库需返回vehId,vehType
            let query = { ...{ unitId: sessionStorage.getItem('unitId') }, ...{ vehId: item.vehNo }, ...item };
            this.getInCompleteWork(query);

        },
        // 获取本车当天未完成业务列表
        getInCompleteWork(item) {
            // console.log('itemquery',item)
            this.vehicleStatus.allocateVeh.item = item;
            getInCompleteWork(item).then(res => {
                this.querySaferManag();
                this.vehicleStatus.allocateVeh.results = res.data.map(item => {
                    return {
                        ...item,
                        ...{
                            'trainAdd': 0
                        }
                    }
                });;
                this.vehicleStatus.allocateVeh.arr = res.data.map(item => {
                    return {
                        ...item,
                        ...{
                            'trainAdd': 0
                        }
                    }
                });
                // console.log('res', res)
            }).catch(err => {
                console.log('err', err)
            })
        },
        // 查看此车剩余业务量
        getInComplete() {
            this.vehicleStatus.allocateVeh.inCompleteArr=[];
            if (!this.vehicleStatus.allocateVeh.newVehNo.length) {
                // console.log('come in')
                this.$message.info('请先输入车号');
                return;
            }
            let arr = this.IsExsitveh();
            if(this.vehicleStatus.allocateVeh.newStage !== 0){
                arr=arr.filter( item=>{
                    return item.vehNo == this.vehicleStatus.allocateVeh.newVehNo && item.stage == this.vehicleStatus.allocateVeh.newStage;
                })
            }
            if (!arr.length) {
                this.vehicleStatus.allocateVeh.allocate.newVehId = '';
                this.$message.info('此车号不存在，请重新输入');
                return;
            }
            for(let item of arr){
                this.vehicleStatus.allocateVeh.allocate.newVehId = item.vehId;
                const query = {
                    'unitId': sessionStorage.getItem('unitId'),
                    'vehId': item.vehId
                }
                getInCompleteWork(query).then(res => {
                    this.vehicleStatus.allocateVeh.inCompleteArr = [...this.vehicleStatus.allocateVeh.inCompleteArr,...res.data];
                }).catch(err => {
                    console.log('err', err)
                })
            }
            // console.log('heihei', this.vehicleStatus.allocateVeh.newVehNo.length);
        },
        // 查看车号是否存在
        IsExsitveh() {
            let arr=[];
             this.vehicleStatus.list.results.map(item => {
                if(item.vehNo === Number(this.vehicleStatus.allocateVeh.newVehNo)){
                    arr.push(item);
                }
            });
            return arr;

        },
        // 只查看异常状态
        error(e) {
            if (e.target.checked) {
                this.vehicleStatus.allocateVeh.arr = this.vehicleStatus.allocateVeh.results.filter(item => {
                    return item.status === 2;
                })
            } else {
                this.vehicleStatus.allocateVeh.arr = this.vehicleStatus.allocateVeh.results;
            }
        },
        // 是否调配安全员
        changeTea(e) {
            this.vehicleStatus.allocateVeh.checkTea = e.target.checked;
        },
        // 增加时长
        addSec(costTime, record) {
            this.vehicleStatus.allocateVeh.arr = this.vehicleStatus.allocateVeh.arr.map(item => {
                if (record.stageMark === item.stageMark && record.vehId === item.vehId && record.turnCnt === item.turnCnt) {
                    return {
                        ...item,
                        ...{
                            'trainAdd': costTime * 60
                        }
                    }
                } else {
                    return item;
                }
            });
            this.vehicleStatus.allocateVeh.changeRrr = this.vehicleStatus.allocateVeh.changeRrr.map(item => {
                if (record.stageMark === item.stageMark && record.vehId === item.vehId && record.turnCnt === item.turnCnt) {
                    return {
                        ...item,
                        ...{
                            'trainAdd': costTime * 60
                        }
                    }
                } else {
                    return item;
                }
            });
        },
        selbox(e, record) {
            if (e.target.checked) {
                this.vehicleStatus.allocateVeh.changeRrr.push(record);
                // if (record.trainAdd === 0) {
                //   this.$message.info('请输入正确的时间，时间不能为0');
                //   return;
                // }
            } else {
                this.vehicleStatus.allocateVeh.changeRrr = this.vehicleStatus.allocateVeh.changeRrr.filter(item => {
                    return !record.stageMark === item.stageMark && record.vehId === item.vehId && record.turnCnt === item.turnCnt
                })
            }
        },
        // 查询安全员列表
        querySaferManag() {
            let querys = this.saferManagement.list.query;
            // 判断是否为点击页码查询
            getCount(querys).then((res) => {
                if (res.data.rowCount) {
                    getTeaList(querys).then((res) => {
                        this.$nextTick(() => {
                            this.saferManagement.list.results = res.data;
                        })
                    }).catch((err) => {
                        console.log('err', err)
                    })
                } else {
                    this.$nextTick(() => {
                        this.saferManagement.list.results = [];
                        this.saferManagement.list.total = 0;
                    })
                }
            }).catch((err) => {
                console.log('err', err)
            })
        },
        //车号和科目
        carData(value){
            let arr=[];
            if(!value.trim().length){
                arr= this.vehicleStatus.list.results
            }else {
                arr= this.vehicleStatus.list.results.filter(item=>{
                    if(item.vehNo == value){
                        return item;
                    }
                });
            };
            if(value.indexOf('/') != -1){
                this.vehicleStatus.allocateVeh.newStage=value.substr(value.length-1,);
                this.vehicleStatus.allocateVeh.newVehNo=value.split("号")[0];
            }else {
                this.vehicleStatus.allocateVeh.newStage=0;
                this.vehicleStatus.allocateVeh.newVehNo=value;
            }

            this.carSorce=arr.map( item =>{
                return `${item.vehNo}号/科目${item.stage}`
            });
        },
        // onSearch(value){
        //     this.vehicleStatus.allocateVeh.newVehNo=value.split("号")[0];
        // },
        // 选择安全员
        selTea(e) {
            this.vehicleStatus.allocateVeh.allocate.newTeaId = e;
        },
        handleChange(value) {
            this.saferSorce = this.saferManagement.list.results.filter(item => {
                if (item.name.indexOf(value) != -1) {
                    return item;
                }
            }).map( item =>{
                return `${item.name}/${item.phone}`
            })
            if(value.indexOf('/') != -1){
              let newTeaId= this.saferManagement.list.results.filter( item=>{
                  if(value.substring(0,value.indexOf('/'))&&value.substring(value.indexOf('/')+1,value.length)){
                    return item.name === value.substring(0,value.indexOf('/')) && item.phone === value.substring(value.indexOf('/')+1,value.length)
                  }else if(value.substring(0,value.indexOf('/'))){
                    return item.name === value.substring(0,value.indexOf('/'))
                  }else{
                    return item.phone === value.substring(value.indexOf('/')+1,value.length)
                  }

                })
                this.vehicleStatus.allocateVeh.allocate.newTeaId = newTeaId[0].teaId;
            }
        },
        // 确认调配
        allocateVeh() {
            if (this.vehicleStatus.allocateVeh.checkTea) {
                if (!this.vehicleStatus.allocateVeh.allocate.newTeaId) {
                    this.$message.info('请先选择调配的安全员');
                    return;
                }
            }
            // let item = this.IsExsitveh();
            // if (!item.length) {
            //     this.$message.info('此车号不存在，请重新输入');
            //     this.vehicleStatus.allocateVeh.allocate.newVehId = '';
            //     return;
            // } else {
            //     this.vehicleStatus.allocateVeh.allocate.newVehId = item[0].vehId;
            // }
            let arr = this.IsExsitveh();
            if(this.vehicleStatus.allocateVeh.newStage !== 0){
                arr=arr.filter( item=>{
                    return item.vehNo == this.vehicleStatus.allocateVeh.newVehNo && item.stage == this.vehicleStatus.allocateVeh.newStage;
                })
            }
            if (!arr.length) {
                this.vehicleStatus.allocateVeh.allocate.newVehId = '';
                this.$message.info('此车号不存在，请重新输入');
                return;
            }
            for(let item of arr){
                this.vehicleStatus.allocateVeh.allocate.newVehId = item.vehId;
                }
            if (this.vehicleStatus.allocateVeh.changeRrr.length) {
                this.vehicleStatus.allocateVeh.changeRrr.map(item => {
                    // 没有返回teaId
                    const query = { ...{ 'cId': item.cId, 'oldVehId': item.vehId, 'trainAdd': item.trainAdd, 'userId': sessionStorage.getItem('userId'), 'oldTeaId': item.teaId ? item.teaId : 0 }, ...this.vehicleStatus.allocateVeh.allocate }
                    // console.log('query', query);
                    allocateVeh(query).then(res => {
                        if (!res.result) {
                            this.$message.success('调配成功');
                            this.vehicleStatus.allocateVeh.inCompleteArr = [];
                            this.vehicleStatus.allocateVeh.changeRrr = [];
                            this.vehicleStatus.allocateVeh.arr = [];
                            this.vehicleStatus.allocateVeh.newVehNo = '';
                            this.vehicleStatus.allocateVeh.allocate.newTeaId = 0;
                            this.vehicleStatus.allocateVeh.checkTea = false;
                            this.vehicleStatus.modal.deployvisible = false;
                            this.carSorce=this.vehicleStatus.list.results.map( item =>{
                                return `${item.vehNo}号/科目${item.stage}`
                            })
                        } else {
                            this.$message.error(res.message);
                        }
                    }).catch(err => {
                        console.log('err', err);
                    })
                })
            } else {
                this.$message.info('请选择需要调配的项，并且输入正确的时间');
            }
        },
        // 关键字查询
        onSearch(value) {
            this.businessAudit.list.query.stageMark = value.target.value ? value.target.value : 0;
        },
        oncheckState(value) {
            this.businessAudit.list.query.auditStatus = value;
        },
        // 日期更改
        dataChange(val) {
            this.businessAudit.daterange = val;
            let querys = this.businessAudit.list.query;
            if (val.length) {
                querys.applyDate = val[0].format('YYYY-MM-DD 00:00:00');
                querys.applyDateEnd = this.$moment(val[1]).add(1, 'days').format('YYYY-MM-DD 00:00:00');
            } else {
                querys.applyDate = this.$moment().format('YYYY-MM-DD 00:00:00');
                querys.applyDateEnd = this.$moment().add(2, 'days').format('YYYY-MM-DD 00:00:00');
            }
        },
        // 审核通过
        pass(record, state) {
            // let params={cId:record.cId,applyNoteType:record.applyNoteType,auditUserId:record.applyUserId,unitId:sessionStorage.getItem('unitId')};
            if (state === 'pass') {
                pass({ auditNote: '通过', cId: record.cId, applyNoteType: record.applyNoteType, auditUserId: record.applyUserId, unitId: sessionStorage.getItem('unitId') }).then(res => {
                    if (res.data.rowCount) {
                        this.$message.success('审核通过');
                        this.queryAudit();
                    } else {
                        this.$message.error(res.message);
                    }
                }).catch(err => {
                    console.log('err', err)
                })
            } else {
                deny({ auditNote: '否决', cId: record.cId, applyNoteType: record.applyNoteType, auditUserId: record.applyUserId, unitId: sessionStorage.getItem('unitId') }).then(res => {
                    if (res.data.rowCount) {
                        this.$message.success('审核未通过');
                        this.queryAudit();
                    } else {
                        this.$message.error(res.message);
                    }
                }).catch(err => {
                    console.log('err', err)
                })
            }

        },
        resetExport() {
            this.selName = [];
            this.excelData = [];
        },
    },
    watch: {},
    created() { },
    mounted() {
        // if(!this.$route.meta.keepAlive){
        //     this.queryAudit();
        //     this.isFav();
        //   }
          this.queryAudit();
          this.isFav();
        //   this.oldParams = JSON.stringify(this.businessAudit);
    },
    activated() {
        this.isFav();
        // this.businessAudit = JSON.parse(this.oldParams);
        // this.queryAudit();
    },
};