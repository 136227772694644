const detail = resolve =>require(["@/views/integratedBusiness/billingDetails/detail/index.vue"], resolve);
const invoicePrint = resolve =>require(["@/views/integratedBusiness/billingDetails/invoicePrint/index.vue"], resolve);
export default {
    //新增订单
    name: "billing",
    data() {
        return {}
    },
    methods: {
    },
    watch: {},
    created() {
    },
    components: {
        detail,
        invoicePrint
    },
    mounted() {}
};