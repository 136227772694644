import {
    trainByDay
} from "@/service/businessData/index";
export default {
    name: "shortTrain",
    data() {
        return {
            shortTrain: {
                list: {
                    query: {
                        pageSize: 10,
                        pageIndex: 1,
                        begin:this.$moment().format('YYYYMMDD'),
                        end: this.$moment().format('YYYYMMDD'),
                        localTags:1,
                        stage:2,
                        unitId:sessionStorage.getItem('unitId'),
                        vehType:'C1',
                        userId: sessionStorage.getItem('userId')
                    },
                    total: 0,
                    results: []
                },
                daterange: [],
                columns: [{
                    width: 75,
                    title: "序号",
                    align: "center",
                    scopedSlots: {
                        customRender: "SerialNumber"
                    },
                },
                    {
                        title: "操作员",
                        dataIndex: "optioner",
                        width: 150,
                    },
                    {
                        title: "票号",
                        dataIndex: "number",
                        width: 150,
                    },
                    {
                        title: "科目",
                        dataIndex: "stage",
                        width: 150,
                    },
                    {
                        title: "支付金额",
                        dataIndex: "price",
                        width: 150,
                    },
                    {
                        title: "车号",
                        dataIndex: "vehNo",
                        width: 150,
                    },
                    {
                        title: "驾校",
                        dataIndex: "school",
                        width: 150,
                    },
                    {
                        title: "备注",
                        dataIndex: "note",
                        width: 150,
                    }
                ]
            },
            excelName: ['自带训练日报'],
            selName:[],
            excelData:[],
        }
    },
    methods: {
        queryShortData() {
            let querys = this.shortTrain.list.query;
            trainByDay(querys).then(res=>{
                // this.shortTrain.list.results=[...res.data]
            })
        },
        selectType(e){
            console.log('e',e)
        },
        selectStage(e){
            console.log('e',e)
        },
        selectLocal(e){
            console.log('e',e)
        }
    },
    created() {
        this.queryShortData();
    },
    mounted() {
        this.setQueryByAdvanced(this.shortTrain.list.query);
    }
};