import {
    getControlLogByUserId
} from "@/service/resManagement/index";
import {
    getList,
} from "@/service/userManagement/index";
export default {
    name: "devControLog",
    data() {
        return {
            devControLog: {
                list: {
                    query: {
                        begin:this.$moment().add('0','days').format('YYYYMMDD'),
                        end: this.$moment().add('1','days').format('YYYYMMDD'),
                        unitId:sessionStorage.getItem('unitId'),
                        userId:'',
                        stage:0,
                        vehType:'',
                    },
                    total: 0,
                    results:[]
                },
                daterange: [this.$moment().add('0','days'),this.$moment().add('0','days')],
                columns: [{
                    width: 75,
                    title: "序号",
                    align: "center",
                    scopedSlots: {
                        customRender: "SerialNumber"
                    }
                },
                    {
                        title: "车牌号",
                        dataIndex: "veh",
                    },
                    {
                        title: "车号",
                        dataIndex: "vehNo",
                    },
                    {
                        title: "命令",
                        dataIndex: "cmd",
                    },
                    {
                        title: "命令操作",
                        dataIndex: "cmdContent",
                    },
                    {
                        title: "下发时间",
                        dataIndex: "inTime",
                        customRender: (item => {
                            return this.transForm(item);
                        })
                    },
                    {
                        title: "操作人",
                        dataIndex: "nickname",
                    }]
            },
            userArr: [],
            excelName: ['控制日志'],
            selName:[],
            excelData:[],
            userId:sessionStorage.getItem('userId'),
        }
    },
    methods: {
        querySecurity() {
            let querys = this.devControLog.list.query;
            getControlLogByUserId(querys).then(res=>{
                this.devControLog.list.results=[...res.data];
                this.devControLog.list.results =  this.devControLog.list.results.map((item, index) =>{
                    item.id = index;
                    return item
                })
                this.resetExport();
                this.selName = [`查询日期: ${this.devControLog.list.query.begin}-${this.devControLog.list.query.end}`," "," "," "," "," "," "];
                let excelsource= this.comexport(this.devControLog.columns,this.devControLog.list.results)
                this.excelData=[... this.excelData,...excelsource];
                this.excelData.push([`结算日期：${this.$moment(this.devControLog.daterange[1]).format('YYYY-MM-DD')}`," "," "," "," "," "," "]);
            })
        },
        getDate(){
            this.$nextTick(()=>{
                this.$refs.export.getExport(this.selName,this.excelName,this.excelData);
            })
        },
        querySaferManag() {
            let query = { nickName: '', phone: '', unitId: sessionStorage.getItem('unitId'), pageIndex: 1, pageSize: 1000 };
            this.userArr = [];
            getList(query).then(res => {
                this.userArr.push({ nickName: '全部', userId:'' })
                this.userArr = [...this.userArr, ...res.data];
            });
        },
        resetExport(){
            this.selName=[];
            this.excelData=[];
        }
    },
    created() {

    },
    mounted() {
        this.querySaferManag();
        this.isFav();
        // if(!this.$route.meta.keepAlive){
        //     this.isFav();
        //     this.querySecurity();
        //   }
    },
    activated(){
        this.isFav();
        // this.querySecurity();
    }
};
