import { getLastLocationCompareStatistic } from "@/service/businessData/index";
import { message } from "ant-design-vue";
export default {
  name: "CarRuntimeReport",
  data() {
    return {
      CarRuntimeReport: {
        list: {
          query: {
            eId: sessionStorage.getItem("eId"),
            begin: this.$moment().format("YYYYMMDD"),
            end: this.$moment().format("YYYYMMDD"),
          },
          vehNo: "",
          stage: "2",
          sunTotal: [],
          total: 0,
          results: [],
        },
        daterange: [this.$moment(), this.$moment()],
        screenHeight: 500,
        // columns: []
      },
      //表头
      columns: [
        {
          title: "车号",
          dataIndex: "vehNo",
        },
        {
          title: "车牌号",
          dataIndex: "veh",
        },
        {
          title: "定位时间",
          dataIndex: "locationTime",
        },
      ],
    };
  },
  methods: {
    formatterDate(date) {
      let result = new Date(date);
      return result;
    },
    time(date) {
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      var h = date.getHours();
      h = h < 10 ? "0" + h : h;
      var minute = date.getMinutes();
      minute = minute < 10 ? "0" + minute : minute;
      var second = date.getSeconds();
      second = second < 10 ? "0" + second : second;
      return y + "-" + m + "-" + d + " " + h + ":" + minute + ":" + second;
    },
    selectData() {
      getLastLocationCompareStatistic(this.CarRuntimeReport.list.query).then(
        (res) => {
          if (res.data) {
            this.CarRuntimeReport.list.results = res.data.map((item) => {
              item.locationTime = this.timestampToTime(item.locationTime);
              return item;
            });
          } else {
            this.CarRuntimeReport.list.results = "";
          }
        }
      );
    },
    //将时间戳转换为普通时间
    timestampToTime(timestamp) {
      let unixtime = timestamp;
      let unixTimestamp = new Date(unixtime);
      let Y = unixTimestamp.getFullYear(),
        M =
          unixTimestamp.getMonth() + 1 > 10
            ? unixTimestamp.getMonth() + 1
            : "0" + (unixTimestamp.getMonth() + 1),
        D =
          unixTimestamp.getDate() >= 10
            ? unixTimestamp.getDate()
            : "0" + unixTimestamp.getDate(),
        h =
          unixTimestamp.getHours() < 10
            ? "0" + unixTimestamp.getHours()
            : unixTimestamp.getHours(),
        min =
          unixTimestamp.getMinutes() < 10
            ? "0" + unixTimestamp.getMinutes()
            : unixTimestamp.getMinutes(),
        s =
          unixTimestamp.getSeconds() < 10
            ? "0" + unixTimestamp.getSeconds()
            : unixTimestamp.getSeconds();
      let toDay = Y + "-" + M + "-" + D + " " + h + ":" + min + ":" + s;
      return toDay;
    },
  },
  created() {},
  mounted() {
    this.isFav();
    this.screenHeight = document.documentElement.scrollHeight - 300;
  },
  activated() {
    this.isFav();
    this.screenHeight = document.documentElement.scrollHeight - 300;
    //  this.queryShortData();
  },
};
