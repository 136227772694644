const trainingData = resolve => require(["@/views/integratedBusiness/trainingDetails/trainingData/index.vue"], resolve);
const facialAbnormalities = resolve => require(["@/views/integratedBusiness/trainingDetails/facialAbnormalities/index.vue"], resolve);
const schoolTraining = resolve => require(["@/views/integratedBusiness/trainingDetails/schoolTraining/index.vue"], resolve)
const businessFaceAnomaly = resolve => require(["@/views/integratedBusiness/trainingDetails/businessFaceAnomaly/index.vue"], resolve);
export default {
    //新增订单
    name: "trainingDetails",
    data() {
        return {
            key: "1",
            timeOut:{
                times:0
            }
        }
    },
    methods: {
        // 本校对比异常
        getLocalTrainAbnormalListCount2() {
            let querys = { begin:this.$moment().add(-1, "days").format('YYYYMMDD'), end: this.$moment().format('YYYYMMDD'), readTag: -1, unitId: sessionStorage.getItem('unitId') }
            this.$store.dispatch("StoreDataQuery/setQueryByAbnormalList", querys);
        },
        getKey(key) {
            this.key = key;
        }
    },
    watch: {},
    created() {
    },
    components: {
        trainingData,
        facialAbnormalities,
        schoolTraining,
        businessFaceAnomaly
    },
    mounted() {
        // if(this.$route.query.key === "loop"){
        //  this.getLocalTrainAbnormalListCount2();
        // }
    },
    watch: {
        $route: {
          handler: function(val, oldVal){
            if(val.path !== '/trainingDetails'){
                clearInterval(this.timeOut.times);
            }
          },
          // 深度观察监听
          deep: true
        }
      },
    activated() {
        if (this.$route.query.key === "loop") {
            this.getLocalTrainAbnormalListCount2();
            this.timeOut.times = setInterval(this.getLocalTrainAbnormalListCount2,7200000);
            this.key = "4";
        } else {
            this.key = "1";
        }
    }
};