import {
    getVehListInnerDev,
    getLastLocation
} from "@/service/vehicleStatus/index";
import {
    getTrainTrack,
    getAuditList,
} from "@/service/charge/index";
// import Vue from 'vue'
// import { TimePicker } from 'element-ui';
// Vue.use(TimePicker);
// import 'element-ui/lib/theme-chalk/index.css';
export default {
    name: "vehicleMonitoring",
    data() {
        return {
            btnLoading: false,
            speedtimes: 1,//轨迹回放速度倍数
            cluster: null,//点聚合
            map: {},
            markPoint: null,//轨迹回放车辆
            polyline: null,//绘制轨迹
            passedPolyline: null,
            oldMark: {},
            driveline: [],//行驶线路
            passedline: [],//已通过线路
            leftline: [],//未通过线路
            cpassed: 0,//当前过线路的坐标数组长度
            tpassed: 0,//全部通过过线路的坐标数组长度
            center: [104.066442,30.548976], 
            vdata: [],//车辆定位数据
            vmarkers: [],//车辆定位
            infoWindow: {},//信息窗体
            drawVis: false,
            drawMsg: {},
            selectMark: {},
            circleMarker: null,
            iscarMsg: false,
            //暂时测试
            marker: null,
            lineArr: [],
            isContinue: true,
            isInit: false,
            dateRange: this.$moment(),
            timeRange: [this.$moment().add('-1','hour'), this.$moment()],
            timeStart: this.$moment().add('-1','hour'),
            timeEnd: this.$moment(),
            // 页面加载
            pageLoading: true,
            // 车辆总数
            vehicleMonitoring: {
                list: {
                    query: {
                        pageIndex: 1,
                        pageSize: 500,
                        //stage 默认0为全部
                        stage: 0,
                        //starId 默认-1为全部
                        starId: -1,
                        status: '',
                        unitId: sessionStorage.getItem('unitId'),
                        veh: '',
                        vehNo: '',
                        vehType: '',
                    },
                    results: [],
                    conttactArr: [],
                    onlineResult: [],
                    logresults: [],
                    cartotal: 0,
                    onlineNum: 0
                }
            },
            // 异常总数
            businessAudit: {
                list: {
                    query: {
                        applyDate: this.$moment().format('YYYY-MM-DD 00:00:00'),
                        applyDateEnd: this.$moment().add(1, 'days').format('YYYY-MM-DD 00:00:00'),
                        pageIndex: 1,
                        pageSize: 500,
                        unitId: sessionStorage.getItem('unitId'),
                        stageMark: 0,
                        auditStatus: 0,
                        userId: sessionStorage.getItem('userId')
                    },
                    total: 0,
                    results: [],
                    cartotal: 0
                },
                daterange: [],
            },
            // 车号树状图
            carTree: {
                expandedKeys: ["stage2", "stage3"],
                searchValue: '',
                autoExpandParent: true,
                treeData: [],
                temporarySave: []
            },
            // 进度参数
            playParam: {
                play: false,
                start: false,
            },
            timeOut: {
                times: 0
            },
            // 查询轨迹参数
            trackParams:{
                date:this.$moment().format('YYYY-MM-DD'),
                timeBegin:this.$moment().add('-1','hour').format('HH:mm:ss'),
                timeEnd:this.$moment().format('HH:mm:ss'),
            },
            btnRole:[],
            allChangeMarkers:[],
            allChangeLocation:[],
            labelMarker:[],
            markerChange:[],
            initStatus:false
        }
    },
    // watch:{
    //     center(newValue,oldValue) {
    //         debugger
    //     }
    // },
    methods: {
        //轨迹移动退出
        playbackquit() {
            if (this.isInit) {
                this.isContinue = true;
                this.playParam.start = false;
                this.playParam.play = false;
                this.lineArr = [];
                this.dateRange = this.$moment();
                this.polyline = null;
                this.pageLoading = true;
                this.map.clearMap();
                this.getMapDate();
                this.isInit = false;
            }
        },
        //轨迹移动开始
        startAnimation() {
            if (!this.lineArr.length) {
                this.$message.info("请先查询轨迹")
                return;
            }else {
                this.markPoint.moveAlong(this.lineArr, this.speedtimes * 500);
                this.playParam.play = true;
                this.playParam.start = true;
            }
        },
        //轨迹移动暂停
        pauseAnimation() {
            if (!this.lineArr.length) {
                this.$message.info("请先查询轨迹")
                return;
            }
            if (this.markPoint) {
                this.markPoint.pauseMove();
                this.playParam.play = false;
            }
        },
        //轨迹移动继续
        resumeAnimation() {
            if (!this.lineArr.length) {
                this.$message.info("请先查询轨迹")
                return;
            }
            if (this.markPoint) {
                this.markPoint.resumeMove();
            }
        },
        //轨迹移动停止
        stopAnimation() {
            if (!this.lineArr.length) {
                this.$message.info("请先查询轨迹")
                return;
            }
            if (this.markPoint) {
                this.tpassed = 0;//设置总通过坐标点长度为0
                // this.leftline.setPath([]);//绘制剩余通过线路为空
                this.markPoint.moveAlong(this.lineArr, this.speedtimes * 500);
                this.playParam.play = true;
                this.playParam.start = true;
            }
        },
        // 速度变化
        getTimes(speed) {
            if (!this.lineArr.length) {
                this.$message.info("请先查询轨迹")
                return;
            }
            if (speed === "add") {
                this.speedtimes++;
            } else {
                if (this.speedtimes == 1) {
                    this.$message.info('已经是最小倍数')
                } else {
                    this.speedtimes--;
                }
            }
        },
        //初始化轨迹回放线路
        lineInit(vehMsg, query) {
            this.getTrainTrack(vehMsg, query);
        },
        //回放速度改变
        onAfterChange() {
            let lineArrRun = [];//行驶线路
            let passedArr = [];//通过线路
            if (this.tpassed < this.lineArr.length) {
                this.tpassed += this.cpassed - 1;
            }
            for (let i = 0; i < this.lineArr.length; i++) {
                if (i > this.tpassed) {
                    lineArrRun.push(this.lineArr[i]);//添加未通过线路
                }
                else if (i === this.tpassed) {
                    lineArrRun.push(this.lineArr[i]);//添加未通过线路
                    passedArr.push(this.lineArr[i])//添加已通过线路
                }
                else {
                    passedArr.push(this.lineArr[i])//添加已通过线路
                }
            }
            this.leftline.setPath(passedArr);//绘制已通过线路
            if (lineArrRun.length > 0 && this.cpassed) {
                this.playParam.play = true;
                this.playParam.start = true;
                this.markPoint.moveAlong(lineArrRun, this.speedtimes * 500);

            }
        },
        //车辆定位数据
        async  getmarkers() {
            this.vmarkers = [];
            for (let i in this.vdata) {
                if(this.vdata[i].position.length>0){
                    let Marker = new AMap.Marker({
                        position: this.vdata[i].position,
                        content: `<div><h4 class="n" style="display:none"></h4></div>`,
                        // icon: 'none',
                        imei: this.vdata[i].imei,
                        offset: new AMap.Pixel(-26, -13),
                        autoRotation: true,
                        angle:this.vdata[i].direction
                    })
                    let MarkerChange = new AMap.Marker({
                        position: this.vdata[i].position,
                        // content: this.vdata[i].content,
                        icon: this.vdata[i].online ? '/static/images/carmap.png' : '/static/images/no_line.png',
                        imei: this.vdata[i].imei,
                        offset: new AMap.Pixel(-26, -13),
                        autoRotation: true,
                        angle:this.vdata[i].direction
                    })
                    let labelText = new AMap.Text({
                        text:this.vdata[i].labelText,
                        anchor:'center', // 设置文本标记锚点
                        draggable:false,
                        angle:0,
                        offset: new AMap.Pixel(0, -28),
                        style:{
                            'background-color': 'transparent',
                            'border-width': 0,
                            'text-align': 'center',
                        },
                        position: this.vdata[i].position,
                    });
                    this.vmarkers.push(Marker);
                    this.labelMarker.push(labelText)
                    this.markerChange.push(MarkerChange)
                }
                if (i == this.vdata.length - 1) {
                    this.addCluster();
                }
            }
        },
        addCluster() {
            this.map.clearMap();
            if (this.cluster) {
                this.cluster.setMap(null);
            }
            var sts = [{
                url: "/static/images/blue.png",
                size: new AMap.Size(32, 32),
                offset: new AMap.Pixel(-16, -16)
            }, {
                url: "/static/images/green.png",
                size: new AMap.Size(32, 32),
                offset: new AMap.Pixel(-16, -16)
            }, {
                url: "/static/images/orange.png",
                size: new AMap.Size(36, 36),
                offset: new AMap.Pixel(-18, -18)
            }, {
                url: "/static/images/red.png",
                size: new AMap.Size(48, 48),
                offset: new AMap.Pixel(-24, -24)
            }, {
                url: "/static/images/darkRed.png",
                size: new AMap.Size(48, 48),
                offset: new AMap.Pixel(-24, -24)
            }];
            this.map.plugin(["AMap.MarkerClusterer"], () => {
                this.cluster = new AMap.MarkerClusterer(
                    this.map,
                    this.vmarkers,
                    {
                        gridSize: 2,
                        styles: sts,
                        minClusterSize: 2,
                        maxZoom: 18
                    }
                );
            });
            // 添加点标记
           this.labelMarker.forEach(item=>{
                item.setMap(this.map)
            })
            this.markerChange.forEach(item=>{
                item.setMap(this.map)
            })
            this.markEvent();
            this.pageLoading = false;
            // this.reInit();
            this.map.setZoom(18);
        },
        reInit() {
            if (!this.cluster) {
                $('#container').empty();
                this.mapInit();
                this.getCarData();
            }
        },
        carsArr() {
            this.drawVis = true;
        },
        onClose() {
            this.playbackquit();
            this.drawVis = false;
        },
        onExpand(expandedKeys) {
            this.carTree.expandedKeys = expandedKeys
        },
        onChange(e) {
            const value = e.target.value;
            let optionArr = JSON.parse(JSON.stringify(this.carTree.temporarySave));
            if (!value.trim()) {
                this.carTree.treeData = this.carTree.temporarySave;
                this.onExpand(["stage2", "stage3"]);
                return;
            }
            let parantkey = [];
            let parantkeyArr = optionArr.filter(item => {
                let childArr = item.children;
                let isReturn = false;
                let newArr = childArr.filter(items => {
                    if (items.key == `stage2-${value}` || items.key == `stage3-${value}`) {
                        isReturn = true;
                        return items;
                    }
                });
                item.children = newArr;
                if (isReturn) {
                    return item;
                }
            });
            for (let item of parantkeyArr) {
                parantkey.push(item.key);
            }
            this.carTree.treeData = parantkeyArr;
            this.onExpand(parantkey);
        },
        onSelect(checkedKeys, info) {
            this.iscarMsg = false;
            this.selectMark = {};
            if (info.node.eventKey == "stage2") {
                if (info.node.expanded) {
                    if (this.carTree.expandedKeys.length == 2) {
                        this.carTree.expandedKeys = ["stage3"];
                    } else {
                        this.carTree.expandedKeys = [];
                    }
                } else {
                    if (this.carTree.expandedKeys == ["stage3"]) {
                        this.carTree.expandedKeys = ["stage2", "stage3"];
                    } else {
                        this.carTree.expandedKeys = ["stage2"];
                    }
                }
            } else if (info.node.eventKey == "stage3") {
                if (info.node.expanded) {
                    if (this.carTree.expandedKeys.length == 2) {
                        this.carTree.expandedKeys = ["stage2"];
                    } else {
                        this.carTree.expandedKeys = [];
                    }
                } else {
                    if (this.carTree.expandedKeys == ["stage2"]) {
                        this.carTree.expandedKeys = ["stage2", "stage3"];
                    } else {
                        this.carTree.expandedKeys = ["stage3"];
                    }
                }
            }
            this.carTree.temporarySave.map(item => {
                item.children.map(item => {
                    if (item.key === checkedKeys[0]) {
                        this.vehicleMonitoring.conttactArr.map(e => {
                            if (e.imei === item.item.imei) {
                                if (item.position[0] != undefined && item.position[1] != undefined) {
                                    this.selectMark = item.item;
                                    this.iscarMsg = true;
                                    if (this.circleMarker) {
                                        this.map.remove(this.circleMarker);
                                    }
                                    this.circleMarker = new AMap.CircleMarker({
                                        center: new AMap.LngLat(e.position[0], e.position[1]),  // 圆心位置
                                        radius: 50, //半径
                                        borderWeight: 3,
                                        strokeColor: "#FF33FF",
                                        strokeOpacity: 1,
                                        strokeWeight: 6,
                                        // strokeOpacity: 0.2,
                                        fillOpacity: 0.4,
                                        strokeStyle: 'dashed',
                                        strokeDasharray: [10, 10],
                                        // 线样式还支持 'dashed'
                                        fillColor: '#1791fc',
                                        zIndex: 50,
                                    });
                                    this.map.add(this.circleMarker);
                                    this.map.setCenter(e.position);
                                    setTimeout(() => {
                                        if (this.circleMarker) {
                                            this.map.remove(this.circleMarker);
                                        }
                                    }, 3000)
                                    this.map.setZoom(18);
                                }
                            }
                        })
                    }
                })
            })
        },
        // 获取车辆列表
        async getVehListInnerDev() {
            let query = this.vehicleMonitoring.list.query;
            await getVehListInnerDev(query).then(res => {
                this.vehicleMonitoring.list.cartotal = res.data.length;
                let datas = res.data.sort(this.compareKey()).filter(item => { return item.devId && item.imei });
                this.vehicleMonitoring.list.results = [...datas];
                this.vehicleMonitoring.conttactArr = [...datas];
            });
        },
        // 获取异常状态列表
        async queryAudit() {
            let querys = this.businessAudit.list.query;
            await getAuditList(querys).then(res => {
                this.businessAudit.list.results = [...res.data];
            });
        },
        // 获取车辆在线数
        async getLastLocation() {
            let query = { unitId: sessionStorage.getItem('unitId') }
            await getLastLocation(query).then(res => {
                // 设置动态地图中心点
                if (!this.initStatus) {
                    // 获取第一个在线车辆的经纬度并设置为默认中心点 1为在线
                    const onlineAttr = res.data.filter(item=>item.online===1)
                    const defaultCenter = onlineAttr.length>0?[onlineAttr[0].longitude,onlineAttr[0].latitude]:this.center
                    this.center = defaultCenter
                    this.initStatus = true
                    this.mapInit();
                }
                if (res) {
                    let obj = {};
                    this.vehicleMonitoring.onlineResult = res.data.reduce(function (item, next) { obj[next.imei] ? '' : obj[next.imei] = true && item.push(next); return item; }, []);
                    this.vehicleMonitoring.list.onlineNum = this.vehicleMonitoring.onlineResult.filter(item => {
                        return item.online
                    }).length;
                    this.updateDate();
                }
            });
            if(this.vehicleMonitoring.list.onlineNum>=this.vehicleMonitoring.list.cartotal){
                this.vehicleMonitoring.list.cartotal = this.vehicleMonitoring.list.onlineNum;

            }else {
                return  this.vehicleMonitoring.list.cartotal
            }
        },
        // 每隔1s更新一条数据
        updateDate() {
            let isExistPos = false;
            for (let i in this.vehicleMonitoring.conttactArr) {
                if (this.vehicleMonitoring.conttactArr[i].latitude && this.vehicleMonitoring.conttactArr[i].longitude) {
                    isExistPos = true;
                    break;
                }
            }
            if (isExistPos) {
                bbq: for (let i in this.vehicleMonitoring.onlineResult) {
                    for (let j in this.vehicleMonitoring.conttactArr) {
                        if (this.vehicleMonitoring.onlineResult[i].imei === this.vehicleMonitoring.conttactArr[j].imei) {
                            // 判断车辆的经纬度是否有改变，有改变则更新位置
                            if ((this.vehicleMonitoring.onlineResult[i].latitude !== this.vehicleMonitoring.conttactArr[j].latitude) || (this.vehicleMonitoring.onlineResult[i].longitude !== this.vehicleMonitoring.conttactArr[j].longitude)) {
                                this.updateMark(this.vehicleMonitoring.conttactArr[j], this.vehicleMonitoring.onlineResult[i], j);
                                this.vehicleMonitoring.conttactArr[j].latitude = this.vehicleMonitoring.onlineResult[i].latitude;
                                this.vehicleMonitoring.conttactArr[j].longitude = this.vehicleMonitoring.onlineResult[i].longitude;
                                this.vehicleMonitoring.conttactArr[j].direction = this.vehicleMonitoring.onlineResult[i].direction;
                                this.vehicleMonitoring.conttactArr[j].speed = this.vehicleMonitoring.onlineResult[i].speed;
                                this.vehicleMonitoring.conttactArr[j].online = this.vehicleMonitoring.onlineResult[i].online;
                                break bbq;
                            }
                        }
                    }
                }
            } else {
                this.vehicleMonitoring.conttactArr = this.vehicleMonitoring.conttactArr.map(item =>{
                    item.position = [];
                    return item
                })
                for (let i in this.vehicleMonitoring.onlineResult) {
                    for (let j in this.vehicleMonitoring.conttactArr) {
                        if (this.vehicleMonitoring.onlineResult[i].imei === this.vehicleMonitoring.conttactArr[j].imei) {
                            this.vehicleMonitoring.conttactArr[j] = { ...this.vehicleMonitoring.conttactArr[j], ...this.vehicleMonitoring.onlineResult[i] }
                            this.vehicleMonitoring.conttactArr[j].position =[this.vehicleMonitoring.conttactArr[j].longitude,this.vehicleMonitoring.conttactArr[j].latitude];
                        }
                    }
                }
                this.contactData();
            }
            setTimeout(() => {
                this.updateDate();
            }, 1000);
        },
        async updateMark(oldItem, newItem, index) {
            // 将所有位置改变的marker点都push进数组中，另外再设置一个数组用于存放所有markers点的新旧位置。旧位置在前
            if (!this.isContinue) {
                return;
            }
            if (this.allChangeMarkers.length>9) {
                return;
            }
            this.vehicleMonitoring.conttactArr[index].position = [newItem.longitude, newItem.latitude];
            // var marker, lineArr = [[104.7657,31.416615],[104.783066,31.411161]];

            // this.allChangeMarkers.push(new AMap.Marker({
            //     map: this.map,
            //     position: [newItem.longitude, newItem.latitude],
            //     icon: "https://webapi.amap.com/images/car.png",
            //     offset: new AMap.Pixel(-26, -13),
            //     autoRotation: true,
            //     angle:-90,
            // }))
            // 在所有的vamrkers中寻找imei字段一致的markers，并push进改变的markers数组中
            let changeLocation = [[oldItem.longitude, oldItem.latitude],[newItem.longitude, newItem.latitude]]
            this.vmarkers.forEach((item,indexItem)=>{
                if (item.w.imei===oldItem.imei) {
                    this.labelMarker[indexItem].moveAlong(changeLocation,500);
                    this.markerChange[indexItem].moveAlong(changeLocation,500);
                    item.moveAlong(changeLocation,500);
                }
            })
        },
        // 组合数据
        contactData() {
            this.getMapDate();
        },
        async getMapDate() {
            await this.gpsTranDate();
            await this.getTreenData();
            await this.getmarkers();
        },
        async gpsTranDate() {
            this.vdata = [];
            this.vehicleMonitoring.conttactArr.map(item => {
                if (item.latitude && item.longitude) {
                    this.vdata.push({
                        position: item.position,
                        content: `<div><h4 class="n" style="font-weight:bolder;color:${item.stage === 2 ? 'red' : 'blue'}; transform: rotate(0deg) !important;">${item.vehNo}号</h4><img style=" display: inline-block; width: 42px; height: 20px;transform: rotate(${item.direction + 90}deg) !important;" src="${item.online ? '/static/images/carmap.png' : '/static/images/no_line.png'}"></div>`,
                        offset: [-45, -25],
                        clickable: true,
                        imei: item.imei,
                        labelText:`<div><h4 class="n" style="font-weight:bolder;color:${item.stage === 2 ? 'red' : 'blue'}; transform: rotate(0deg) !important;">${item.vehNo}号</h4></div>`,
                        direction: item.direction,
                        label:`<div><h4 class="n" style="font-weight:bolder;color:${item.stage === 2 ? 'red' : 'blue'}; transform: rotate(0deg) !important;">${item.vehNo}号</h4></div>`,
                        online:item.online
                    })
                }
            });
        },
        getdata(value) {
            if (!value) {
                // this.getTreenData();
            } else {
                this.drawMsg = value;
                this.carsArr();
            }
        },
        // 获得树形数据
        async getTreenData() {
            this.carTree.treeData = [];
            this.carTree.temporarySave = [];
            let stage2Arr = this.vehicleMonitoring.conttactArr.filter(item => { return item.stage === 2 });
            let stage3Arr = this.vehicleMonitoring.conttactArr.filter(item => { return item.stage === 3 });
            let stage2Child = [];
            let stage3Child = [];
            for (let i in stage2Arr) {
                stage2Child.push({
                    item: stage2Arr[i],
                    title: `${stage2Arr[i].vehNo}号`,
                    key: `stage2-${stage2Arr[i].vehNo}`,
                    position: stage2Arr[i].position,
                    scopedSlots: {
                        title: 'treeTitle',
                    },
                })
            }
            for (let i in stage3Arr) {
                stage3Child.push({
                    item: stage3Arr[i],
                    title: `${stage3Arr[i].vehNo}号`,
                    key: `stage3-${stage3Arr[i].vehNo}`,
                    position: stage3Arr[i].position,
                    scopedSlots: {
                        title: 'treeTitle',
                    },
                })
            }
            this.carTree.treeData.push({ title: '科目2', key: 'stage2', slots: { icon: 'yel' }, children: stage2Child }, { title: '科目3', key: 'stage3', slots: { icon: 'blue' }, children: stage3Child });
            this.carTree.temporarySave.push({ title: '科目2', key: 'stage2', slots: { icon: 'yel' }, children: stage2Child }, { title: '科目3', key: 'stage3', slots: { icon: 'blue' }, children: stage3Child });
            this.carTree.autoExpandParent = true;
            this.carTree.expandedKeys = ["stage2", "stage3"];
        },
        retDraw(val) {
            this.drawVis = val;
        },
        // 数组排序
        compareKey() {
            return function (obj1, obj2) {
                if (obj1['vehNo'] < obj2['vehNo']) {
                    return -1;
                } else if (obj1['vehNo'] === obj2['vehNo']) {
                    return 0;
                } else {
                    return 1;
                }
            };
        },
        // mark点击事件
        markEvent() {
            this.$nextTick(() => {
                AMap.event.addListener(this.cluster, 'click', (e) => {
                    this.openInfo(e.markers);
                })
            })
        },
        // 获取当前地图层级
        logMapinfo() {
            let zoom = this.map.getZoom(); //获取当前地图级别
            if (this.infoWindow.B) {
                this.infoWindow.close();
            }
        },
        // 地图缩放
        async zoom() {
            AMap.event.addListener(this.map, 'zoomchange', (e) => {
                this.logMapinfo();
            })
        },
        // 打开信息窗体
        openInfo(marks) {
            //构建信息窗体中显示的内容
            let zoom = this.map.getZoom();
            if (zoom < 18) {
                return;
            }
            var info = [];
            let str = ""
            for (let i = 0; i < marks.length; i++) {
                info.push(marks[i].B.content);
            }
            str = `<div class="marks" style="display: flex; flex-direction:row; width: ${marks.length == 1 ? '52px' : marks.length == 2 ? '104px' : marks.length == 3 ? '156px' : marks.length == 4 ? '208px' : '260px'};flex-wrap:wrap; justify-content:left;align-items:center; align-content:space-between;">${info.join("")}</div>`
            this.infoWindow = new AMap.InfoWindow({
                content: str,
                offset: new AMap.Pixel(0, -15)  //使用默认信息窗体框样式，显示信息内容
            });
            this.infoWindow.open(this.map, [marks[0].B.position.lng, marks[0].B.position.lat]);
        },
        async getCarData() {
            await this.getVehListInnerDev();
            await this.queryAudit();
            await this.getLastLocation();
            await this.zoom();
            this.timeOut.times = setInterval(this.getLastLocation, 30000);

        },
        // 获取轨迹点
        getTrainTrack(record, params) {
            this.isContinue = false;
            if (this.polyline) {
                this.map.clearMap();
            }
            let query = {
                imei: record.imei,
                begin: params.begin ? params.begin : this.$moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
                end: params.end ? params.end : this.$moment().format('YYYY-MM-DD HH:mm:ss')
            }
            getTrainTrack(query).then(res => {
                if(res.data){
                    this.trackArr(res.data, query, record);
                }else{
                    this.$message.error(res.message);
                }
            })
        },
        trackArr(arr, vehMsg, record) {
            this.isInit = true;
            let moveMark = null;
            this.lineArr = arr.map(item => {
                return [item.lng, item.lat]
            });
            if (this.cluster) {
                moveMark=record.imei
            }
            this.map = new AMap.Map("container", {
                resizeEnable: true,
                center:this.center,
                zoom: 17
            });

            this.markPoint = new AMap.Marker({
                map: this.map,
                position: this.lineArr[0],
                icon: "https://webapi.amap.com/images/car.png",
                offset: new AMap.Pixel(-26, -13),
                autoRotation: true,
                angle:-90,
            });

            // 绘制轨迹
            this.polyline = new AMap.Polyline({
                map: this.map,
                path: this.lineArr,
                showDir:true,
                strokeColor: "#28F",  //线颜色
                // strokeOpacity: 1,     //线透明度
                strokeWeight: 6,      //线宽
                // strokeStyle: "solid"  //线样式
            });

            this.passedPolyline = new AMap.Polyline({
                map: this.map,
                // path: lineArr,
                strokeColor: "#AF5",  //线颜色
                // strokeOpacity: 1,     //线透明度
                strokeWeight: 6,      //线宽
                // strokeStyle: "solid"  //线样式
            });
            // this.markPoint.on('moving', function (e) {
            //     this.passedPolyline.setPath(e.passedPath);
            // });
            // this.markPoint = new AMap.Marker({
            //     position: moveMark,
            //     content: `<div><img style=" display: inline-block; width: 42px; height: 20px;transform: rotate(0deg) !important;" src="${record.online ? '/static/images/carmap.png' : '/static/images/no_line.png'}"></div>`,
            //     imei: record.imei,
            //     offset: new AMap.Pixel(-26, -13),
            //     autoRotation: true,
            // });
            // // 绘制轨迹
            // this.polyline = new AMap.Polyline({
            //     map: this.map,
            //     path: this.lineArrr,
            //     showDir: true,
            //     strokeColor: "#28F",  //线颜色
            //     strokeWeight: 6,      //线宽
            // });
            //
            // this.passedPolyline = new AMap.Polyline({
            //     map: this.map,
            //     strokeColor: "#AF5",  //线颜色
            //     strokeWeight: 6,      //线宽
            // });
            // this.markPoint.on('moving', function (e) {
            //     this.passedPolyline.setPath(e.passedPath);
            // });
            // this.leftline = new AMap.Polyline({
            //     strokeColor: "#AF5",  //线颜色
            //     strokeWeight: 6,      //线宽
            // });
            // this.leftline.setMap(this.map);
            // if (this.cluster) {
            //     this.cluster.removeMarkers;
            //     this.cluster.addMarkers([this.markPoint]);
            // }
            //     if (e.passedPath) {
            //         this.driveline = e.passedPath[e.passedPath.length - 1]
            //         this.passedPolyline.setPath(e.passedPath);
            //         this.cpassed = e.passedPath.length - 1;//设置当前通数组长度
            //         if (this.driveline.lng == this.lineArr[this.lineArr.length - 1].lng && this.driveline.lat == this.lineArr[this.lineArr.length - 1].lat) {
            //             this.playParam.play = false;
            //         }
            //     }
            this.map.setFitView();
        },
        onOk(value) {
            this.trackParams.date = this.$moment(value).format('YYYY-MM-DD');
        },
        getRangeTime(value){
            this.trackParams.timeBegin = this.$moment(value[0]).format('HH:mm:ss');
            this.trackParams.timeEnd = this.$moment(value[1]).format('HH:mm:ss');
        },
        getTimeStart(value){
            this.trackParams.timeBegin = this.$moment(value).format('HH:mm:ss');
        },
        getTimeEnd(value){
            this.trackParams.timeEnd = this.$moment(value).format('HH:mm:ss');
        },
        // 查询数据
        lineStart(){
            let query = {begin: `${this.trackParams.date} ${this.trackParams.timeBegin}`,end: `${this.trackParams.date} ${this.trackParams.timeEnd}`}
            this.playParam.start = false;
            this.playParam.play = false;
            this.lineInit(this.drawMsg, query);
        },
        // 数据区间置灰
        disabledDate(current) {
            let cur = this.$moment(current).format('YYYYMMDD');
            let today = this.$moment().format('YYYYMMDD');
            let weekBefore = this.$moment().add('-6','month').format('YYYYMMDD');
            return cur < weekBefore || cur > today;
        },
        // 地图初始化
        mapInit() {
            this.map = new AMap.Map("container", {
                resizeEnable: true,
                center: this.center
            });
        }
    },
    beforeCreate() {
    },
    created() {
    },
    mounted() {
        this.getCarData();
        this.mapInit();
        let params = this.$route.path.substr(1,)
        this.btnRole = this.getBtnRole(params);
    },
    watch: {
        $route: {
            handler: function (val, oldVal) {
                if (val.path !== '/vehicleMonitoring') {
                    clearInterval(this.timeOut.times);
                } else {
                    this.getLastLocation();
                    this.timeOut.times = setInterval(this.getLastLocation, 30000);
                }
            },
            // 深度观察监听
            deep: true
        }
    },
};
