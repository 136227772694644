import request from "../../utils/request";
//设备注册
/**
 *设备列表
 * @param {object} params
 * @param {string} params.imei  -非必填
 * @param {int} params.pageIndex - 必填
 * @param {int} params.pageSize -必填
 * @param {string} params.sim -非必填
 * @param {int} params.status -非必填 状态0 正常， 1注销，-1全部
 * @param {int} params.unitld -必填
 * @param {string} params.veh -非必填
 */
export function getList(params){
    return request("/dev/getList",{
        method:"get",
        params:params
    });
}

/**
 * 设备列表统计
 * @param {object} params
 * @param {string} imei -非必填
 * @param {string} sim -非必填
 * @param {int} status -非必填
 * @param {int} unitId -必填
 * @param {string} veh -非必填
 */
export function getCount(params) {
    return request("/dev/getCount",{
        method:"get",
        params:params
    });
}

/**
 * 注销设备
 * @param {object} params
 * @param {int} params.devId        设备ID-必填
 * @param {string} params.devReg      车牌号-必填
 * @param {int} params.unitId         单位ID-必填
 */
export function devData(params){
    return request("/dev/del",{
        method:"post",
        params:params
    });
}

/**
 * 换卡
 * @param {object} params
 * @param {int} params.devId        设备ID-必填
 * @param {string} params.sim      sim号-必填
 * @param {int} params.unitId         单位ID-必填
 */
export  function changeSim(params) {
    return request("/dev/changeSim",{
        method:"post",
        params:params
    });
}

/**
 * 设备注册
 * @param {object} params
 * @param {int} params.factory
 * @param {int} params.imei        设备ID-必填
 * @param {string} params.sim      sim号-必填
 * @param {int} params.unitId         单位ID-必填
 */
export  function devAdd(params) {
    return request("/dev/add",{
        method:"post",
        params:params
    });
}

/**
 * 设备参数设
 * @param {object} params
 * @param {int} params.devNum
 * @param {int} params.imei        设备ID-必填  /monitor/setDevParams
 */
export  function getparameterSetting(params) {
    return request("/monitor/getDevParams",{
        method:"get",
        params:params
    });
}

/**
 *  打印参数设置
 * @param {object} params
 * @param {int} params.kpjh          开票机号-必填
 * @param {int} params.company      公司名称-必填
 * @param {int} params.invoice     纳税人识别号-必填
 * @param {int} params.address     公司地址-必填
 * @param {int} params.tel        联系电话-必填
 * @param {int} params.contact    联系人-必填
 * *@param {int} params.unitId    单位ID-必填
 */
export  function faxInfo(params) {
    return request("/fax/init",{
        method:"post",
        params:params
    });
}

/**
 *  打印参数查询
 * @param {object} params
 * *@param {int} params.unitId    单位ID-必填
 */
export  function getFaxInfo(params) {
    return request("/fax/getFaxInfo",{
        method:"post",
        params:params
    });
}

/**
 *  打印参数查询
 * @param {object} params
 * *@param {int} params.unitId    单位ID-必填
 */
export  function setDevParams(params) {
    return request("/monitor/setDevParams",{
        method:"post",
        params:params
    });
}
