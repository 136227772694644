import { trainDaily, trainDailySum, trainDailyCount } from "@/service/businessData/index";
import { Table } from "ant-design-vue";
import { TableColumn } from "element-ui";
export default {
    name: "shortTrainDaily",
    data() {
        return {
            orgOptions: {},
            shortTrainDaily: {
                list: {
                    query: {
                        date: this.$moment().format('YYYYMMDD'),
                        dateEnd: this.$moment().format('YYYYMMDD'),
                        localTags: -1,
                        stage: 2,
                        unitId: sessionStorage.getItem('unitId'),
                        vehType: "",
                        userId: sessionStorage.getItem('userId'),
                        pageIndex: 1,
                        pageSize: 10,
                        cType: '0'
                    },
                    total: 0,
                    results: [],
                    resultss: [],
                    pays: '',
                    prePays: '',
                    formatPrePays: ''
                },
                daterange: [this.$moment(), this.$moment()],
                date: [],
                columns: []

            },
            excelName: ['短训日报'],
            selName: [],
            excelData: [],
            userId: sessionStorage.getItem('userId'),
            cTypeClumn: [],
            initClumn: [
                {
                    width: 70,
                    title: "序号",
                    scopedSlots: {
                        customRender: "SerialNumber"
                    },
                },
                {
                    title: "业务编号",
                    dataIndex: "stageMark",
                    align: 'center',
                    customRender: (item) => {
                        return item ? item : "暂无";
                    }
                },
                // {
                //     title: "业务类型",
                //     dataIndex: "cType",
                //     customRender: (item) => {
                //         return this.$enums.cType[item];
                //     }
                // },
                {
                    title: "优惠券编号",
                    dataIndex: "coupon",
                },
                {
                    title: "优惠券金额",
                    dataIndex: "couponPrice",
                    customRender: (item) => {
                        return item ? item / 100 : 0;
                    }
                },
                {
                    align: 'center',
                    title: "驾校",
                    dataIndex: "shortName",
                    customRender: (item) => {
                        return item ? item : "暂无";
                    }
                },
                {
                    title: "安全员",
                    dataIndex: "coachName",
                    align: 'center',
                    customRender: (item) => {
                        return item ? item : "暂无";
                    }
                },
                {
                    title: "车号",
                    dataIndex: "vehNo",
                    align: 'center',
                    customRender: (item) => {
                        return item ? item : "暂无";
                    }
                },
                {
                    title: "培训日期",
                    dataIndex: "orderDate",
                    align: 'center',
                    customRender: (item) => {
                        return item ? item : "暂无";
                    }
                },
                {
                    title: "开始时间",
                    dataIndex: "trainBegin",
                    align: 'center',
                },
                {
                    title: "结束时间",
                    dataIndex: "trainEnd",
                    align: 'center',
                },
                {
                    title: "短训金额",
                    dataIndex: "cost",
                    align: 'center',
                    customRender: (item) => {
                        return item / 100
                    }
                },
                {
                    title: "短训时间(小时)",
                    dataIndex: "costTime",
                    align: 'center',
                    customRender: (item) => {
                        return item / 60
                    }
                },
                {
                    title: "购买日期",
                    dataIndex: "payDate",
                    align: 'center',
                },
                {
                    title: "营收情况",
                    dataIndex: "result",
                    align: 'center',
                    customRender: (item) => {
                        return item ? item : "暂无";
                    }
                },
                {
                    title: "推荐人",
                    dataIndex: "referee",
                    align: 'center',
                    customRender: (item) => {
                        return item ? item : "无";
                    }
                },
                {
                    title: "制票人",
                    dataIndex: "nickName",
                    align: 'center',
                    customRender: (item) => {
                        return item ? item : "暂无";
                    }
                },
                {
                    title: "VIP联系电话",
                    align: 'center',
                    dataIndex: "phone",
                    customRender: (item) => {
                        return item ? item : "暂无";
                    }
                },
                {
                    title: "调配备注",
                    align: 'center',
                    dataIndex: "allocateLog"
                }
            ]
        }
    },
    methods: {
        queryShortData(queryBy) {
            let querys = this.shortTrainDaily.list.query;
            if (queryBy !== "pagination") {
                querys.pageIndex = 1;
            }
            this.shortTrainDaily.columns = [...this.cTypeClumn];
            console.log('this.shortTrainDaily.columns', this.shortTrainDaily.columns)
            trainDailySum(querys).then(res => {
                this.shortTrainDaily.list.pays = res.data[0] ? res.data[0].pays / 100 : 0;
                this.shortTrainDaily.list.prePays = res.data[0] ? res.data[0].prePays : 0;
                this.shortTrainDaily.list.formatPrePays = res.data[0] ? res.data[0].formatPrePays : 0;
                trainDailyCount(querys).then(res => {
                    this.shortTrainDaily.list.total = res.data[0].count;
                    trainDaily(querys).then(res => {
                        res.data = res.data.map(item =>{
                            if(item.status != 3){
                                item.trainBegin = item.trainBegin ? this.$moment(this.translateDate(item.trainBegin)).format('YYYY-MM-DD HH:mm:ss') : '';
                                item.trainEnd = item.trainEnd ? this.$moment(this.translateDate(item.trainEnd)).format('YYYY-MM-DD HH:mm:ss') : '';
                                if (item.trainBegin) {
                                    if (!item.trainEnd) {
                                        item.trainEnd = this.$moment(item.trainBegin).add(item.costTime, 'minutes').format('YYYY-MM-DD HH:mm:ss');
                                    }
                                }
                            }
                            return item;
                        })
                        this.shortTrainDaily.list.results = [...res.data];
                    })
                })
            })
        },
        getDate() {
            this.resetExport();
            let querys = this.shortTrainDaily.list.query;
            let serch = { ...querys }
            serch.pageIndex = 1;
            serch.pageSize = this.shortTrainDaily.list.total + 100;
            trainDaily(serch).then(res => {
                let source = res.data.sort(this.compareKey()).map(item => {
                    if(item.status != 3){
                        item.trainBegin = item.trainBegin ? this.$moment(this.translateDate(item.trainBegin)).format('YYYY-MM-DD HH:mm:ss') : '';
                        item.trainEnd = item.trainEnd ? this.$moment(this.translateDate(item.trainEnd)).format('YYYY-MM-DD HH:mm:ss') : '';
                        if (item.trainBegin) {
                            if (!item.trainEnd) {
                                item.trainEnd = this.$moment(item.trainBegin).add(item.costTime, 'minutes').format('YYYY-MM-DD HH:mm:ss');
                            }
                        }
                    }
                    return item;
                })
                this.selName = [`查询日期: ${this.$moment(this.shortTrainDaily.daterange[0]).format('YYYY-MM-DD')}~${this.$moment(this.shortTrainDaily.daterange[1]).format('YYYY-MM-DD')}`, `业务类型: ${this.$rformat.methods.r_cType(this.shortTrainDaily.list.query.cType)}`, `归属: ${this.$rformat.methods.r_isSch(this.shortTrainDaily.list.query.localTags)}`, `科目: ${this.$rformat.methods.r_projectItem(this.shortTrainDaily.list.query.stage)}`];
                for (let i = 0; i < this.shortTrainDaily.columns.length - 4; i++) {
                    this.selName.push(" ")
                    console.log('this.selName', this.selName)
                }
                let excelsource = this.comexport(this.shortTrainDaily.columns, source, false, 'no');
                this.excelData = [... this.excelData, ...excelsource];
                this.excelData.push([`${this.$rformat.methods.r_cType(this.shortTrainDaily.list.query.cType)}结算日期：${this.$moment(this.shortTrainDaily.daterange[0]).format('YYYY-MM-DD')}至${this.$moment(this.shortTrainDaily.daterange[1]).format('YYYY-MM-DD')}`, `预收：${this.shortTrainDaily.list.prePays}`, `实收：${this.shortTrainDaily.list.pays}`, `转实收：${this.shortTrainDaily.list.formatPrePays}`, `合计：${this.shortTrainDaily.list.pays}`]);
                for (let i = 0; i < this.shortTrainDaily.columns.length - 5; i++) {
                    this.excelData[this.excelData.length - 1].push(" ")
                }
                this.$nextTick(() =>{
                    this.$refs.export.getExport(this.selName, this.excelName, this.excelData);
                })
            })
        },
        // 表格行颜色样式
        trColor(record, index) {
            // { 0: "正常", 1: "结算",2:'异常', 3:'注销',}
            let className = 'light-row';
            if (record.note != null) { className = 'biaoji-row' }
            return className;
        },
        resetExport() {
            this.selName = [];
            this.excelData = [];
        },
        getDateStr(e) {
            this.shortTrainDaily.list.query.date = this.$moment(e[0]).format('YYYYMMDD');
            this.shortTrainDaily.list.query.dateEnd = this.$moment(e[1]).format('YYYYMMDD');
            this.shortTrainDaily.daterange = e;
        },
        getCtype(e) {
            if (e == '-1') {
                this.cTypeClumn = this.initClumn.filter(e => {
                    return e.dataIndex != "coupon" && e.dataIndex != "couponPrice" && e.dataIndex != "cType"
                })
            } else if (e == '2') {
                this.cTypeClumn = [... this.initClumn]
            } else {
                this.cTypeClumn = this.initClumn.filter(e => {
                    return e.dataIndex != "coupon" && e.dataIndex != "couponPrice"
                })
            }
        },
        // 数组排序
        compareKey() {
            return function (obj1, obj2) {
                if (obj1['stageMark'] < obj2['stageMark']) {
                    return -1;
                } else if (obj1['stageMark'] === obj2['stageMark']) {
                    return 0;
                } else {
                    return 1;
                }
            };
        },
    },
    created() {

    },
    mounted() {
        this.shortTrainDaily.columns = this.initClumn.filter(e => {
            return e.dataIndex != "coupon" && e.dataIndex != "couponPrice"
        });
        this.cTypeClumn = this.initClumn.filter(e => {
            return e.dataIndex != "coupon" && e.dataIndex != "couponPrice"
        })
        this.isFav();
        this.queryShortData();
    },
    activated() {
        this.isFav();
    }
};
