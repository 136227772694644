import { getTodayIntegration } from "@/service/basePage/index";
export default {
    name: "homePage",
    data() {
        return {
            homePage: {
                list: {
                    query: {
                        unitId: sessionStorage.getItem('unitId'),
                        stage: -1,
                        vehType: " ",
                    },
                    total: 0,
                    results: []
                },
                statis: {
                    total: 0,//当日总数
                    trained: 0,//已培训
                    UnTrain: 0,//未培训
                    trainning: 0,//正在培训
                },
            },
            timeOut: {
                times: 0
            },
        }
    },
    methods: {
        // 查询当天租车情况，包括购买总数、已训、未训、正在培训(不包括注销)
        getTodayIntegration() {
            let query = this.homePage.list.query;
            getTodayIntegration(query).then(res => {
                this.homePage.statis.total = res.data.total ? res.data.total : 0;
                this.homePage.statis.UnTrain = res.data.UnTrain ? res.data.UnTrain : 0;
                this.homePage.statis.trained = res.data.trained ? res.data.trained : 0;
                this.homePage.statis.trainning = res.data.trainning ? res.data.trainning : 0;
            });
        },
    },
    created() {

    },
    mounted() {
        this.getTodayIntegration();
    },
    activated() {
        this.getTodayIntegration();
    },
    destroyed() {
    }
};