var echarts = require('echarts');
import {
  cancel,
  modifyStu,
  cancelTags,
  addStu,
  confirm,
  getTags,
  getTodayList,
  getTurnCntByVeh,
  preAdd,
  getListByTags,
  askTurn,
  couponExists,
  getvalidLocalStu
} from "@/service/charge/index";
// 车辆列表
import {
  getvehList
} from "@/service/resManagement/index";
// 基本费用列表
import {
  getCostList,
} from "@/service/costSet/index";
// vip列表，查询是否为vip
import {
  getVipList
} from "@/service/resManagement/index";
export default {
  data() {
    return {
      charge: {
        list: {
          query: {
            projectItem: undefined,
            school: undefined,
            vehType: 'C1',
            starId: -1,
            stage: 2,
            vehNo: '',
            veh: '',
            status: '',
            unitId: sessionStorage.getItem('unitId'),
            pageIndex: 1,
            pageSize: 100,
            vehId: '',
            phone: '',
            vipType: 1,
            cashType: '',
            name: '',
            certId: '',
            shortId: '',
            userId: sessionStorage.getItem('userId'),
            sch: '',
            valid: 1
          },
          total: 0,
          results: []
        },
        costTime: 0.5,
        // // 分组码
        // tags: '',
        //是否被占用
        isCon: false,
        changeState: true,
        daterange: [],
        // 表头
        columns: [],
        // 预约排序表头
        rankOrder: [],
        // 组号
        tags: '',
        modifyTrain: false,
        // 完善信息
        studentMessage: {
          list: {
            query: {
              cId: 0,
              name: '',
              phone: '',
              certId: '',
              cashType: '',
            },
            total: 0,
            results: []
          },
          columns: [
            {
              width: 75,
              title: "序号",
              scopedSlots: {
                customRender: "SerialNumber"
              }
            }, {
              width: 100,
              title: "姓名",
              dataIndex: "name",
            }, {
              width: 100,
              title: "身份证号",
              dataIndex: "certId",
            }, {
              width: 100,
              title: "电话",
              dataIndex: "phone",
            }, , {
              width: 100,
              title: "操作",
              scopedSlots: {
                customRender: "option"
              }
            }],
          data: []
        },
        isVip: false,
        isCost: false,
        data: [],
        // 星级价格表
        getStarList: [],
        // 基本费用列表
        getCostList: [],
        // 驾校列表
        schoolList: [],
        // 点击完善信息暂存当前列信息
        selectRecord: {},
        vehNoArr: [],
        printArr: [],
      },
      form: this.$form.createForm(this),
      // 默认打开面板
      activeKey: ['1'],
      // 预付款参数
      preAdd: {
        coupon: '',
        cType: 0,
        cashType: 1,
        costTime: 0.5,
        enterCode: '',
        netType: 2,
        orderDate: this.$moment().format('YYYYMMDD'),
        cellId: [],
        cancelId: [],
        couponPrice: 0
      },
      // 订单详情
      orderDetais: {
        list: [],
        visible: false,
        popVisable: false,
      },
      // 是否确认
      checkAll: false,
      arrIndex: [],
      displayarr: [],
      checkedList: ['pay', 'msg', 'ord'],
      basicForm: this.$form.createForm(this),
      stuForm: this.$form.createForm(this),
      addColumns: [{
        // 车号
        dataIndex: 'vehNo',
        customRender: (item => {
          return `${item}车`;
        })
      }, {
        // 轮次
        dataIndex: 'turnCnt',
        customRender: (item => {
          return `第${item}轮`;
        })
      },
      {
        // 时长
        dataIndex: 'costTime',
        scopedSlots: {
          customRender: 'costTime'
        },
      },
      {
        // 基本费用
        dataIndex: 'hourPrice',
        scopedSlots: {
          customRender: 'hourPrice'
        },
      },
      {
        // 星级费用
        dataIndex: 'starPrice',
        scopedSlots: {
          customRender: 'starPrice'
        },
      },
      {
        // vip星级费用
        dataIndex: 'isvip',
        scopedSlots: {
          customRender: 'isvip'
        },
      }, {
        // vip星级费用
        dataIndex: 'money',
        scopedSlots: {
          customRender: 'money'
        },
      }, {
        // 完善学员信息
        dataIndex: 'addMessage',
        scopedSlots: {
          customRender: 'addMessage'
        },
      }, {
        width: 100,
        fixed: "right",
        scopedSlots: {
          customRender: "action"
        }
      }
      ],
      option: {
        title: {
          text: '车辆使用统计'
        },
        tooltip: {},
        // legend: {
        //     data:['销量']
        // },
        xAxis: {
          data: []
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return `${value}小时`
            }
          }
        },
        series: [{
          name: '销量',
          type: 'bar',
          barWidth: 50,
          data: [],
          itemStyle: {
            normal: {
              color: '#40a9ff',
              label: {
                show: true, //开启显示
                position: 'top', //在上方显示
                textStyle: { //数值样式
                  color: 'red',
                  fontSize: 16
                }
              }
            }
          }
        }],
      },
      referee: {
        referee2: '',
        referee2Name: '',
        referee3: '',
        referee3Name: '',
      },
      allVehNo: [],
      rankType: 'vihicleSrc',
      tableHeigh: { y: 410 },
      drag: {
        x: "0",
        y: "0",
        flag: 0,
        isActive: false,
        redBorder: true,
      },
      groupPrice: 0,
      useCoupon: false,
      checkBox: {
        isExist: false,
        isGroup: false,
        couponVal: false,
      }
    }
  },
  methods: {
    // turnCnt 轮次    vehId车id
    // 如果没有这条数据就添加，有这条数据就删除 编辑框获取数据
    onCellChange(state, id, turnCnt, vehId) {
      if (!this.charge.list.query.phone) {
        $('.tel').css({ border: '1px solid red' })
        this.$message.error('联系电话不能为空');
        return;
      } else {
        $('.tel').css({ border: '1px solid #d9d9d9' })
      }
      // console.log('state,id,turnCnt, vehId', state, id, turnCnt, vehId);
      let item = this.arrIndex.find(item => {
        return item.vehId === vehId;
      })
      this.askTurn(item, state, turnCnt, vehId, id);
    },
    // 获取该用户当日未确认的预约情况
    askTurn(item, state, turnCnt, vehId, id) {
      let params = { orderDate: this.$moment().format('YYYYMMDD'), turnCnt: turnCnt, unitId: sessionStorage.getItem('unitId'), vehId: vehId }
      askTurn(params).then(res => {
        if (res.result === 0) {
          this.isoccupy(item, state, turnCnt, vehId, id);
        } else {
          this.$message.info(res.message);
        }
      })
    },
    // 查询获取选定日期同车、车型、科目的轮次是否被占用
    isoccupy(item, state, turnCnt, vehId, id) {
      let params = {
        orderDate: this.$moment().format('YYYYMMDD'),
        orderDateEnd: this.$moment().format('YYYYMMDD'),
        stage: this.charge.list.query.stage,
        turnCnt: turnCnt,
        unitId: this.charge.list.query.unitId,
        vehId: vehId,
        vehType: this.charge.list.query.vehType,
      };
      getTurnCntByVeh(params).then(res => {
        if (!this.charge.tages) {
          this.getTags();
        }
        if (res.data.length) {
          this.$message.error(`${item.vehNo}车在此${turnCnt}轮已被占用`);
          let a = `editable${id}`;
          this.$refs[a][0].modify('lock');
          return;
        } else {
          let starPrice = {};
          starPrice = this.charge.getStarList.find(index => {
            return index.starId === item.starId;
          })
          let vehType = item.vehType;
          let stage = item.stage;
          let isSch
          let Sch = this.charge.schoolList.find(item => {
            return item.shortId === this.charge.list.query.school
          });
          if (Sch) {
            isSch = Sch.localTags;
            this.charge.list.query.sch = isSch;
          }

          let basicPrice = this.charge.getCostList.find(item => {
            return item.isSch === isSch && item.vehType === vehType && item.stage === stage
          })
          if (!basicPrice) {
            this.charge.changeState = false;
            this.$message.error('此车号无基本价格，请先在训练费用页面绑定对应价格');
            return;
          }
          else {
            this.preAdd.cellId = [...this.preAdd.cellId, ...[id]];
            this.charge.changeState = true;
          }
          let newarr = [...this.displayarr];
          let ret2 = this.displayarr.findIndex((v) => {
            return v.vehId === vehId && v.turnCnt === turnCnt;
          });
          this.groupPrice = basicPrice ? basicPrice.groupPrice * 0.5 / 100 : 0,
            this.displayarr.push({
              state: state,
              id: id,
              vehNo: item.vehNo,
              starId: item.starId,
              starPrice: starPrice ? starPrice.price * 0.5 / 100 : 0,
              turnCnt: turnCnt,
              price: basicPrice ? basicPrice.price / 100 : 0,
              groupPrice: basicPrice ? basicPrice.groupPrice * 0.5 / 100 : 0,
              groupP: basicPrice ? basicPrice.groupPrice / 100 : 0,
              vipPrice: basicPrice ? basicPrice.vipPrice * 0.5 / 100 : 0,
              vipPr: basicPrice ? basicPrice.vipPrice / 100 : 0,
              vehId: vehId,
              hourPrice: basicPrice ? basicPrice.price * 0.5 / 100 : 0,
              starp: starPrice ? starPrice.price / 100 : 0,
              costTime: 30,
              isvip: this.charge.isVip,
              group: 0
            })
          newarr = [...this.displayarr];
          if (this.displayarr.length) {
            this.charge.selectRecord = this.displayarr[this.displayarr.length - 1];
            this.charge.modifyTrain = true;
            this.charge.studentMessage.list.results = [];
          }
        }
      })
    },
    // 得到可编辑的列名字
    getDataIndex() {
      let arr = [];
      this.charge.columns.forEach(item => {
        if (item.dataIndex !== "turnCnt")
          arr.push({
            vehId: item.dataIndex,
            vehNo: item.title,
            starId: item.starId,
            stage: item.stage,
            vehType: item.vehType
          });
      });
      this.arrIndex = [...arr];
    },
    // 时长增减
    onChange(e) {
      this.displayarr[this.displayarr.length - 1].hourPrice = this.displayarr[this.displayarr.length - 1].price * e;
      this.displayarr[this.displayarr.length - 1].costTime = 60 * e;
      this.displayarr[this.displayarr.length - 1].starPrice = this.displayarr[this.displayarr.length - 1].starp * e;
      this.displayarr[this.displayarr.length - 1].vipPrice = this.displayarr[this.displayarr.length - 1].vipPr * e;
      this.displayarr[this.displayarr.length - 1].groupPrice = this.displayarr[this.displayarr.length - 1].groupP * e;
      this.groupPrice = this.displayarr[this.displayarr.length - 1].groupPrice
    },
    // 鼠标移入事件
    columncolor() {
      this.$nextTick(() => {
        $('.charge .ant-table-row td').mouseover(function () {
          for (let i = 0; i < $('.charge .ant-table-row').length; i++) {
            $('.charge .ant-table-row').eq(i).children().eq($(this).index()).css('background', '#e6f7ff')
            $(this).parent().children().css('background', '#e6f7ff')
          }
        })
        $('.charge .ant-table-row td').mouseout(function () {
          for (let i = 0; i < $('.charge .ant-table-row').length; i++) {
            $('.charge .ant-table-row').eq(i).children().eq($(this).index()).css('background', 'none');
            $(this).parent().children().css('background', 'none')
          }
        })
      })
    },
    getSchool(value) {
    },
    // 得到总和
    getTotal() {
      let total = 0;
      this.orderDetais.list.filter(item => {
        total = total + item.cost / 100;
      })
      return total;
    },
    // 完善学员信息
    addStuMessage() {
      this.stuForm.validateFields((err, values) => {
        if (!err) {
          let query = this.charge.studentMessage.list.query;
          let params = {
            ...query,
            ...values
          }
          if (this.charge.list.query.sch) {
            params.certId = params.certId.toUpperCase();
            values.certId = values.certId.toUpperCase();
            getvalidLocalStu({ certId: params.certId }).then(res => {
              if (res.result) {
                this.$message.error(res.message)
              } else {
                if (res.data) {
                  if (res.data.certId === params.certId) {
                    this.addStuMsg(params, values);
                  } else {
                    this.$message.error("该学员不是本校学员");
                  }
                }
              }
            })
          } else {
            this.addStuMsg(params, values);
          }

        }
      });
    },
    addStuMsg(params, values) {
      this.charge.studentMessage.list.results.push(params);
      let arr = this.displayarr.map(item => {
        if (Object.is(this.charge.selectRecord, item)) {
          item = {
            ...item,
            ...values
          }
        }
        return item;
      })
      this.displayarr = [...arr];
      this.handleFormFieldsReset(this.stuForm);
      this.handleFormFieldsReset(this.stuForm)
    },
    // 删除学员
    delStu(item) {
      let arr = this.charge.studentMessage.list.results.filter(e => {
        return !(item.name === e.name && item.phone === e.phone);
      });
      this.charge.studentMessage.list.results = arr;
      this.$message.info('学员删除成功！')
    },
    // 电话事件判断客户是否为vip
    getTel(isTip) {
      if (!this.charge.list.query.phone) { $('.tel').css({ border: '1px solid red' }); return; }
      // if (!this.charge.list.query.phone.trim().length!==11) {this.$message.error('请输入正确的手机号'); return; }
      const { pageIndex, pageSize, phone, unitId, vipType, name, valid } = this.charge.list.query;
      let params = { pageIndex, pageSize, phone, unitId, vipType, name, valid }
      getVipList(params).then(res => {
        this.getCostList();
        if (!res.message) {
          if (res.data.length) {
            if (this.charge.list.query.stage === 2) {
              this.referee.referee2 = res.data[0].referee2;
              this.referee.referee2Name = res.data[0].referee2Name;
            } else {
              this.referee.referee3 = res.data[0].referee3;
              this.referee.referee3Name = res.data[0].referee3Name;
            }
            this.charge.isVip = true;
            let arr = this.displayarr.map(item => {
              return {
                ...item,
                ...{
                  isvip: this.charge.isVip
                }
              }
            })
            if (res.data[0].vehType) {
              let vehType = res.data[0].vehType;
              let carType = vehType.substr(0, 1) == 'C' ? '小车' : '大车';
              let selType = this.charge.list.query.vehType.substr(0, 1) == 'C' ? '小车' : '大车';
              if (carType == selType) {
                this.charge.isCost = true;
              } else {
                this.charge.isCost = false;
              }
            }
            this.displayarr = arr;
            if (isTip) {
              this.$message.info('该客户为vip客户');
            }
            let isExist = this.charge.schoolList.filter(item => {
              return item.shortId === res.data[0].shortId;
            });
            if (isExist.length) {
              this.charge.list.query.school = res.data[0].shortId;
            }
          } else {
            this.charge.isVip = false;
          }
        } else {
          this.$message.info(res.message);
        }
      }).catch(err => {
        console.log('err', err)
      })
      // 调用函数查询是否为vip
      // 判断是否有订单信息
    },
    //查看优惠券可用性
    couponExists(e) {
      this.preAdd.coupon = e;
      if (!e.trim()) {
        return;
      }
      let query = { coupon: e, eId: sessionStorage.getItem('eId'), stage: this.charge.list.query.stage }
      couponExists(query).then(res => {
        if (!res.data.rowCount) {
          this.$message.info('此优惠券不存在，请输入正确优惠券');
          this.useCoupon = false;
          this.checkBox.isExist = false;
          this.checkBox.couponVal = false;
        } else {
          this.checkBox.isExist = true;
          this.checkBox.couponVal = true;
          this.useCoupon = true;
          this.$message.info('验证成功');
        }
      })
    },
    //是否使用优惠券
    getCoupon(e) {
      this.useCoupon = e.target.checked;
      this.checkBox.isExist = e.target.checked;
    },
    // vip费用复选框
    changeBox(e, record) {
      let arr = this.displayarr.map(item => {
        if (Object.is(record, item)) {
          item.isvip = e.target.checked;
        }
        return item;
      })
      this.displayarr = arr;
    },
    // 是否团购
    getGroup(e, record) {
      if (this.groupPrice == 0) {
        this.$message.info('团购优惠为0元,请先修改团购价');
        return;
      }
      this.checkBox.isGroup = e.target.checked;
      let arr = this.displayarr.map(item => {
        if (Object.is(record, item)) {
          item.group = e.target.checked ? 1 : 0;
        }
        return item;
      })
      this.displayarr = arr;
    },
    // 获取组号
    getTags() {
      getTags().then(res => {
        this.charge.tages = res.data.tags;
      })
    },
    // 基本费用列表
    getCostList() {
      const {
        unitId
      } = this.charge.list.query;
      let params = {
        unitId
      }
      getCostList(params).then(res => {
        this.charge.getCostList = [...res.data]
      })
    },
    // 获取得到车号
    getvehList() {
      let params = this.charge.list.query;
      getvehList(params).then(res => {
        this.allVehNo = [...this.allVehNo, ...res.data];
        let obj = {};
        let peon = this.allVehNo.reduce((cur, next) => {
          obj[next.vehId] ? "" : obj[next.vehId] = true && cur.push(next);
          return cur;
        }, []);
        this.allVehNo = peon;
        let cw = (document.body.clientWidth || document.documentElement.clientWidth) - 160;
        if ((res.data.length + 2) * 85 >= cw) {
          this.tableHeigh.x = (res.data.length + 2) * 85
        } else {
          this.tableHeigh = { y: this.tableHeigh.y }
        }
        if (this.charge.printArr.length) {
          this.$print(this.$refs.print);
          this.charge.printArr = [];
          this.$refs.print.getprintList(this.charge.printArr);
        }
        this.charge.vehNoArr = [...res.data];
        this.option.xAxis.data = [];
        this.charge.columns = [];
        this.charge.columns.push({
          vehNo: 'fir',
          title: '',
          dataIndex: 'turnCnt',
          align: "center",
          customRender: item => {
            return `第${item}轮`
          },
          width: 62
        })
        if (this.rankType === 'vihicleSrc' || this.rankType === 'vihicleDesc') {
          res.data.sort(this.compareKey())
        }
        // console.log('shuju',res.data);
        res.data.map((item, index) => {
          this.setColumns({
            item: item,
            width: 62
          });
        })
        this.charge.columns.push({
          vehNo: 'last',
          dataIndex: 'last',
          align: "center",
          width: 0
        })
        // this.creatCnt(12);

        this.getTodayList();
      })
    },
    // 配置车号列头
    setColumns(el) {
      this.charge.columns.push({
        title: el.item.vehNo + '号',
        vehNo: el.item.vehNo,
        dataIndex: el.item.vehId,
        starId: el.item.starId,
        stage: el.item.stage,
        vehType: el.item.vehType,
        align: "center",
        scopedSlots: {
          customRender: el.item.vehId
        },
        width: el.width
      })
    },
    // 获取当日预约情况
    getTodayList() {
      const date = this.$moment().format('YYYYMMDD')
      const param = {
        orderDate: date,
        orderDateEnd: date,
        stage: this.charge.list.query.stage,
        unitId: this.charge.list.query.unitId,
        vehType: this.charge.list.query.vehType,
      }
      getTodayList(param).then(res => {
        let turnCount = [];
        let index = res.data.map(item => { turnCount.push(item.turnCnt); })
        let number = turnCount.sort(function (a, b) { return b - a; })[0];
        this.creatCnt(number >= 12 ? number + 1 : 12);
        this.getDataIndex();
        this.changeDate(res.data);
        this.creatEchart(true);
        this.option.series[0].data = [];
        this.charge.rankOrder = [];
        let arr = [...this.multie(res.data), ...this.charge.vehNoArr];
        let newArr = this.contArr(arr);
        newArr.sort(this.compareKey()).map(item => {
          if (item.vehNo) {
            item.costTime ? item.costTime : item.costTime = 0;
            const hour = item.costTime / 60;
            this.option.series[0].data.push(hour);
            this.charge.rankOrder.push(item.vehNo);
          }
          return item;
        });
        this.option.xAxis.data = [];
        for (let i = 0; i < this.charge.rankOrder.length; i++) {
          if (this.charge.rankOrder[i]) {
            this.option.xAxis.data.push(this.charge.rankOrder[i] + '号');
          }
        }
        let changeArr = this.charge.rankOrder.map(item => {
          return { 'vehNo': item }
        });
        changeArr.unshift({ 'vehNo': 'fir' });
        changeArr.push({ 'vehNo': 'last' });
        this.charge.columns = this.twoArrContact(changeArr, this.charge.columns);
        this.getDataIndex();
        this.creatEchart(true);
        setTimeout(() => {
          this.columncolor();
        }, 1000)
      })
      this.creatEchart(true);
    },
    // 两个数组对象合并
    twoArrContact(jsonArr, jsonArr2) {
      let firarr = [];
      for (let i = 0; i < jsonArr.length; i++) {
        for (let j = 0; j < jsonArr2.length; j++) {
          if (jsonArr[i]['vehNo'] === jsonArr2[j]['vehNo']) {
            firarr.push(jsonArr2[j]);
            break;
          }
        }
      }
      return firarr;

    },
    // 转换对象
    merge(list) {
      let result = []
      let cache = {}
      list.forEach(item => {
        let key = `key:${item.key},turnCnt${item.turnCnt}`
        let index = cache[key]
        if (index !== undefined) {
          let vehId = this.getKey(item);
          let vehIds = this.getKey(result[0]);
          for (let j = 0; j < vehIds.length; j++) {
            if (vehIds[j] === vehId[0]) {
              if (item[vehId[0]] !== result[0][vehIds[j]]) {
                return;
              }
            } else {
              result[index][vehId[0]] = item[vehId[0]];
            }
          }
        } else {
          result.push(Object.assign({}, item))
          cache[key] = result.length - 1
        }
      })
      return result
    },
    // 组合数组
    contArr(list) {
      let result = []
      let cache = {}
      list.forEach(item => {
        let key = `vehId:${item.vehId}`
        let index = cache[key]
        if (index !== undefined) {
          result[index].vehNo = item.vehNo;
        } else {
          result.push(Object.assign({}, item))
          cache[key] = result.length - 1
        }
      })
      return result
    },
    // 相同项累加
    multie(list) {
      let result = []
      let cache = {}
      list.forEach(item => {
        let key = `vehId:${item.vehId}`
        let index = cache[key]
        if (index !== undefined) {
          result[index].costTime += item.costTime
        } else {
          result.push(Object.assign({}, item))
          cache[key] = result.length - 1
        }
      })
      return result;
    },
    // 动态生成24轮
    creatCnt(key) {
      this.charge.data = [];
      for (let i = 0; i < key; i++) {
        this.charge.data.push({
          turnCnt: i + 1
        })
      }
    },
    //修改数据
    changeDate(dataSource) {
      if (!dataSource.length) {
        return;
      }
      const newArr = this.charge.data.map((item, key) => {
        let arr = dataSource.map((value) => {
          if (value.turnCnt === item.turnCnt) {
            return {
              key: key,
              turnCnt: item.turnCnt,
              [value.vehId]: value.stageMark ? 'property-safety' : 'lock'
            }
          }
        }).filter((item) => item != undefined);
        if (arr.length) {
          if (arr.length >= 2) {
            return this.merge(arr)[0];
          } else {
            return arr[0];
          }
        } else {
          return {
            key: key,
            turnCnt: item.turnCnt,
          };
        }
      })
      // 将多维数组变成一维数组
      this.charge.data = [...newArr];
      // this.charge.data = [... newArr.reduce(function (a, b) { 
      //   return a.concat(b)})];
    },
    //获取对象名
    getKey(item) {
      return Object.keys(item).filter(item => {
        return item != 'key' && item != 'turnCnt'
      })
    },
    // 确认信息无误
    qrInfo(e) {
      this.checkAll = e.target.checked;
    },
    // 预提交订单
    preAddOrder() {
      if (this.useCoupon) {
        this.preAdd.coupon = this.basicForm.getFieldValue('coupon');
        this.preAdd.couponPrice = this.basicForm.getFieldValue('couponPrice')?this.basicForm.getFieldValue('couponPrice') * 100:0;
        // console.log(this.preAdd.couponPrice);
        if(this.preAdd.couponPrice == 0){
          this.$message.info("优惠券金额必须大于0,请重新输入");
          return;
        }
      } else {
        this.preAdd.coupon = '';
        this.preAdd.couponPrice = 0;
      }
      // if(!this.preAdd.isExist&&this.preAdd.coupon){
      //   this.$message.info("此优惠券不存在,请先输入正确的优惠券!");
      //   return;
      // }
      if (!this.charge.studentMessage.list.results.length) {
        this.$message.info('请先完善学员信息');
        return;
      }
      let arr = this.displayarr.map(item => {
        if (item.isVip === false) {
          item.vipPrice = 0;
        }
        return item;
      })
      this.displayarr = arr;
      const query = this.preAdd;
      const params = {
        ...query,
        ...this.displayarr[this.displayarr.length - 1]
      }
      params.shortId = this.charge.list.query.school;
      params.stage = this.charge.list.query.stage;
      params.vehType = this.charge.list.query.vehType;
      params.userId = sessionStorage.getItem('userId');
      params.personCnt = this.charge.studentMessage.list.results.length;
      params.status = 0;
      params.tags = this.charge.tages;
      params.unitId = sessionStorage.getItem('unitId');
      params.phone = this.charge.list.query.phone;
      if (this.charge.list.query.stage === 2) {
        params.referee = this.referee.referee2;
      } else {
        params.referee = this.referee.referee3;
      }
      let isquery = {
        orderDate: this.$moment().format('YYYYMMDD'),
        orderDateEnd: this.$moment().format('YYYYMMDD'),
        stage: this.charge.list.query.stage,
        turnCnt: params.turnCnt,
        unitId: this.charge.list.query.unitId,
        vehId: params.vehId,
        vehType: this.charge.list.query.vehType,
      };
      if (this.useCoupon) {
        let e = this.basicForm.getFieldValue('coupon');
        let query = { coupon: e, eId: sessionStorage.getItem('eId'), stage: this.charge.list.query.stage }
        couponExists(query).then(res => {
          if (!res.data.rowCount) {
            this.useCoupon = false;
            this.checkBox.isExist = false;
            this.checkBox.couponVal = false;
            this.preAdd.couponPrice = 0;
            this.$message.info('此优惠券不存在,请重新输入优惠券');
          } else {
            this.$message.info('优惠券验证成功');
            this.useCoupon = true;
            this.checkBox.isExist = true;
            this.checkBox.couponVal = false;
            this.preAddLast(isquery, params);
          }
        })
      } else {
        if (this.checkBox.couponVal && !this.checkBox.isExist) {
          this.$message.info('未选中优惠券，不能享受优惠券优惠!');
        }
        this.preAddLast(isquery, params)
      }
    },
    preAddLast(isquery, params) {
      getTurnCntByVeh(isquery).then(res => {
        // console.log('res',res)
        if (res.data.length) {
          this.$message.error(`${isquery.vehNo}车在此${isquery.turnCnt}轮已被占用`);
        } else {
          preAdd(params).then(res => {
            if (!res.message) {
              if (res.data.enterCode) {
                this.charge.modifyTrain = false;
                this.checkBox.isExist = false,
                  this.checkBox.isGroup = false,
                  this.checkBox.couponVal = false;
                this.useCoupon = false
                this.handleFormFieldsReset(this.basicForm);
                this.handleFormFieldsReset(this.stuForm);
                this.charge.costTime = 0.5;
                this.orderDetais.list.push(res.data);
                this.orderDetais.list.map(item => {
                  return item.vehNo = this.allVehNo.filter(e => {
                    return e.vehId === item.vehId;
                  })[0].vehNo;
                })
                if (res.data.cId) {
                  this.charge.studentMessage.list.results.map(item => {
                    addStu({
                      cId: res.data.cId,
                      name: item.name,
                      phone: item.phone,
                      certId: item.certId,
                      cashType: item.cashType
                    }).then((res) => {
                      this.lockIcon();
                      this.charge.costTime = 0.5;
                    })
                  })
                } else {
                  this.$message.err('提交订单失败，请重新提交订单')
                }
              } else {
                this.$message.error('提交失败，请重新提交订单!')
              }
            } else {
              this.$message.error(res.data.message);
            }
          })
        }
      })
    },
    // 无验证码
    refresh(item) {
      const { cType, cashType, costTime, enterCode, netType, orderDate, personCnt, phone, referee, shortId, stage, status, tags, turnCnt, unitId, userId, vehId, vehType } = item;
      let params = { cType, cashType, costTime, enterCode, netType, orderDate, personCnt, phone, referee, shortId, stage, status, tags, turnCnt, unitId, userId, vehId, vehType }
      preAdd(params).then(res => {
        if (!res.message) {
        }
      })
    },
    // 打开购物车列表
    shopCar() {
      this.orderDetais.visible = true;
    },
    // 不加入购物车
    canclecar() {
      this.handleFormFieldsReset(this.basicForm);
      this.handleFormFieldsReset(this.stuForm);
      this.checkBox.isExist = false;
      this.checkBox.isGroup = false;
      this.checkBox.couponVal = false;
      this.useCoupon = false;
      this.charge.costTime = 0.5
      this.charge.modifyTrain = false;
      this.preAdd.cellId = this.preAdd.cellId.filter(item => { return item !== this.displayarr[this.displayarr.length - 1].id });
      this.displayarr = [];
    },
    // 选择下方短信等等
    selectServe(checkedValues) {
      this.checkedList = [...checkedValues];
    },
    // 取消所有订单
    cancelAllOrder() {
      if (this.orderDetais.list.length) {
        const {
          unitId,
          orderDate,
          tags
        } = this.orderDetais.list[0];
        let params = {
          unitId,
          orderDate,
          tags
        }
        cancelTags(params).then(res => {
          if (res.data.rowCount) {
            this.orderDetais.list = [];
            this.displayarr = [];
            this.getvehList();
            this.orderDetais.visible = false;
            this.preAdd.cancelId = [...this.preAdd.cellId];
            this.unlockIcon();
            this.preAdd.cellId = [];
            this.$message.success('订单已全部取消');
            this.charge.tages = '';
          } else {
            this.$message.error('订单取消失败，请重新取消');
          }
          // 数组清空
        }).catch(err => {
          console.log('err', err)
        })
      }
    },
    // 确认所有订单
    async  confirmAllOrder() {
      this.charge.printArr = []
      if (!this.checkAll) {
        this.$message.info('已确认以上信息无误,再提交订单');
        return;
      }
      if (this.orderDetais.list.length) {
        const { unitId, orderDate, tags, cashType } = this.orderDetais.list[0];
        let params = {
          unitId,
          orderDate,
          tags,
          cashType
        }
        confirm(params).then(res => {
          if (res.data.rowCount) {
            this.orderDetais.list = [];
            this.displayarr = [];
            this.charge.studentMessage.list.results = [];
            this.orderDetais.visible = false;
            this.checkAll = false;
            this.$message.success('订单已提交');
            this.preAdd.cancelId = [...this.preAdd.cellId];
            this.preAdd.cellId = [];
            this.charge.tages = '';
            // 在这里
            let params = { unitId, tags }
            getListByTags(params).then(res => {
              this.$nextTick(() => {
                this.charge.printArr = res.data.filter(item => { return item.stageMark });
                let arr = res.data.filter(item => { return item.cType == 2 || (item.cType == 0 && item.localTags == 1) })
                if (arr.length == 0) {
                  this.$refs.print.getprintList(this.charge.printArr);
                  this.getvehList();
                } else {
                  this.makePrintDate();
                }
              })
            }).catch(err => {
              console.log(err);
            })
          } else {
            this.$message.error('请重新提交订单');
          }
          // 数组清空
        });
      }
    },
    async makePrintDate() {
      for (let i = 0; i < this.charge.printArr.length; i++) {
        let param = { cId: this.charge.printArr[i].cId }
        if (this.charge.printArr[i].cType == 2 || (this.charge.printArr[i].cType == 0  && this.charge.printArr[i].localTags == 1)) {
          await this.modifyStu(param, i);
        }
      }
      this.$refs.print.getprintList(this.charge.printArr);
      this.getvehList();
    },
    // 获取学员信息
    async modifyStu(params, i) {
      return new Promise((resolve, reject) => {
        modifyStu(params).then(res => {
          delete res.data[0].phone;
          this.charge.printArr[i] = { ...this.charge.printArr[i], ...res.data[0] }
          resolve();
        })
      })
    },
    // 取消订单
    cancelOrder(item) {
      cancel({
        cId: item.cId,
        unitId: item.unitId
      }).then(res => {
        if (res.data.rowCount) {
          this.unlockIcon();
          this.$message.success('订单取消成功');
          this.checkAll = false;
          const cancelItem = this.displayarr.filter(e => {
            return e.vehId === item.vehId && e.turnCnt === item.turnCnt
          })
          this.preAdd.cancelId = [...this.preAdd.cancelId, ...[cancelItem[0].id]];
          if (cancelItem.length) {
            this.preAdd.cellId = this.preAdd.cellId.filter(item => { return item !== cancelItem[0].id });
          }
          this.orderDetais.list = this.orderDetais.list.filter(e => {
            return e !== item
          })
          this.getvehList();
          if (!this.orderDetais.list.length) {
            this.orderDetais.visible = false;
            this.displayarr = [];
          }
        } else {
          this.$message.error('订单取消失败，请重新取消');
        }
      });
    },
    // 刷新
    refreh() { },
    // 重置完善学员信息
    resetInfo() {
      this.charge.modifyTrain = false;
      this.handleFormFieldsReset(this.basicForm);
      this.handleFormFieldsReset(this.stuForm);
    },
    // 关闭收费详情model
    handleOk() {
      this.orderDetais.popVisable = false;
      setTimeout(() => {
        this.orderDetais.visible = false;
        this.cancelAllOrder();
      }, 200);

    },
    cancel() {
    },
    // 选择驾校
    setSch(e) {
      this.charge.list.query.school = e;
      this.displayarr = [];
    },
    creatEchart(chart) {
      this.$nextTick(() => {
        if (document.getElementById('main')) {
          var myChart = echarts.init(document.getElementById('main'));
          myChart.setOption(this.option, chart);
          window.onresize = myChart.resize;
        }
      })
    },
    // 数组排序 
    compareKey() {
      let a = 0;
      let b = 0;
      let c = 0;
      let key = "";
      if (this.rankType === "vihicleSrc") {
        key = 'vehNo';
        a = -1
        c = 1
      } else if (this.rankType === "vihicleDesc") {
        key = 'vehNo'
        a = 1
        c = -1
      } else if (this.rankType === "orderSrc") {
        key = 'costTime';
        a = -1
        c = 1

      } else if (this.rankType === "orderDesc") {
        key = 'costTime';
        a = 1
        c = -1
      }
      return function (obj1, obj2) {
        if (obj1[key] < obj2[key]) {
          return a;
        } else if (obj1[key] === obj2[key]) {
          return b;
        } else {
          return c;
        }
      };
    },
    // 排序方式
    rank(type) {
      // orderSrc预约量升序  orderDesc预约量升序   vihicleSrc 车号升序  vihicleDesc车号降序;
      this.rankType = type;
      this.getvehList();
    },
    // 折叠面板
    collapseChange(key) {
      if (!key) {
        this.tableHeigh.y = document.documentElement.clientHeight - 270;
        $('.chargemodify').css({ height: document.documentElement.clientHeight - 230 + 'px' })
        $('#collapse').css({
          position: 'absolute',
          bottom: '0px',
          width: '140px',
        });
        $('#collapse i').css({
          color: '#fff'
        });
        $('#collapse span').css({
          color: '#fff'
        });
        $('.ant-collapse-item').css({
          background: '#36C5C3',
        });
        this.creatEchart(true);
      } else {
        this.tableHeigh.y = 410;
        $('.chargemodify').css({ height: '465px' })
        this.creatEchart(true);
        $('#collapse').css({
          position: 'relative',
          width: '100%',
        });
        $('#collapse i').css({
          color: '#000'
        });
        $('#collapse span').css({
          color: '#000'
        });
        $('.ant-collapse-item').css({
          background: '#fff',
        });
      }
    },
    // 点击图标变锁定
    lockIcon() {
      this.$nextTick(() => {
        for (let i = 0; i < this.preAdd.cellId.length; i++) {
          let a = `editable${this.preAdd.cellId[i]}`;
          this.$refs[a][0].modify('lock');
        }
      })
    },
    unlockIcon() {
      this.$nextTick(() => {
        for (let i = 0; i < this.preAdd.cancelId.length; i++) {
          let a = `editable${this.preAdd.cancelId[i]}`;
          this.$refs[a][0].modify('user-delete');
        }
      })
    },
    // 关闭收费详情model
    closeDetail() {
      this.orderDetais.visible = false;
      this.checkAll = false;
      // this.lockIcon();
    },
    // 使input失去焦点
    timeLblur() {
      $('.ant-input-number-input').blur();
    },
    setXY(x, y) {
      this.drag.x = x;
      this.drag.y = y;
    },
    getFlag() {
      return this.drag.flag;
    },
    setFlag(num) {
      this.drag.flag = num;
    },
    setActive(b) {
      this.drag.isActive = b;
    },
    setRedBorder(b) {
      this.drag.redBorder = b;
    }
  },
  directives: {
    drag(el, binding) {
      el.onclick = function (e) {
        // debugger;
        var flag = binding.value.getFlag();
        flag = !flag;
        var disx = e.pageX - el.offsetLeft;
        var disy = e.pageY - el.offsetTop;
        document.onmousemove = function (e) {
          var xx = e.pageX - disx + 'px';
          var yy = e.pageY - disy + 'px'
          el.style.left = xx;
          el.style.top = yy;
          binding.value.setXY(xx, yy)
        };
        if (!flag) {
          document.onmousemove = document.onmouseup = null;
        }
        binding.value.setFlag(flag);
        binding.value.setActive(flag);
        binding.value.setRedBorder(!flag);
      }
    }
  },
  mounted() {
    this.isFav();
    this.getvehList();
    // 获取驾校列表
    this.charge.schoolList = JSON.parse(sessionStorage.getItem('driverSchool'));
    this.charge.getStarList = JSON.parse(sessionStorage.getItem('starCost'));
    let school = this.charge.schoolList.find(item => {
      return item.localTags === 0;
    })
    if (school) {
      this.charge.list.query.school = school.shortId;
    }
  },
  activated() {
    this.isFav();
  },
}