import {cancelFav,getUserFav} from "@/service/public/index";
export default {
  name: "shortcutEntry",
  data() {
    return {
          shortcutEntry:{
            list: {
                query: {
                  unitId:sessionStorage.getItem('unitId'),
                  userId: sessionStorage.getItem('userId')
                },
                total: 0,
                results: []
              },
          }
    }
  },
  methods: {
      handleClick(path) {
          this.$router.push(path);
      },
      cancelFav(item){
          let query=this.shortcutEntry.list.query;
          query.menuName=item.path.substr(1,);
          cancelFav(query).then(res=>{
            if(!res.message){
              this.getUserFav();
              this.$message.info('快捷入口菜单取消成功');
              this.setUserRoles();
          }else{
              this.$message.error(res.message);
          }
          }).catch(err=>{
            console.log('err',err)
          })
      },
      getUserFav(){
        let query={userId: sessionStorage.getItem('userId')}
        getUserFav(query).then(res=>{
          this.shortcutEntry.list.results=[];
            for(let item of res.data){
              this.shortcutEntry.list.results.push( {
                title:item.menuText,
                path:`/${item.menuName}`,
                color:item.menuColor,
                iconType:item.menuIcon
            },)
            }
        }).catch(err=>{
            console.log('err',err)
        })
      },
  },
  created() {

  },
  mounted(){
    $('.ant-layout-content').css({'backgroundColor':'#f0f0f0'});
    this.getUserFav();
    this.isFav();
  },
  activated(){
    this.getUserFav();
    this.isFav();
  },
  destroyed(){
    $('.ant-layout-content').css({'backgroundColor':'#fff'});
}
};