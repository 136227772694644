const vipMag = resolve => require(["@/views/resManagement/vipManagement/vipMag/index.vue"], resolve);
const vipQua = resolve => require(["@/views/resManagement/vipManagement/vipQua/index.vue"], resolve);
export default {
    //新增订单
    name: "vipManagement",
    data() {
        return {
            //编辑页面数据
            vipMag: {
                list: {
                    query: {
                        pageIndex: 1,
                        pageSize: 1000,
                        name: '',
                        phone: '',
                        unitId: sessionStorage.getItem('unitId'),
                        vipType: 1,
                        valid: 0
                    },
                    total: 0,
                    results: []
                },
            },
            timeOut:{
                times:0
            }
        }
    },
    methods: {
        queryvipManage() {
            let querys = this.vipMag.list.query;
            this.$store.dispatch("StoreDataQuery/setQueryByNews", querys)
        },
    },
    watch: {},
    created() {
    },
    components: {
        vipMag,
        vipQua
    },
    mounted() {
        // if(this.$route.query.key === "loop"){
        //     this.queryvipManage();
        // }
    },
    watch: {
        $route: {
          handler: function(val, oldVal){
            if(val.path !== '/vipManagement'){
                clearInterval(this.timeOut.times);
            }
          },
          // 深度观察监听
          deep: true
        }
      },
    activated(){
        if(this.$route.query.key === "loop"){
            this.queryvipManage();
            this.timeOut.times = setInterval(this.queryvipManage,7200000);
           }
    }
};