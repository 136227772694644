import { getLocalCarMovDetail } from "@/service/businessData/index";
export default {
    name: "teaTrain",
    data() {
        return {
            teaTrain: {
                list: {
                    query: {
                        begin: this.$moment().add(0, 'day').format('YYYYMMDD'),
                        end: this.$moment().add(1, 'day').format('YYYYMMDD'),
                        // begin: this.$moment().format('YYYYMMDD'),
                        // end: this.$moment().format('YYYYMMDD'),
                        eId: sessionStorage.getItem('eId'),
                        certId:'',
                        phone:''
                    },
                    total: 0,
                    results: [],
                },
                daterange: [this.$moment().add(0, 'day'), this.$moment().add(0, 'day')],
                // daterange: [this.$moment().format('YYYYMMDD'), this.$moment().format('YYYYMMDD')],
                columns: [{
                    width: 75,
                    title: "序号",
                    scopedSlots: {
                        customRender: "SerialNumber"
                    },
                },
                {
                    title: "车号",
                    dataIndex: "vehNo",
                },
                {
                    title: "车牌号",
                    dataIndex: "veh",
                },
                {
                    title: "科目",
                    dataIndex: "stage",
                },
                {
                    title: "开始时间",
                    dataIndex: "begin",
                },
                {
                    title: "结束时间",
                    dataIndex: "end",
                },
                {
                    title: "教练姓名",
                    dataIndex: "teaName",
                },
                {
                    title: "教练身份证号",
                    dataIndex: "teaCertId",
                },
                    {
                        title: "电话号码",
                        dataIndex: "phone",
                    },
                    {
                        title: "用车时长/分钟",
                        dataIndex: "ycTime",
                    }
                ]
            },
            excelName: ['用车明细'],
            selName: [],
            excelData: [],
            userId: sessionStorage.getItem('userId')
        }
    },
    methods: {
        querysafer() {
            let querys = this.teaTrain.list.query;
            getLocalCarMovDetail(querys).then(res => {
                this.teaTrain.list.results = [...res.data];
                // this.teaTrain.list.results = this.teaTrain.list.results.sort(this.compareKey());
                let arr  = this.teaTrain.list.results
                for (var i = 0; i < arr.length; i++ ) {
                   // arr.[i].push("ycTime")
                    arr[i].ycTime =''
                }
                this.teaTrain.list.results = this.teaTrain.list.results.map(item => {
                    item.begin = this.changeDate(item.begin).replace(/-/g,'/');
                    item.end = this.changeDate(item.end).replace(/-/g,'/');
                    var data =new Date(item.begin)
                    var dataa =new Date(item.end)
                    var kstime=data.getTime()
                    var edtime=dataa.getTime()
                    // item.begin =data.getTime()
                    // item.end =dataa.getTime()
                    item.ycTime=Math.round((edtime-kstime)/60000)+'分钟'
                    return item
                });
                this.resetExport();
                this.selName = [`查询日期: ${this.teaTrain.list.query.begin}-${this.teaTrain.list.query.end}`, " ", " ", " ", " ", " ", " ", " "," "," "," "];
                let excelsource = this.comexport(this.teaTrain.columns, this.teaTrain.list.results)
                this.excelData = [... this.excelData, ...excelsource];
                this.excelData.push([`结算日期：${this.$moment(this.teaTrain.daterange[0]).format('YYYY-MM-DD')}至${this.$moment(this.teaTrain.daterange[1]).format('YYYY-MM-DD')}`, " ", " ", " ", " ", " ", " ", " ", " ", " "," "," "," "]);
                this.teaTrain.list.results = this.teaTrain.list.results.map((item, index) => {
                    return { ...item, ...{ id: index } }
                })
            })
        },
        resetExport() {
            this.selName = [];
            this.excelData = [];
        },
        getDate() {
            this.$nextTick(() => {
                this.$refs.export.getExport(this.selName, this.excelName, this.excelData);
            })
        },
        getDateStr(e) {
            this.teaTrain.list.query.begin = this.$moment(e[0]).format('YYYYMMDD');
            this.teaTrain.list.query.end = this.$moment(e[1]).format('YYYYMMDD');
            this.teaTrain.daterange = e;
        },
        changeDate(item) {
            if (item) {
                let startT = item.lastIndexOf('T');
                var result = `${item.substring(0, startT)} ${item.substring(startT + 1, item.lastIndexOf('.'))}`;
                return result;
            }
        },
    },
    created() {
    },
    mounted() {
        this.isFav();
        this.querysafer();
    },
    activated() {
        this.isFav();
    }
};
