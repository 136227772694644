const devManagemen = resolve =>require(["@/views/dev/devManagement/devManagemen/index.vue"], resolve);
const printerParameters = resolve =>require(["@/views/dev/devManagement/printerParameters/index.vue"], resolve);
export default {
    //新增订单
    name: "dev",
    data() {
        return {}
    },
    methods: {
    },
    watch: {},
    created() {
    },
    components: {
        devManagemen,
        printerParameters
    },
    mounted() {}
}
