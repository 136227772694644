var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"rental"},[_c('ContentSearch',[_c('template',{slot:"input"},[_c('div',{staticClass:"conditional-item advanced"},[_c('span',{staticClass:"item-name"},[_vm._v("选择时间：")]),_c('a-range-picker',{attrs:{"allowClear":false,"value":_vm.CarRuntimeReport.daterange,"placeholder":['开始日期', '结束日期']},on:{"change":function (e) {
              _vm.CarRuntimeReport.daterange = e;
              _vm.handleChangeOnRangePicker(
                e,
                _vm.CarRuntimeReport.list.query,
                false,
                'been'
              );
            }}})],1),_c('div',{staticClass:"btn"},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.selectData}},[_vm._v("查询")])],1)]),_c('template',{slot:"operation"},[_c('a',{staticClass:"menu",on:{"click":function($event){return _vm.addFav(_vm.userId, _vm.$store.getters.currentMenu)}}},[_c('a-icon',{style:({ color: _vm.getFav ? 'rgb(7,167,227)' : '#FFD700' }),attrs:{"fill":"currentColor","type":"star"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.getFav),expression:"!getFav"}]},[_vm._v("收藏")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.getFav),expression:"getFav"}]},[_vm._v("已收藏")])],1)])],2),_c('a-table',{attrs:{"rowKey":function (record) { return record.end; },"loading":_vm.$store.getters.pageView.pageLoading,"columns":_vm.columns,"dataSource":_vm.CarRuntimeReport.list.results},scopedSlots:_vm._u([{key:"SerialNumber",fn:function(text, record, index){return [_vm._v(_vm._s(index + 1))]}}])}),_c('router-view')],1)}
var staticRenderFns = []

export { render, staticRenderFns }