import {teaCommisionDetail} from "@/service/businessData/index";
export default {
    name: "securityDetail",
    data() {
        return {
            securityDetail: {
                list: {
                    query: {
                        begin:this.$moment().format('YYYYMMDD'),
                        end: this.$moment().format('YYYYMMDD'),
                        unitId:sessionStorage.getItem('unitId'),
                        userId: sessionStorage.getItem('userId')
                    },
                    total: 0,
                    results:[]
                },
                // this.$moment().startOf('month')
                daterange: [this.$moment(),this.$moment()],
                columns: [{
                        width: 75,
                        title: "序号",
                        align: "center",
                        scopedSlots: {
                            customRender: "SerialNumber"
                        },
                    },
                    {
                        title: "日期",
                        dataIndex: "orderDate",
                        width: 150,
                    },
                    {
                        title: "姓名",
                        dataIndex: "teaName",
                        width: 150,
                    },
                    {
                        title: "科目二小车训练小时",
                        dataIndex: "smallVehStage2",
                        width: 150,
                        customRender:item=>{
                            return item/60
                      },
                    },
                    {
                        title: "科目二大车训练小时",
                        dataIndex: "bigVehStage2",
                        width: 150,
                        customRender:item=>{
                            return item/60
                      },
                    },
                    {
                        title: "科目三小车训练小时",
                        dataIndex: "smallVehStage3",
                        customRender:item=>{
                              return item/60
                        },
                        width: 150,
                    },
                    {
                        title: "科目三大车训练小时",
                        dataIndex: "bigVehStage3",
                        width: 150,
                        customRender:item=>{
                            return item/60
                      },
                    }
                ]
            },
            excelName: ['安全员提成明细'],
            selName:[],
            excelData:[],
            userId:sessionStorage.getItem('userId')
        }
    },
    methods: {
        querySecurity() {
            let querys = this.securityDetail.list.query;
            teaCommisionDetail(querys).then(res=>{
                this.securityDetail.list.results=[...res.data];
                this.resetExport();
                this.selName = [`查询日期: ${this.securityDetail.list.query.begin}-${this.securityDetail.list.query.end}`," "," "," "," "," "," "];
                let excelsource= this.comexport(this.securityDetail.columns,this.securityDetail.list.results)
                this.excelData=[... this.excelData,...excelsource];
                this.excelData.push([`结算日期：${this.$moment(this.securityDetail.daterange[1]).format('YYYY-MM-DD')}`," "," "," "," "," "," "]);
            })

            //判断是否为点击页码查询
        },
        getDate(){
            this.$nextTick(()=>{
                this.$refs.export.getExport(this.selName,this.excelName,this.excelData);
            })
        },
        getDateStr(e){
            this.securityDetail.list.query.begin = this.$moment(e[0]).format('YYYYMMDD');
            this.securityDetail.list.query.end = this.$moment(e[1]).format('YYYYMMDD')
            this.securityDetail.daterange = e;
        },
        resetExport(){
            this.selName=[];
            this.excelData=[];
        }
    },
    created() {
        
    },
    mounted() {
        // if(!this.$route.meta.keepAlive){
        //     this.isFav();
        //     this.querySecurity();
        //   }
        this.isFav();
        this.querySecurity();
    },
    activated(){
        this.isFav();
        // this.querySecurity();
    }
};