import Vue from 'vue'
import { InputNumber } from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// console.log('InputNumber',InputNumber)
Vue.use(InputNumber);
import {
  getListCountByUserId,
  apply,
  sendCode,
  modifyStu,
  stuDataa,
  stuDel,
  addStu,
  postValidUser,
  getListByUserId,
  getTrainList,
  getTrainPhoto,
} from "@/service/charge/index";
import {
  getList,
} from "@/service/userManagement/index";
const columnsss = [{
  title: '学员姓名',
  dataIndex: 'stuName',
  width: '25%',
  scopedSlots: { customRender: 'stuName' },
}, {
  title: '手机号',
  dataIndex: 'phone',
  width: '25%',
  scopedSlots: { customRender: 'phone' },
}, {
  title: '身份证号',
  dataIndex: 'certId',
  width: '25%',
  scopedSlots: { customRender: 'certId' },
}, {
  title: '操作',
  dataIndex: 'operation',
  scopedSlots: { customRender: 'operation' },
  width: '25%',
  align: 'center'
}]
const data = [];
export default {
  //新增订单
  name: "billingDetails",
  data() {
    return {
      vueAmaps: {
        zoom: 13,
        center: [104.762406, 31.420331],
        vdata: [],//车辆定位数据
        vmarkers: [],//车辆定位
        infoWindow: {},//信息窗体
        cluster: null,//点聚合
        texts: [],
        currentWindow: [],
        window: {}
      },
      data,
      remnant: 30,
      userId: sessionStorage.getItem('userId'),
      columnsss,
      conStu: {
        cId: '',
        name: '',
        phone: '',
        certId: '0',
        newPhone: '',
        newName: '',
        newCertId: '',
        resultss: [],
        oldRecord: {}
      },
      //编辑页面数据
      charge: {
        stageMarks: '',
        printArr: [],
        codeVal: ''
      },
      billingDetails: {
        list: {
          query: {
            pageIndex: 1,
            pageSize: 10,
            orderDate: this.$moment().format('YYYYMMDD'),
            orderDateEnd: this.$moment().format('YYYYMMDD'),
            stage: 0,
            unitId: sessionStorage.getItem('unitId'),
            vehType: '',
            status: -1,
            stageMark: 0,
            cId: '',
            userId: '',
            vehNo: 0,
          },
          total: 0,
          results: [],
        },
        daterange: [this.$moment(), this.$moment()],
        stuCid: '',
        apply: false,
        radResult: [],
        selectedInfo: [],
      },
      advancequry: {},
      // 培训详情
      trainingDetails: {
        list: {
          query: {
            pageIndex: 1,
            pageSize: 10,
            orderDate: this.$moment('2019-05-01').format('YYYYMMDD'),
            orderDateEnd: this.$moment().format('YYYYMMDD'),
            stage: 0,
            unitId: sessionStorage.getItem('unitId'),
            veh: '',
            vehType: '',
            stageMark: 0,
            cType: '',
            coachName: '',
            userId: sessionStorage.getItem('userId'),
            vehNo: 0,
          },
          total: 0,
          results: [],
          resultsLog: []
        },
        AllocateLogVisible: false,
        daterange: [],
      },
      modifyStuDataVisible: false,
      seeStudentInformation: false,
      seeCodeVisible: false,
      modifyTrain: false,
      modalTitle: '异常申请',
      basicForm: this.$form.createForm(this),
      stuForm: this.$form.createForm(this),
      //表头
      columns: [
        {
          width: 65,
          title: "序号",
          align: "center",
          scopedSlots: {
            customRender: "SerialNumber"
          }
        }, {
          title: "业务编号",
          dataIndex: "stageMark",
          scopedSlots: {
            customRender: "stageMark"
          }
        }, {
          title: "收费",
          dataIndex: "cost",
          customRender: (item) => {
            return item / 100
          }
        }, {
          title: "指派车辆",
          dataIndex: "veh",
        },
        {
          title: "业务日期",
          dataIndex: "orderDate",
        },
        {
          title: "车号",
          dataIndex: "vehNo",
          customRender: (item) => {
            return `${item}号`
          }
        },
        {
          title: "轮次",
          dataIndex: "turnCnt",
          customRender: (item) => {
            return `${item}`
          }
        }, {
          title: "车型",
          dataIndex: "vehType",
          customRender: (item) => {
            return item ? item : '暂无'
          }
        }, {
          title: "科目",
          dataIndex: "stage",
          customRender: (item) => {
            return `${item}`
          }
        }, {
          title: "业务状态",
          dataIndex: "status",
          customRender: (item) => {
            return this.$enums.stageStatus[item];
          }
        }, {
          title: "业务类型",
          dataIndex: "cType",
          customRender: (item) => {
            return this.$enums.cType[item];
          }
        }, {
          title: "联系电话",
          dataIndex: "phone",
          customRender: (item) => {
            return item ? item : '暂无'
          }
        }, {
          title: "购买时长",
          dataIndex: "costTime",
          customRender: (item) => {
            return `${item / 60} 小时`
          }
        }, {
          title: "验证码",
          dataIndex: "enterCode",
          scopedSlots: {
            customRender: "operations"
          }
        },{
          title: "操作人员",
          // 虚拟
          dataIndex: "nickName",
          customRender: (item) => {
            return item ? item : '微信公众号'
          }
        }, {
          title: "操作",
          scopedSlots: {
            customRender: "operation"
          }
        }],
      //关联学员表头
      stucolumns: [{
        title: "学员姓名",
        dataIndex: "stuName",
        scopedSlots: {
          customRender: "stuName"
        }
      }, {
        title: "身份证号",
        dataIndex: "certId",
        scopedSlots: {
          customRender: "certId"
        }
      },
      {
        title: "联系电话",
        dataIndex: "phone",
        scopedSlots: {
          customRender: "phone"
        }
      },
      {
        title: "操作",
        scopedSlots: {
          customRender: "operation"
        }
      }
      ],
      viewstu: [{
        title: "学员姓名",
        dataIndex: "stuName",
        scopedSlots: {
          customRender: "stuName"
        }
      }, {
        title: "身份证号",
        dataIndex: "certId",
        scopedSlots: {
          customRender: "certId"
        }
      },
      {
        title: "联系电话",
        dataIndex: "phone",
        scopedSlots: {
          customRender: "phone"
        }
      }
      ],
      excelName: ['计费详情'],
      selName: [],
      excelData: [],
      userArr: [],
      stageMarkDeatil: {
        visible: false,
        stuMsg: {},
        data: {},
        trainPhotos: [],
        checkPhotos: [],
        previewImage: '',
        previewVisible: false,
        tabindex: "1",
        isSearch: false
      },
      // 业务拆分
      BuSplitParams: {
        results: {},
        addStage: [],
        totalMin: 0,
        model: {
          visible: false
        }
      }
    };
  },
  methods: {
    // 查询列表
    async  queryBilling(queryBy) {
      let querys = this.billingDetails.list.query;
      //判断是否为点击页码查询
      if (queryBy !== "pagination") {
        querys.pageIndex = 1;
      }
      await getListCountByUserId(querys).then((res) => {
        this.billingDetails.list.total = res.data.rowCount;
        this.resetExport();
        if (res.data.rowCount) {
          getListByUserId(querys).then((res) => {
            this.$nextTick(() => {
              this.billingDetails.list.results = res.data;
            });
            this.querySaferManag();
          }).catch((err) => {
            console.log('err', err)
          })
        } else {
          this.$nextTick(() => {
            this.querySaferManag();
            this.billingDetails.list.results = [];
            this.billingDetails.list.total = 0;
          })
        }
      }).catch((err) => {
        console.log('err', err)
      })
    },
    getDate() {
      this.resetExport();
      let querys = this.billingDetails.list.query;
      let serch = { ...querys };
      serch.pageIndex = 1;
      serch.pageSize = this.billingDetails.list.total + 100;
      getListByUserId(serch).then((res) => {
        this.selName = [`业务编号：${this.billingDetails.list.query.stageMark}`, `查询日期: ${this.billingDetails.list.query.orderDate}-${this.billingDetails.list.query.orderDateEnd}`, `业务状态: ${this.$rformat.methods.r_BusinessStatus(this.billingDetails.list.query.status)}`, `科目: ${this.$rformat.methods.r_projectItem(this.billingDetails.list.query.stage)}`, "  ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " "];
        let excelsource = this.comexport(this.columns, res.data);
        excelsource = this.exportDate(excelsource);
        this.excelData = [...this.excelData, ...excelsource];
        const exportOther = ["","",""]
        if(this.excelData[0].length===18) {
          this.selName.push(...exportOther)
        }
        this.$refs.export.getExport(this.selName, this.excelName, this.excelData);
      })
    },
    // 关闭查询验证码model
    handleCancel() {
      this.seeCodeVisible = false;
      this.charge.codeVal = ''
    },
    conAddStuMessage() {
      // let querys = this.coonAddStu;
      this.stuForm.validateFields((err, values) => {
        if (!err) {
          values.cId = this.billingDetails.stuCid;
          addStu(values).then((res) => {
            if (res.message.length) {
              this.$message.error(res.message)
            } else {
              this.$message.success('新增成功');
              this.handleFormFieldsReset(this.stuForm);
              this.modifyStuData({ cId: this.billingDetails.stuCid, status: 0 })
            }
          }).catch((error) => {
            console.log(error);
          })
        }
      });
    },
    handleChange(value, id, columnsss) {
      const newData = this.conStu.resultss
      const target = newData.filter(item => id === item.id)[0]
      if (target) {
        target[columnsss] = value
        this.conStu.resultss = newData
      }
    },
    edit(record) {
      this.oldRecord = { ...record, ...{ name: record.stuName }, ...{ certId: record.certId ? record.certId : '' } };
      const newData = [...this.conStu.resultss];
      const target = newData.filter(item => record.id === item.id)[0];
      if (target) {
        target.editable = true
        this.conStu.resultss = newData
      }
    },
    save(record) {
      let params = { newName: record.stuName, newPhone: record.phone, newCertId: record.certId ? record.certId : '' };
      let query = { ...{ cId: this.billingDetails.stuCid }, ...params, ...this.oldRecord };
      const newData = [...this.conStu.resultss];
      const target = newData.filter(item => record.id === item.id)[0]
      if (target) {
        delete target.editable;
        this.conStu.resultss = newData;
        this.cacheData = newData.map(item => ({ ...item }))
      }
      stuDataa(query).then(res => {
        if (!res.message) {
          this.$message.success('信息修改成功');
        }
        else {
          this.$message.error(res.message)
        }
      })
    },
    cancelOption(record) {
      const newData = [...this.conStu.resultss];
      const target = newData.filter(item => record.id === item.id)[0];
      if (target) {
        Object.assign(target, this.cacheData.filter(item => record.id === item.id)[0])
        delete target.editable
        this.conStu.resultss = newData
      }
    },
    //查看上车验证码
    seeCode(record) {
      this.charge.stageMarks = record.stageMark;
      this.seeCodeVisible = true;
    },
    seeCodeSearch(e) {
      let params = {
        stageMark: this.charge.stageMarks,
        unitId: sessionStorage.getItem('unitId'),
        userId: sessionStorage.getItem('userId'),
        psw: e
      }
      postValidUser(params).then(res => {
        if (res.data.enterCode.length) {
          this.charge.codeVal = res.data.enterCode;
        }
        else {
          this.charge.codeVal = "";
          this.$message.error('密码验证失败！！！！')
        }
      })
    },
    conDel(record) {
      let params = {
        name: record.stuName,
        phone: record.phone,
        cId: this.billingDetails.stuCid
      }
      stuDel(params).then(res => {
        if (!res.message) {
          this.$message.success("学员数据删除成功");
          this.modifyStuData({ cId: this.billingDetails.stuCid, status: 0 })
        }
        else {
          this.$message.error(res.message);
        }
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 使数据显示在表单上
    modifyData(record) {
      this.modifyTrain = true;
      this.$nextTick(() => {
        this.basicForm.setFieldsValue({
          stageMark: record.stageMark,
          cType: this.$enums.costType[record.cType],
          veh: record.veh,
          stage: record.stage
        });
      })
      this.billingDetails.list.query.cId = record.cId;
    },
    //发送验证码
    sendVerificationCode(record) {
      let params = {
        stageMark: record.stageMark,
        unitId: sessionStorage.getItem('unitId'),
        costTime: record.costTime,
        enterCode: record.enterCode,
        orderDate: record.orderDate,
        phone: record.phone,
        stage: record.stage,
        turnCnt: record.turnCnt,
        vehNo: record.vehNo,
        vehType: record.vehType
      }
      sendCode(params).then(res => {
        if (!res.message) {
          this.$message.success("验证码发送成功");
        }
        else {
          this.$message.error(res.message);
        }
      }).catch((error) => {
        console.log((error));
      });
    },
    // 打印发票
    dyData(arr) {
      let params = {
        cId: arr[0].cId
      };
      this.charge.printArr = arr;
      if (arr[0].cType == 2 || (arr[0].cType == 0 && arr[0].localTags == 1)) {
        modifyStu(params).then(res => {
          delete res.data[0].phone;
          this.charge.printArr[0] = { ... this.charge.printArr[0], ...res.data[0] }
          this.$refs.print.getprintList(arr);
          this.$nextTick(() => {
            setTimeout(() => {
              this.$print(this.$refs.print);
            }, 1000)
          })
        })
      } else {
        this.$refs.print.getprintList(arr);
        this.$nextTick(() => {
          setTimeout(() => {
            this.$print(this.$refs.print);
          }, 1000)
        })
      }
    },
    modifyStuData(record) {
      let params = {
        cId: record.cId
      };
      this.billingDetails.stuCid = record.cId;
      modifyStu(params).then(res => {
        this.conStu.resultss = res.data;
        this.conStu.resultss = this.conStu.resultss.map((item, index) => {
          return { ...item, ...{ id: index } }
        });
        this.cacheData = this.conStu.resultss.map(item => ({ ...item }))
      })
      if (record.status !== 0) {
        this.seeStudentInformation = true;
      } else {
        this.modifyStuDataVisible = true;
      }
    },
    stuData() {
      this.modifyStuDataVisible = false;
      this.handleFormFieldsReset(this.stuForm);
    },
    // 表单确定
    confirmRevision() {
      this.basicForm.validateFields((err, values) => {
        if (!err) {
          const query = this.billingDetails.list.query;
          let params = {
            applyNote: values.applyNote,
            applyUserId: sessionStorage.getItem('userId'),
            applyNoteType: values.applyNoteType,
            unitId: query.unitId,
            cId: query.cId
          }
          apply(params).then(res => {
            if (res.data.rowCount) {
              this.resetInfo();
              this.$message.success('申请成功!');
              this.queryBilling();
            } else {
              this.$message.error(res.message);
              this.resetInfo();
            }
          }).catch(err => {
            console.log('err', err)
          })
        }
      });
    },
    // 取消
    cancel() {
      this.handleFormFieldsReset(this.basicForm);
    },
    // 业务编号查询
    onSearch(value) {
      this.billingDetails.list.query.stageMark = value.target.value ? value.target.value : 0;
    },
    onSearchh(e) {
      this.billingDetails.list.query.vehNo = e.target.value ? e.target.value : 0;
    },
    getnote(e) {
      const val = this.$enums.abnormalApplication[e.target.value];
      if (val === "订单错误") {
        this.$message.info("您选择的申请类型为订单错误,可能导致该订单被注销");
      }
      this.basicForm.setFieldsValue({
        applyNote: val,
      });
    },
    // 重置信息
    resetInfo() {
      this.handleFormFieldsReset(this.basicForm);
      this.modifyTrain = false;
      this.remnant = 32;
    },
    // 表格行颜色样式
    trColor(record, index) {
      // { 0: "正常", 1: "结算",2:'异常', 3:'注销',4:'退费'}
      let className = 'light-row';
      if (record.status === 0) { className = 'light-row' }
      else if (record.status === 1) { className = 'end-row' }
      else if (record.status === 2) { className = 'yel-row' }
      else if (record.status === 3) { className = 'dark-row' }
      else if (record.status === 4) { className = 'tui-row' }
      return className;
    },
    descInput() {
      var txtVal = this.basicForm.getFieldValue('applyNote').length;
      this.remnant = 30 - txtVal;
    },
    resetExport() {
      this.selName = [];
      this.excelData = [];
    },
    // 查询用户列表
    querySaferManag() {
      let query = { nickName: '', phone: '', unitId: sessionStorage.getItem('unitId'), pageIndex: 1, pageSize: 1000 };
      this.userArr = [];
      getList(query).then(res => {
        this.userArr.push({ nickName: '全部', userId: ' ' }, { nickName: '微信公众号', userId: 1 })
        this.userArr = [...this.userArr, ...res.data];
      });
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      const stageMark = "stageMark";
      // key 序号
      //selectedInfo 选择的行
      this.billingDetails.radResult = [...selectedRowKeys];
      selectedRows.map(item => {
        this.billingDetails.selectedInfo.push(item);
      });
      const r = this.billingDetails.selectedInfo.reduce((all, next) => all.some((atom) => atom[stageMark] == next[stageMark]) ? all : [...all, next], []);
      this.billingDetails.selectedInfo = r.filter(item => {
        return this.billingDetails.radResult.some((e) => {
          return item.stageMark === e;
        });
      });
    },
    allPrint() {
      if (!this.billingDetails.selectedInfo.length) {
        this.$message.info('请先选择打印项');
        return;
      }
      this.dyData(this.billingDetails.selectedInfo);
      this.billingDetails.selectedInfo = [];
      this.billingDetails.radResult = [];

    },
    // 查看详情
    viewDetail(record) {
      let querys = this.trainingDetails.list.query;
      querys.stageMark = record.stageMark;
      this.stageMarkDeatil.data = record;
      getTrainList(querys).then(res => {
        if (res.data.length) {
          this.stageMarkDeatil.data.trainBegin = res.data[0].trainBegin ? this.$moment(this.translateDate(res.data[0].trainBegin)).format('YYYY-MM-DD HH:mm:ss') : '';
          this.stageMarkDeatil.data.trainEnd = res.data[0].trainEnd ? this.$moment(this.translateDate(res.data[0].trainEnd)).format('YYYY-MM-DD HH:mm:ss') : '';
          this.stageMarkDeatil.data.costTime = res.data[0].costTime ? res.data[0].costTime : '';

          if (res.data[0].trainBegin) {
            if (!res.data[0].trainEnd) {
              this.stageMarkDeatil.data.trainEnd = this.$moment(this.translateDate(res.data[0].trainBegin)).add(res.data[0].costTime, 'minutes').format('YYYY-MM-DD HH:mm:ss')
            }
          }
          this.stageMarkDeatil.data.imei = res.data[0].imei ? res.data[0].imei : '';
          this.stageMarkDeatil.data.newImei = res.data[0].newImei ? res.data[0].newImei : '';
        }
        getTrainPhoto({ cId: record.cId, unitId: sessionStorage.getItem('unitId') }).then(res => {
          this.stageMarkDeatil.trainPhotos = res.data;
          this.stageMarkDeatil.checkPhotos = res.data;
          modifyStu({ cId: record.cId }).then(res => {
            this.stageMarkDeatil.stuMsg = res.data[0];
            this.stageMarkDeatil.visible = true;
          })
        })
      })

    },
    // 图片放大查看
    viewImg(img) {
      this.stageMarkDeatil.previewImage = img;
      this.stageMarkDeatil.previewVisible = true;
    },
    // 关闭查询验证码model
    handleCancel() {
      this.seeCodeVisible = false;
      this.charge.codeVal = ''
    },
    // 转换时间
    formatDateTime(val) {
      let date = val.split('T');
      let dateMonth = date[0];
      let min = date[1].substr(0, date[1].indexOf('.'));
      return dateMonth + ' ' + min;
    },
    tabChange(val) {
      this.stageMarkDeatil.tabindex = val;
    },
    closeModel() {
      this.stageMarkDeatil.visible = false;
      this.stageMarkDeatil.tabindex = '1';
      this.stageMarkDeatil.isSearch = false;
      this.vueAmaps.markers = [];
      this.stageMarkDeatil.trainPhotos = []
    },
    // 业务拆分
    BuSplit(record) {
      this.BuSplitParams.results = record;
      this.BuSplitParams.model.visible = true;

    },
    // 确认业务拆分
    BuSplitOpt() {
      let splitTime = [];
      let params = {};
      this.BuSplitParams.addStage.map(item => {
        if (item.startT !== 0) {
          splitTime.push(item.startT)
        }
      })
      params = { cId: this.BuSplitParams.results.cId, stageMark: this.BuSplitParams.results.stageMark, time: splitTime }
    },
    splitAdd(record) {
      this.BuSplitParams.addStage.push({ cId: record.cId, stageMark: record.stageMark, costTime: record.costTime, maxMin: record.costTime, id: new Date(), startT: 0 })
    },
    // 获取拆分时间
    getTime() {
      this.BuSplitParams.addStage.map((item) => {
        item.maxMin = this.max(item);
      });
      // this.BuSplitParams.addStage=this.BuSplitParams.addStage.map((item) =>{
      //    if(item.id === idx){
      //      total=total+value;
      //    }else{
      //     total=total+item.splitTime;
      //    }
      //    if(total<=item.costTime){
      //      if(item.id === idx){
      //       item.splitTime=value;
      //      }
      //    }
      //    return item;
      //  });
    },
    max(record) {
      const arr = [];
      let num = 0;
      this.BuSplitParams.addStage.map((item) => {
        if (item.id !== record.id) {
          arr.push({ ...item });
        }
      });
      arr.map((item) => {
        num += Number(item.startT);
      });
      return record.costTime - num;
    },
    numberMax(item) {
      if (item.maxMin || item.maxMin === 0) {
        return item.maxMin >= 0 ? item.maxMin : 0;
      }
      return item.costTime;
    },
    // 移除记录
    removeRerord(idx) {
      this.BuSplitParams.addStage.splice(idx, 1)

    },
    async queryData() {
      await this.queryBilling();
      await this.isFav();
      await this.resetserach();
    },
   async resetserach() {
      this.setQueryByAdvanced(this.advancequry);
    },
  },
  watch: {},
  created() {
    // 当前单位值为1和4时，增加学员信息展示
    if(sessionStorage.getItem('unitId')==='1' || sessionStorage.getItem('unitId')==='4') {
      const stuColumn = [{
        title: "学员姓名",
        // 虚拟
        dataIndex: "stuName",
        customRender: (item) => {
          return `${item}`
        }
      },{
        title: "学员电话",
        // 虚拟
        dataIndex: "stuPhone",
        customRender: (item) => {
          return `${item}`
        }
      },{
        title: "学员身份证",
        // 虚拟
        dataIndex: "stuCertId",
        customRender: (item) => {
          return `${item}`
        }
      }]
      this.columns.splice(14,0,...stuColumn)
    }
  },
  mounted() {
    this.advancequry = { ...this.billingDetails.list.query };
    this.queryData();
  },
  activated() {
    // this.queryData();
  }
};