import {
  getVipCount,
  getVipList,
  addVip,
  modifyVip,
  delVip,
  startVip,
  pauseVip,
  installReferee,
  removeReferee
} from "@/service/resManagement/index";
// 获取安全员列表
import {
  getTeaList,
} from "@/service/resManagement/index";
import {
  openDownLoad
} from "@/utils/index";
export default {
  //新增订单
  name: "vipMag",
  data() {
    return {
      //编辑页面数据
      vipMag: {
        list: {
          query: {
            pageIndex: 1,
            pageSize: 10,
            name: '',
            phone: '',
            oncePhone: '',
            status: '',
            unitId:sessionStorage.getItem('unitId'),
            vipType: 1,
            userId: sessionStorage.getItem('userId'),
            valid:1
          },
          total: 0,
          results: []
        },
        modifyTrain: false,
        modalTitle: '',
        optionItem: '',
        driverList:[],
        img:{
          visible:false,
          url:'',
        },
      },
      Referee:{
        results:[],
        re2Data:[],
        re3Data:[],
      },
      dataSource: [],
      basicForm: this.$form.createForm(this),
      userId:sessionStorage.getItem('userId'),
      //表头
      columns: [
        {
          width: 75,
          title: "序号",
          align: "center",
          scopedSlots: {
            customRender: "SerialNumber"
          }
        }, {
          title: "姓名",
          dataIndex: "name",
        },
        {
          title: "身份证号",
          dataIndex: "certId",
        },
        {
          title: "手机号",
          dataIndex: "phone",
        },
        {
          title: "驾校",
          dataIndex: "shortId",
          customRender:(item)=>{
            return this.vipMag.driverList.map(index=>{
              if(index.shortId===item){
                return index.shortName
              }

            })
          }
        },
        {
              title: "车型",
              dataIndex: "vehType"
          },
        {
          title: "录入来源",
          dataIndex: "userId",
        },
        {
          title: "上传图片",
          dataIndex: "validPic",
          scopedSlots: {
            customRender: "validPic"
          }
        },
        {
          title: "操作",
          scopedSlots: {
            customRender: "operation"
          }
        }
      ],
      excelName: ['vip管理'],
      selName:[],
      excelData:[],
    };
  },
  methods: {
    // 查询列表
    queryvipManage(queryBy) {
      let querys = this.vipMag.list.query;
      //判断是否为点击页码查询
      if (queryBy !== "pagination") {
        querys.pageIndex = 1;
      }
      getVipCount(querys).then((res) => {
        this.vipMag.list.total = res.data.rowCount;
        if (res.data.rowCount) {
          getVipList(querys).then((res) => {
            this.vipMag.list.results = res.data;
            this.getTeaList();
          });
        } else {
          this.vipMag.list.results = [];
          this.vipMag.list.total = 0;
          this.getTeaList();
        }
      });
    },
    getDate(){
      this.resetExport();
      let querys = this.vipMag.list.query;
      let serch ={...querys};
      serch.pageIndex = 1;
      serch.pageSize = this.vipMag.list.total + 100;
       getVipList(serch).then(res =>{
        this.selName = [`VIP类型：${this.$rformat.methods.r_vipType(this.vipMag.list.query.vipType)}`, `车号: ${this.vipMag.list.query.phone}-${this.vipMag.list.query.name}`, " ", " ", " ", " "," "," "];
        let excelsource = this.comexport(this.columns, res.data);
        excelsource = this.exportDate(excelsource);
        this.excelData = [... this.excelData, ...excelsource];
        this.$refs.export.getExport(this.selName,this.excelName,this.excelData);
      })

    },
    // 修改获取数据
    modifyData(record) {
      this.vipMag.modifyTrain = true;
      if (record) {
        this.vipMag.modalTitle = '修改VIP数据';
        this.vipMag.optionItem = 'modifyData';
        this.vipMag.list.query.oncePhone = record.phone;
        let referee2=this.Referee.results.filter(item=>{
          if(item.phone===record.referee2){
            return item;
          }
        })
        let referee3=this.Referee.results.filter(item=>{
          if(item.phone===record.referee3){
            return item;
          }
        })
        this.$nextTick(() => {
          this.basicForm.setFieldsValue({
            name: record.name,
            certId: record.certId,
            phone: record.phone,
            shortId: record.shortId,
            status: record.status,
            vehType:record.vehType,
            referee2:referee2.length?`${referee2[0].name}/${referee2[0].phone}`:'',
            referee3:referee3.length?`${referee3[0].name}/${referee3[0].phone}`:'',
          });
        })
      } else {
        this.vipMag.modalTitle = 'VIP数据维护';
        this.vipMag.optionItem = 'addData';
      }
    },
    // model提交按钮
    confirmRevision() {
      this.basicForm.validateFields((err, values) => {
        if (!err) {
          // 暂时写死
            values.userId =sessionStorage.getItem('userId'),
            values.valid =1,
            values.unitId = sessionStorage.getItem('unitId');
            values.vipType =1,
            values.referee2 =values.referee2?values.referee2.substr(values.referee2.indexOf('/')+1,):'';
            values.referee3 =values.referee3?values.referee3.substr(values.referee3.indexOf('/')+1,):'';
            this.saveDate(values);
        }
      });
    },
    // 保存数据
    saveDate(values) {
      if (this.vipMag.optionItem === 'addData') {
        // 状态0代表正常
        values.status = 0;
        addVip(values).then((res) => {
          if (res.message.length) {
            this.$message.error(res.message)
          } else {
            this.resetInfo();
            this.$message.success('新增成功');
          }
        })
      } else {
        values.newPhone = values.phone;
        values.phone = this.vipMag.list.query.oncePhone;
        modifyVip(values).then((res) => {
          if (res.message.length) {
            this.$message.error(res.message)
          } else {
            this.resetInfo();
            this.$message.success('修改成功');
          }
        })
      }
    },
    // 查看图片
    download(link) {
      openDownLoad(link);
    },
    // 注销
    delVip(record) {
      const { phone, unitId } = record;
      let params = { phone, unitId }
      delVip(params).then((res) => {
        if (res.message.length) {
          this.$message.error(res.message)
        } else {
          this.queryvipManage();
          this.$message.success('注销成功');
        }
      })
    },
    // 启用
    startVip(record) {
      const {
        phone,
        unitId
      } = record;
      let params = {
        phone,
        unitId
      }
      startVip(params).then((res) => {
        if (res.message.length) {
          this.$message.error(res.message)
        } else {
          this.queryvipManage();
          this.$message.success('启用成功');
        }
      })
    },
    // 暂停
    pauseVip(record) {
      const {
        phone,
        unitId
      } = record;
      let params = {
        phone,
        unitId
      }
      pauseVip(params).then((res) => {
        if (res.message.length) {
          this.$message.error(res.message)
        } else {
          this.queryvipManage();
          this.$message.success('暂停成功');
        }
      })
    },
    // 重置信息
    resetInfo() {
      this.queryvipManage();
      this.vipMag.modifyTrain = false;
      this.handleFormFieldsReset(this.basicForm);
    },
    // 姓名手机号查询
    onSearch(params) {
      // console.log('params',params.target.value);
      let querys = this.vipMag.list.query;
      querys.phone = '';
      querys.name = '';
      if (this.checkNumber(params.target.value)) {
        querys.phone = params.target.value;
      } else {
        querys.name = params.target.value;
      }
      // this.queryvipManage();
    },
    //验证字符串是否是数字
    checkNumber(theObj) {
      theObj=theObj.trim();
      var reg = /^[0-9]+.?[0-9]*$/;
      if (reg.test(theObj)) {
        return true;
      }
      return false;
    },
    // 科目2
    handleChange(value,reType){
      let arr=this.Referee.results.filter(item=>{
        if(item.name.indexOf(value)!= -1){
          return item;
        }
      })
      if(reType==='referee2')
      {
        this.Referee.re2Data =arr.map(item=>{
          return `${item.name}/${item.phone}`
        })
      }else{
        this.Referee.re3Data =arr.map(item=>{
          return `${item.name}/${item.phone}`
        })
      }
    },
    // 获取安全员列表
    getTeaList(){
      const params={pageIndex:1,pageSize:10000,unitId:sessionStorage.getItem('unitId'),name:'',phone:'',starId:'-1',vehType:''}
      getTeaList(params).then(res=>{
        this.Referee.results=res.data;
        this.handleChange('','referee2');
        this.handleChange('','referee3');
      })
    },
     // 查看图片
     viewImg(url){
      this.vipMag.img.url=url;
      this.vipMag.img.visible=true;
    },
    resetExport(){
      this.selName=[];
      this.excelData=[];
    },
  },
  created() {
    this.queryvipManage();
  },
  activated() {
    this.vipMag.driverList=JSON.parse(sessionStorage.getItem('driverSchool'));
     this.isFav();
  },
  mounted() {
     this.vipMag.driverList=JSON.parse(sessionStorage.getItem('driverSchool'));
     this.isFav();
  }
};