export default {
    name: "CouponTraining",
    data() {
        return {
            CouponTraining: {
                list: {
                    query: {
                        projectItem: undefined,
                        unitId:sessionStorage.getItem('unitId'),
                        userId: sessionStorage.getItem('userId')
                    },
                    total: 0,
                    results:[{
                        play:'塘汛',
                        busNum:'350005616',
                        school:'久安',
                        safer:'陈刚强',
                        carNumber:'1号',
                        gmtStart:'10：00',
                        gmtEnd:'11：00',
                        coupon:'320',
                        pos:'0',
                        status:'已完成',
                        maker:'张琳',
                        couponNum:'3994',
                        remark:'xx',
                    }],
                },
                daterange: [],
                columns: [
                    {
                        width: 75,
                        title: "序号",
                        align: "center",
                        scopedSlots: {
                            customRender: "SerialNumber"
                        },
                    },
                    {
                        title: "训练场地",
                        dataIndex: "play",
                        width: 150,
                    },
                    {
                        title: "业务编号",
                        dataIndex: "stageMark",
                        width: 150,
                    },
                    {
                        title: "驾校",
                        dataIndex: "school",
                        width: 150,
                    },
                    {
                        title: "安全员",
                        dataIndex: "coachName",
                        width: 150,
                    },
                    {
                        title: "车号",
                        dataIndex: "carNumber",
                        width: 150,
                    },
                    {
                        title: "开始时间",
                        dataIndex: "gmtStart",
                        width: 150,
                    },
                    {
                        title: "结束时间",
                        dataIndex: "gmtEnd",
                        width: 150,
                    },{
                        title: "体验卷",
                        dataIndex: "coupon",
                        width: 150,
                    },{
                        title: "pos",
                        dataIndex: "pos",
                        width: 150,
                    },
                    {
                        title: "状态",
                        dataIndex: "status",
                        width: 150,
                    },
                    {
                        title: "制票人",
                        dataIndex: "maker",
                        width: 150,
                    },
                    {
                        title: "体验劵编号",
                        dataIndex: "couponNum",
                        width: 150,
                    },
                    {
                        title: "备注",
                        dataIndex: "remark",
                        width: 150,
                    }
                ]
            },
            excelName: ['优惠劵训练日报'],
            selName:[],
            excelData:[],
            userId:sessionStorage.getItem('userId')
        }
    },
    methods: {
        queryCoupon() {
            let querys = this.CouponTraining.list.query;
        }
    },
    created() {
     
    },
    mounted() {
        // if(!this.$route.meta.keepAlive){
        //     this.queryCoupon();
        //     this.isFav();
        //   }
        this.queryCoupon();
        this.isFav();
    },
    activated(){
         this.isFav();
        //  this.queryCoupon();
    }
};