import {teaCommision} from "@/service/businessData/index";
export default {
    name: "security",
    data() {
        return {
            security: {
                list: {
                    query: {
                        begin:this.$moment().format('YYYYMMDD'),
                        end: this.$moment().format('YYYYMMDD'),
                        unitId:sessionStorage.getItem('unitId'),
                        userId: sessionStorage.getItem('userId')
                    },
                    total: 0,
                    results:[],
                },
                // this.$moment().startOf('month')
                daterange: [this.$moment(),this.$moment()],
                columns: [{
                        width: 75,
                        title: "序号",
                        scopedSlots: {
                            customRender: "SerialNumber"
                        },
                    },
                    {
                        title: "姓名",
                        dataIndex: "teaName",
                        width: 150,
                    },
                    {
                        title: "科目二小车训练小时",
                        dataIndex: "smallVehStage2",
                        width: 150,
                        customRender: (item) => {
                            return item / 60
                        }
                    },
                    {
                        title: "科目二大车训练小时",
                        dataIndex: "bigVehStage2",
                        width: 150,
                        customRender: (item) => {
                            return item / 60
                        }
                    },
                    {
                        title: "科目三小车训练小时",
                        dataIndex: "smallVehStage3",
                        width: 150,
                        customRender: (item) => {
                            return item / 60
                        }
                    },
                    {
                        title: "科目三大车训练小时",
                        dataIndex: "bigVehStage3",
                        width: 150,
                        customRender: (item) => {
                            return item / 60
                        }
                    },
                    {
                        title: "提成金额",
                        dataIndex: "teaCommision",
                        width: 150,
                        customRender: (item) => {
                            return item / 100
                        }

                    }
                ]
            },
            tableH:0,
            excelName: ['安全员提成'],
            selName:[],
            excelData:[],
            userId:sessionStorage.getItem('userId')
        }
    },
    methods: {
        querysafer() {
            let querys = this.security.list.query;
            teaCommision(querys).then(res=>{
                this.security.list.results=[...res.data];
                this.resetExport();
                this.selName = [`查询日期: ${this.security.list.query.begin}-${this.security.list.query.end}`," "," "," "," "," "," "];
                let excelsource= this.comexport(this.security.columns,this.security.list.results)
                this.excelData=[... this.excelData,...excelsource];
                this.excelData.push([`结算日期：${this.$moment(this.security.daterange[1]).format('YYYY-MM-DD')}`," "," "," "," "," "," "," "," "]);
                this.security.list.results=this.security.list.results.map((item,index)=>{
                    return {...item,...{id:index}}
                })
            })
        },
        getDate(){
            this.$nextTick(()=>{
                this.$refs.export.getExport(this.selName,this.excelName,this.excelData);
            })
        },
        getDateStr(e){
            this.security.list.query.begin = this.$moment(e[0]).format('YYYYMMDD');
            this.security.list.query.end = this.$moment(e[1]).format('YYYYMMDD');
            this.security.daterange = e;
        },
        resetExport(){
            this.selName=[];
            this.excelData=[];
        }
    },
    created() {
     
    },
    mounted() {
          this.tableH = (document.documentElement.clientHeight || document.body.clientHeight) - 300;
          this.isFav();
          this.querysafer();
    },
    activated(){
        this.tableH = (document.documentElement.clientHeight || document.body.clientHeight) - 300;
         this.isFav();
    }
};