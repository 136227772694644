import {
    postSetJoinOrder
} from "@/service/appointment/index";
import {
    getvehList,
    getvehCount,
} from "@/service/resManagement/index";
import {
    getStarList,
} from "@/service/costSet/index";
export default {
    //新增订单
    name: "reservationVehicles",
    data() {
        return {
            reservationVehicles: {
                list:{
                    query:{
                        pageIndex: 1,
                        pageSize: 10,
                        addJson:[],
                        cancelJson:[],
                        unitId:sessionStorage.getItem('unitId'),

                        veh: '',
                        vehType: '',
                        starId:-1,
                        stage: '',
                        vehNo: '',

                        status: '',
                        vehId: '',
                        tea:'',
                    },
                    results:[],
                    total:0,
                },
                selectedInfo: [],
                selectedKeys: [],
            },
            // 星级费用列表
            starFee: {
                list: [],
                serchList: [],
            },
            //表头
            columns: [{
                    title: "车号",
                    dataIndex: "vehNo",
                },{
                    title: "车牌号",
                    dataIndex: "veh",
                },
                {
                    title: "科目",
                    dataIndex: "stage",
                },
                {
                    title: "车型",
                    dataIndex: "vehType",
                },
                {
                    title: "预约状态",
                    dataIndex: "joinOrder",
                    customRender: (item) => {
                        return item?"已预约":'未预约';
                    }
                },
                {
                    title: "车辆状态",
                    dataIndex: "status",
                    customRender: (item) => {
                        return this.$enums.examinationCarStatus[item]
                    }
                },
                {
                    title: "星级",
                    dataIndex: "starId",
                    customRender: (item) => {
                        return item?this.$enums.starClass[item]:'无';
                    }
                },
                {
                    title: "安全员",
                    dataIndex: "name",
                    customRender: (item) => {
                        return item?item:'无';
                    }
                }],
        };
    },
    methods: {
        //车辆列表查询
        // 查询列表
        queryTestCar(queryBy) {
            let querys = this.reservationVehicles.list.query;
            //判断是否为点击页码查询
            if (queryBy !== "pagination") {
                querys.pageIndex = 1;
            }
            getvehCount(querys).then((res) => {
                this.reservationVehicles.list.total =res.data.rowCount;
                if (res.data.rowCount) {
                    getvehList(querys).then((res) => {
                            this.reservationVehicles.list.results = res.data;
                    }).catch((err) => {
                        console.log('err', err)
                    })
                } else {
                    this.reservationVehicles.list.results = [];
                    this.reservationVehicles.list.total = 0;
                }
            }).catch((err) => {})
        },
        //取消预约
        offClick(){
            let cancelJson=this.getObjectValues(this.reservationVehicles.selectedInfo,"vehId");
            if(!cancelJson.length){
                this.$message.info('请选择取消预约项!');
                return;
            }
            let params = {unitId:sessionStorage.getItem('unitId'),cancelJson:cancelJson,addJson:[]}
            postSetJoinOrder(params).then(res=>{
                 if(res.data.length){
                     this.$message.error("请选择要操作的数据")
                 }
                 else {
                     this.$message.success("取消成功");
                     this.queryTestCar();
                     this.reservationVehicles.selectedKeys=[];
                     this.reservationVehicles.selectedInfo=[];
                 }
            })
        },
        //打开预约
        onClick(){
            let addJson=this.getObjectValues(this.reservationVehicles.selectedInfo,"vehId");
            if(!addJson.length){
                this.$message.info('请选择预约项!');
                return;
            }
            let params = {unitId:sessionStorage.getItem('unitId'),addJson:addJson,cancelJson:[]}
            postSetJoinOrder(params).then(res=>{
                if(res.data.length){
                    this.$message.error("请选择要操作的数据")
                }
                else {
                    this.$message.success("预约成功");
                    this.queryTestCar();
                    this.reservationVehicles.selectedKeys=[];
                    this.reservationVehicles.selectedInfo=[];

                }
            })
        },
        // // 判断是否选中
        // validVip(record, isPass) {
        //     this.isValid(record, isPass, false);
        // },
        // // 全部审核
        // isAllPass(isPass) {
        //     if (this.reservationVehicles.selectedInfo.length) {
        //         for (let [index, item] of new Map(this.reservationVehicles.selectedInfo.map((item, index) => [index, item]))) {
        //             this.isValid(item, isPass, true, index)
        //         }
        //     } else {
        //         this.$message.info("请先选择需要通过的数据");
        //     }
        // },
        getObjectValues(arr,key)
        {
            var values = [];
            for (var property in arr){
                values.push(arr[property][key]);
            }
            return values;
        },
        onSelectChange(selectedRowKeys, selectedRows) {
            const vehId = "vehId";
            this.reservationVehicles.selectedKeys = [...selectedRowKeys];
            selectedRows.map(item => {
                this.reservationVehicles.selectedInfo.push(item);
            });
            const r= this.reservationVehicles.selectedInfo.reduce((all, next) => all.some((atom) => atom[vehId] == next[vehId]) ? all : [...all, next], []);
            this.reservationVehicles.selectedInfo= r.filter(item=>{
                return  this.reservationVehicles.selectedKeys.some((e)=>{
                    return item.vehId === e;
                });
            });
        }

    },
    mounted() {
        this.queryTestCar();
        this.starFee.list=JSON.parse(sessionStorage.getItem('starCost'));
        let arr=this.starFee.list.filter(item=>{return item.starType===1});
        let arrObj=[{'starName':'全部','starId':-1}];
        let arrObj2=[{'starName':'无','starId':0}]
        this.starFee.serchList=[...arr,...arrObj];
        this.setQueryByAdvanced(this.reservationVehicles.list.query);
        this.isFav();
    },
    activated() {
        this.starFee.list=JSON.parse(sessionStorage.getItem('starCost'));
        let arr=this.starFee.list.filter(item=>{return item.starType===1});
        // console.log('arr',arr)
        let arrObj=[{'starName':'全部','starId':-1}];
        let arrObj2=[{'starName':'无','starId':0}]
        this.starFee.serchList=[...arr,...arrObj];
        this.isFav();
    },
    computed: {
        rowSelection() {
            const { selectedRowKeys } = this;
            return {
                onChange: (selectedRowKeys, selectedRows) => {
                    const vehId = "vehId";
                    this.reservationVehicles.selectedKeys = [...selectedRowKeys];
                    selectedRows.map(item => {
                        this.reservationVehicles.selectedInfo.push(item);
                    });
                    const r= this.reservationVehicles.selectedInfo.reduce((all, next) => all.some((atom) => atom[vehId] == next[vehId]) ? all : [...all, next], []);
                    this.reservationVehicles.selectedInfo= r.filter(item=>{
                        return  this.reservationVehicles.selectedKeys.some((e)=>{
                            return item.vehId === e;
                        });
                    });
                }
            };
        }
    },
};