import {
    getLocalTrainAbnormalList2,
    getLocalTrainAbnormalListCount2,
    setLocalTrainAbnormalReaded,
    setLocalTrainAbnormalReadedByCertId,
    getPhotoByCertId
} from "@/service/charge/index";
export default {
    //新增订单
    name: "facialAbnormalities",
    data() {
        return {
            //编辑页面数据
            facialAbnormalities: {
                list: {
                    query: {
                        pageIndex: 1,
                        pageSize: 10,
                        begin: this.$moment().add(-1, "days").format('YYYYMMDD'),
                        end: this.$moment().format('YYYYMMDD'),
                        beginBycert: this.$moment().add(-1, "days").format('YYYY-MM-DD 00:00:00'),
                        endBycert: this.$moment().format('YYYY-MM-DD 23:59:59'),
                        readTag: 0,
                        unitId: sessionStorage.getItem('unitId'),
                        // 默认值未读
                        // readTag: 0,
                    },
                    total: 0,
                    results: []
                },
                daterange: [this.$moment().add(-1, "days"), this.$moment()],
                imgView: {
                    visible: false,
                    total: 0,
                    columns: [
                        {
                            width: 75,
                            title: "序号",
                            align: "center",
                            scopedSlots: {
                                customRender: "SerialNumber"
                            }
                        },
                        {
                            width: 150,
                            title: "指派车辆",
                            dataIndex: "veh",
                            align: "center",
                        }, {
                            width: 75,
                            title: "车号",
                            dataIndex: "vehNo",
                            align: "center",
                        }, {
                            width: 150,
                            title: "拍照时间",
                            align: "center",
                            dataIndex: "photoTime",
                            customRender: item => {
                                if (!item) {
                                    return '暂无';
                                }
                                return this.transForm(item);
                            }
                        }, {
                            width: 150,
                            title: "照片类型",
                            align: "center",
                            dataIndex: "photosType",
                            customRender: item => {
                                if (item === 17) {
                                    return "学员签到拍照"
                                } else if (item === 18) {
                                    return "学员签退拍照"
                                } else if (item === 19) {
                                    return "学员培训过程中拍照"
                                } else if (item === 20) {
                                    return "教练签到拍照"
                                } else if (item === 21) {
                                    return "教练签退拍照"
                                } else if (item === 22) {
                                    return "安全员签到拍照"
                                } else if (item === 23) {
                                    return "安全员签退拍照"
                                } else if (!item) {
                                    return "暂无"
                                }
                            }
                        },
                        {
                            title: "照片",
                            align: "center",
                            width: 150,
                            scopedSlots: {
                                customRender: "photosUrl"
                            }
                        },
                    ],
                    results: []
                }
            },
            // 图片信息
            img: {
                previewVisible: false,
                previewImage: '',
                personMsg:{},
                personVisble:false,
                personImage:false,
            },

            //表头
            columns: [
                {
                    width: 150,
                    title: "序号",
                    align: "center",
                    scopedSlots: {
                        customRender: "SerialNumber"
                    }
                }, {
                    width: 150,
                    title: "学员姓名",
                    dataIndex: "stuName",
                    align: "center",
                    customRender: (item) => {
                        return item ? item : '暂无'
                    }
                }, {
                    width: 150,
                    title: "照片",
                    align: "center",
                    scopedSlots: {
                        customRender: "photos"
                    }
                },{
                    width: 150,
                    title: "身份证号",
                    dataIndex: "certId",
                    align: "center",
                    customRender: (item) => {
                        return item ? item : '暂无'
                    }
                },
                {
                    width: 150,
                    title: "教练姓名",
                    dataIndex: "teaName",
                    align: "center",
                },{
                    width: 150,
                    title: "教练身份证号",
                    dataIndex: "teaCertId",
                    align: "center",
                },{
                    width: 150,
                    title: "电话",
                    dataIndex: "phone",
                    align: "center",
                }, {
                    width: 150,
                    title: "状态",
                    align: "center",
                    scopedSlots: {
                        customRender: "option"
                    }
                },
            ],
            excelName: ['本校人脸对比异常'],
            selName: [],
            excelData: [],
        };
    },
    methods: {
        queryTraining(queryBy) {
            let querys = this.facialAbnormalities.list.query;
            //判断是否为点击页码查询
            if (queryBy !== "pagination") {
                querys.pageIndex = 1;
            }
            getLocalTrainAbnormalListCount2(querys).then(res => {
                this.facialAbnormalities.list.total = res.data.cnt;
                if (res.data.cnt) {
                    getLocalTrainAbnormalList2(querys).then(res => {
                        this.$nextTick(() => {
                            this.facialAbnormalities.list.results = res.data.map((item, index) => {
                                if(item.phone){
                                    return { ...{ id: index }, ...item}
                                }else{
                                    return { ...{ id: index }, ...item,...{phone:'暂无'}}
                                }

                            });
                        });
                    });
                } else {
                    this.$nextTick(() => {
                        this.facialAbnormalities.list.results = [];
                    });
                }
            });
        },
        getDate(){
            this.resetExport();
            let querys = this.facialAbnormalities.list.query;
            let excolumns = this.columns.filter(item =>{
                return item.title!="照片"
            })
            let serch ={...querys};
            serch.pageIndex = 1;
            serch.pageSize = this.facialAbnormalities.list.total + 100;
            getLocalTrainAbnormalList2(serch).then(res =>{
                let souce= res.data.map((item, index) => {
                    if(item.phone){
                        return { ...{ id: index }, ...item}
                    }else{
                        return { ...{ id: index }, ...item,...{phone:'暂无'}}
                    }

                });
                this.selName = [`查询日期: ${this.facialAbnormalities.list.query.begin}-${this.facialAbnormalities.list.query.end}`, `是否查阅：${this.$rformat.methods.r_readTag(this.facialAbnormalities.list.query.readTag)}`];
                for (let i = 0; i < excolumns.length - 3; i++) {
                    this.selName.push(" ")
                }
                let excelsource = this.comexport(excolumns, souce);
                excelsource = this.exportDate(excelsource);
                this.excelData = [... this.excelData, ...excelsource];
                this.$refs.export.getExport(this.selName,this.excelName,this.excelData);
            })
        },
        // 时间变化
        dateChange(e) {
            this.facialAbnormalities.daterange = e;
            this.facialAbnormalities.list.query.begin = this.$moment(e[0]).format('YYYYMMDD');
            this.facialAbnormalities.list.query.end = this.$moment(e[1]).format('YYYYMMDD');
            this.facialAbnormalities.list.query.beginBycert = this.$moment(e[0]).format('YYYY-MM-DD 00:00:00');
            this.facialAbnormalities.list.query.endBycert = this.$moment(e[1]).format('YYYY-MM-DD 23:59:59');
        },
        // 选择科目
        selectStage(e) {
            this.facialAbnormalities.list.query.readTag = e;
        },
        // 查询照片
        viewPhoto(record) {
            this.img.personMsg=record;
            let params = { begin: this.facialAbnormalities.list.query.beginBycert, end: this.facialAbnormalities.list.query.endBycert, certId: record.certId }
            getPhotoByCertId(params).then(res => {
                this.facialAbnormalities.imgView.results = res.data;
                this.facialAbnormalities.imgView.results = res.data.map(item => {
                    let time = this.$moment(item.photoTime).format('YYYYMMDDHHmmss');
                    return { ...item, ...{ timer: time } }
                });
                this.facialAbnormalities.imgView.results.sort(this.compareKey());
                this.facialAbnormalities.imgView.visible = true;
            })
        },
        // 对时间排序
        compareKey() {
            return function (obj1, obj2) {
                if (obj1["timer"] < obj2["timer"]) {
                    return -1;
                } else if (obj1["timer"] === obj2["timer"]) {
                    return 0;
                } else {
                    return 1;
                }
            };
        },
        // 已处理
        option(record) {
            setLocalTrainAbnormalReadedByCertId({ begin: this.facialAbnormalities.list.query.beginBycert, end: this.facialAbnormalities.list.query.endBycert, certId: record.certId }).then(res => {
                if (!res.message) {
                    this.$message.info('数据已处理!');
                    this.queryTraining();
                }
            })
        },
        // 图片放大查看
        viewImg(img) {
            this.img.previewImage = img;
            this.img.previewVisible = true;
        },
        //头像放大
        personImag(img){
            this.img.personImage = img;
            this.img.personVisble = true;
        },
        // 导出
        resetExport() {
            this.selName = [];
            this.excelData = [];
        },
        disabledDate(current) {
            let cur=this.$moment(current).format('YYYYMMDD');
            let monthago=this.$moment().subtract(6,'months').format('YYYYMMDD');
            if(monthago <= cur){   
                return false
            }else{
                return true
            }
        },

    },
    watch: {},
    created() { },
    mounted() {
        // if(!this.$route.meta.keepAlive){
        //     this.queryTraining();
        //     this.isFav();
        //   }
        this.isFav();
        this.queryTraining();
    },
    activated() {
        this.isFav();
        // this.queryTraining();
    },
};
