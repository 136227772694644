import {
  trainByDay
} from "@/service/businessData/index";
export default {
  name: "shortTrain",
  data() {
    return {
      shortTrain: {
        list: {
          query: {
            pageSize: 10,
            pageIndex: 1,
            begin: this.$moment().format('YYYYMMDD'),
            end: this.$moment().format('YYYYMMDD'),
            localTags: -1,
            stage: 2,
            unitId: sessionStorage.getItem('unitId'),
            vehType: '',
            userId: sessionStorage.getItem('userId'),
            cType: "0"
          },
          sunTotal: [],
          total: 0,
          results: []
        },
        daterange: [this.$moment(), this.$moment()],
        screenHeight: 500,
        columns: []
      },
      // excelName: ['短训数据'],
      // selName: [],
      // excelData: [],
      userId: sessionStorage.getItem('userId'),
      excelName: ['短训数据'],
      selName: [],
      excelData: [],
      screenHeight: 0,
      cTypeClumn:[],
      initClumn: [{
        width: 75,
        title: "序号",
        align: "center",
        scopedSlots: {
          customRender: "SerialNumber"
        },
      },
      {
        title: "业务编号",
        dataIndex: "stageMark",
        width: 150,
        align: "center",
        customRender: (item) => {
          return item ? item : "暂无";
        }
      },
      // {
      //   title: "业务类型",
      //   dataIndex: "cType",
      //   width: 150,
      //   align: "center",
      //   customRender: (item) => {
      //     return this.$enums.cType[item];
      //   }
      // },
      {
        title: "优惠券编号",
        dataIndex: "coupon",
        width: 150,
        align: "center",
      },
      {
        title: "优惠券金额",
        dataIndex: "couponPrice",
        width: 150,
        align: "center",
        customRender: (item) => {
          return item ? item /100 : 0;
        }
      },
      {
        title: "科目",
        dataIndex: "stage",
        align: "center",
        width: 75,
        customRender: (item) => {
          return item ? item : "暂无";
        }
      },
      {
        title: "支付金额",
        align: "center",
        dataIndex: "cost",
        width: 150,
        customRender: (item) => {
          return item ? item / 100 : "暂无"
        }
      },
      {
        title: "车号",
        dataIndex: "vehNo",
        align: "center",
        width: 75,
        customRender: (item) => {
          return item ? item : "暂无";
        }
      },
      {
        title: "驾校名称",
        align: "center",
        dataIndex: "shortName",
        width: 75,
        customRender: (item) => {
          return item ? item : "暂无";
        }
      },
      {
        title: "推荐人",
        align: "center",
        dataIndex: "referee",
        width: 75,
        customRender: (item) => {
          return item ? item : "无";
        }
      },
      {
        title: "操作员",
        dataIndex: "nickName",
        width: 75,
        align: "center",
        customRender: (item) => {
          return item ? item : "无";
        }
      },
      {
        title: "训练状态",
        dataIndex: "note",
        width: 150,
        align: "center",
        customRender: (item) => {
          return item ? item : "暂无";
        }
      },
      ]
    }
  },
  methods: {
    queryShortData() {
      let querys = this.shortTrain.list.query;
      this.shortTrain.columns = [...this.cTypeClumn];
      trainByDay(querys).then(res => {
        this.shortTrain.list.results = [...res.data];
        this.resetExport();
        this.selName = [`查询日期: ${this.shortTrain.list.query.begin}-${this.shortTrain.list.query.end}`, `归属: ${this.$rformat.methods.r_isSch(this.shortTrain.list.query.localTags)}`, `业务类型: ${this.$rformat.methods.r_cType(this.shortTrain.list.query.cType)}`, `科目: ${this.$rformat.methods.r_projectItem(this.shortTrain.list.query.stage)}`];
        for (let i = 0; i < this.shortTrain.columns.length - 4; i++) {
          this.selName.push(" ")
        }
        let excelsource = this.comexport(this.shortTrain.columns, this.shortTrain.list.results)
        this.excelData = [... this.excelData, ...excelsource];
        this.excelData.push([`${this.$rformat.methods.r_cType(this.shortTrain.list.query.cType)}结算日期：${this.$moment(this.shortTrain.daterange[1]).format('YYYY-MM-DD')}`])
        for (let i = 0; i < this.shortTrain.columns.length - 2; i++) {
          this.excelData[this.excelData.length - 1].push(" ")
        }
        this.excelData[this.excelData.length - 1].push(`小计：${this.getTotal()}`)
      })
    },
    getDate() {
      this.$nextTick(() => {
        this.$refs.export.getExport(this.selName, this.excelName, this.excelData);
      })
    },
    getTotal() {
      let total = 0;
      this.shortTrain.list.results.filter(item => {
        total = total + item.cost / 100;
      })
      return total;
    },
    resetExport() {
      this.selName = [];
      this.excelData = [];
    },
    getDateStr(e) {
      this.shortTrain.list.query.begin = this.$moment(e[0]).format('YYYYMMDD');
      this.shortTrain.list.query.end = this.$moment(e[1]).format('YYYYMMDD');
      this.shortTrain.daterange = e;
    },
    getCtype(e) {
      if (e == '-1') {
        this.cTypeClumn= this.initClumn.filter(e => {
          return e.dataIndex != "coupon" && e.dataIndex != "couponPrice" && e.dataIndex != "cType"
        })
      } else if (e == '2') {
        this.cTypeClumn= [... this.initClumn]
      } else {
        this.cTypeClumn= this.initClumn.filter(e => {
          return e.dataIndex != "coupon" && e.dataIndex != "couponPrice"
        })
      }
    }
  },
  created() {

  },
  mounted() {
    this.shortTrain.columns = this.initClumn.filter(e => {
      return e.dataIndex != "coupon" && e.dataIndex != "couponPrice"
    })
    this.cTypeClumn = this.initClumn.filter(e => {
      return e.dataIndex != "coupon" && e.dataIndex != "couponPrice"
    })
    this.isFav();
    this.queryShortData();
    this.screenHeight = document.documentElement.scrollHeight - 300;
  },
  activated() {
    this.isFav();
    this.screenHeight = document.documentElement.scrollHeight - 300;
    //  this.queryShortData();
  }
};
