import {
  getListByEId,
  getListByEIdCount,
  refundAudit
} from "@/service/charge/index";
export default {
  //新增订单
  name: "refundDetails",
  data() {
    return {
      daterange: [],
      refundDetails: {
        list: {
          query: {
            pageIndex: 1,
            pageSize: 10,
            begin: this.$moment().add(-1, 'month').format('YYYYMMDD'),
            end: this.$moment().add(1, 'day').format('YYYYMMDD'),
            unitId: sessionStorage.getItem('unitId'),
            status: 0,
            stageMark: 0,
            eId:''
          },
          total: 0,
          results: [],
        },
      },
      auduitParams:{
        auditUserId:0,
        eid:0,
      },
      columns: [{
        width: 75,
        title: "序号",
        align: "center",
        scopedSlots: {
          customRender: "SerialNumber"
        }
      }, {
        title: "业务编号",
        dataIndex: "stageMark",
        scopedSlots: {
          customRender: "stageMark"
        }
      }, {
        title: "客户名称",
        dataIndex: "client",
      }, {
        title: "客户类型",
        dataIndex: "clientType",
        customRender: (item => {
          // item == 0?"个人客户":"其他"
          return "个人客户"
        })
      }, {
        title: "联系电话",
        dataIndex: "phone",
      }, {
        title: "退费金额",
        dataIndex: "cost",
        customRender: (item) => {
          return item/100;
        }
      }, {
        title: "申请人",
        dataIndex: "applyNickName",
      }, {
        title: "申请时间",
        dataIndex: "applyDate",
        customRender: (item) => {
          return this.transForm(item);
        }
      }, {
        title: "状态",
        dataIndex: "status",
        scopedSlots: {
          customRender: "status"
        } // 0 未审 1 审核 2 驳回
      }, {
        title: "操作",
        scopedSlots: {
          customRender: "operation"
        }
      }]
    };
  },
  methods: {
    // 查询列表
    getCount(){
      let params = this.refundDetails.list.query;
      getListByEIdCount(params).then( res => {
        this.refundDetails.list.total=res.data.rowCount;
       if(res.data.rowCount){
           this.queryrefund();
       }else{
        this.refundDetails.list.results=[];
       }
      })
    },
    queryrefund() {
      let  params= this.refundDetails.list.query;
      getListByEId(params).then(res => {
        this.refundDetails.list.results = res.data;
      })
    },
    // 退费单号查询
    onSearch(e) {
      if (!e.trim()) {
        e = 0;
      }
      this.refundDetails.list.query.stageMark = e;
      this.queryrefund();
    },
    // 驳回审核
    auduit(record,state) {
      // auditUserId eId 从路径获取
      let params={auditUserId:this.auduitParams.auditUserId,eId:this.auduitParams.eId,stageMark:record.stageMark,status:state}
      refundAudit(params).then( res => {
         if(!res.message){
           this.$message.info("审核通过");
           this.getCount();
         }else{
          this.$message.info("审核操作失败,失败原因是："+res.message);
         }
      })
      // console.log("审核",record,status);
    },
    // 查看详情
    toDetail(record) {
      // console.log(record);
      this.$router.push({path: '/detail',query: {key:record.stageMark,eId:this.$route.query.eId}});
      // this.$router.push({path: '/wxHome'});
    }
  },
  watch: {},
  created() { },
  mounted() {
    this.auduitParams.auditUserId=this.$route.query.userId;
    this.auduitParams.eId=this.$route.query.eId;
    this.refundDetails.list.query.eId=this.$route.query.eId;
    this.getCount();
  },
  activated() {
    this.auduitParams.auditUserId=this.$route.query.userId;
    this.auduitParams.eId=this.$route.query.eId;
    this.refundDetails.list.query.eId=this.$route.query.eId;
  }
};