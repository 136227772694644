import {getSalesDailySumByFaxed ,getSalesDaily ,getSalesDailyTotal} from "@/service/businessData";
export default {
    name: "dailyReport",
    data() {
        return {
            orgOptions: {},
            dailyReport: {
                list: {
                    query: {
                        begin:this.$moment().format('YYYYMMDD'),
                        end:this.$moment().format('YYYYMMDD'),
                        eId: sessionStorage.getItem('eId'),
                        stage: 2,
                        pageIndex:1,
                        pageSize:10
                    },
                    total: 0,
                    results: [],
                    resultss:[],
                    
                    costSum:0,
                    faxCostSum:0,
                },
                daterange:[this.$moment(),this.$moment()],
                date: [],
                columns: [
                    {
                        title: "发票号",
                        dataIndex:"fax",
                        align:'center',
                        customRender: (item) => {
                            if(item !== '0'){
                                if(item.length == 0){
                                    return "未开票";
                                }else{
                                    return item;
                                }
                            }else{
                                return "未开票";
                            }
                           }
                    },
                    {
                        title: "购买项目",
                        dataIndex: "item",
                        align:'center',
                        customRender: (item) => {
                            return  item?item:"暂无";
                           }
                    },
                    {
                        title: "业务编号",
                        dataIndex: "stageMark",
                        align:'center',
                        customRender: (item) => {
                            return  item?item:"暂无";
                           }
                    },
                    {
                        title: "购买方式",
                        dataIndex: "cashType",
                        align:'center',
                        customRender: (item) => {
                            return  item == 1?"微信":item == 2?"支付宝":item == 3?"现金":item == 4?"转账":"信用卡";
                           }
                    },
                    {
                        title: "购买日期",
                        dataIndex: "payDate",
                        align:'center',
                    },
                    {
                        title: "车牌号",
                        dataIndex: "veh",
                        align:'center',
                    },
                    {
                        title: "含税金额",
                        dataIndex: "cost",
                        align:'center',
                        customRender: (item) => {
                            return item?item/100:" "
                        }
                    },
                    {
                        title: "税额",
                        dataIndex: "faxCost",
                        align:'center',
                        customRender: (item) => {
                            return item?item:" "
                        }
                    },
                    {
                        title: "税率",
                        dataIndex: "faxRatio",
                        align:'center',
                        customRender: (item) => {
                            return item?item:" "
                        }
                    },
                    {
                        title: "金额",
                        dataIndex: "money",
                        align:'center',
                    }, 
                    {
                        title: "发票类型",
                        dataIndex: "faxType",
                        align:'center',
                        customRender: (item) => {
                            return item == "c"?"普票":item == "t"?'电子发票':''
                        }
                    },
                    {
                        title: "开票日期",
                        dataIndex: "faxTime",
                        align:'center',
                         customRender: (item) => {
                            return item?item.substr(0,item.match('T').index):'';
                        }
                    },
                    {
                        title: "开票用户",
                        dataIndex: "nickName",
                        align:'center',
                    },
                    {
                        title: "备注",
                        dataIndex: "note",
                        align:'center',
                    }
                ]
            },
            excelName: ['销售日报'],
            selName:[],
            excelData:[],
            userId:sessionStorage.getItem('userId')
        }
    },
    methods: {
        queryShortData(queryBy) {
            let querys = this.dailyReport.list.query;
            if (queryBy !== "pagination") {
                querys.pageIndex = 1;
            }
            getSalesDailySumByFaxed(querys).then(res=>{
                this.dailyReport.list.costSum=res.data[0]?res.data[0].costSum / 100:0;
                this.dailyReport.list.faxCostSum=res.data[0]?res.data[0].faxCostSum:0;
                getSalesDailyTotal(querys).then(res => {
                    this.dailyReport.list.total = res.data[0].count;
                    getSalesDaily(querys).then(res=>{
                        this.dailyReport.list.results = [...res.data];
                        this.dailyReport.list.results = this.dailyReport.list.results.map(item => {
                            if(item.faxCost){
                                item.money = item.cost/100 - item.faxCost;
                            }else{
                                item.money = "";   
                            }
                            return item;
                        })
                    })
                })
            })
        },
        getDate(){
            this.resetExport();
            let querys = this.dailyReport.list.query;
            let serch ={...querys};
            serch.pageIndex = 1;
            serch.pageSize = this.dailyReport.list.total + 100;
            getSalesDaily(serch).then(res =>{
                let source = res.data.map(item => {
                    if(item.faxCost){
                        item.money = item.cost/100 - item.faxCost;
                    }else{
                        item.money = "";   
                    }
                    return item;
                })
                this.selName = [`查询日期: ${this.$moment(this.dailyReport.daterange[0]).format('YYYY-MM-DD')}~${this.$moment(this.dailyReport.daterange[1]).format('YYYY-MM-DD')}`,`科目: ${this.$rformat.methods.r_projectItem(this.dailyReport.list.query.stage)}`];
                for(let i=0;i<this.dailyReport.columns.length-2;i++){
                    this.selName.push(" ")   
                }
                let excelsource= this.comexport(this.dailyReport.columns,source,true)
                this.excelData=[... this.excelData,...excelsource];
                this.excelData.push([`结算日期：${this.$moment(this.dailyReport.daterange[0]).format('YYYY-MM-DD')}至${this.$moment(this.dailyReport.daterange[1]).format('YYYY-MM-DD')}`,`已开票含税金额总计: ${this.dailyReport.list.costSum}`,`已开票税额总计: ${this.dailyReport.list.faxCostSum}`," "," "," "," "," "," "," "," "," "," "," "]);
                this.$refs.export.getExport(this.selName,this.excelName,this.excelData);
            }) 
        },
        // 表格行颜色样式
        trColor(record, index){
            // { 0: "正常", 1: "结算",2:'异常', 3:'注销',}
            let className ='light-row';
            if (record.note!=null){className ='biaoji-row'}
            return className;
        },
        getDateStr(e){
           this.dailyReport.list.query.begin = this.$moment(e[0]).format('YYYYMMDD');
           this.dailyReport.list.query.end = this.$moment(e[1]).format('YYYYMMDD');
           this.dailyReport.daterange = e;
        },
        resetExport(){
            this.selName=[];
            this.excelData=[];
        },
    },
    created() {
       
    },
    mounted() {
        // if(!this.$route.meta.keepAlive){
        //     this.isFav();
        //     this.queryShortData();
        //   }
        this.isFav();
        this.queryShortData();
    },
    activated(){
         this.isFav();
        //  this.queryShortData();
      }
};