import {
  getCount,
  getRefundList,
  getStageMark,
  refundApply,
} from "@/service/charge/index";
import axios from "axios";
export default {
  //新增订单
  name: "refundDetails",
  data() {
    return {
      userId: sessionStorage.getItem('userId'),
      refundDetails: {
        list: {
          query: {
            pageIndex: 1,
            pageSize: 10,
            begin: this.$moment().add(-1, 'month').format('YYYYMMDD'),
            end: this.$moment().add(1, 'day').format('YYYYMMDD'),
            unitId: sessionStorage.getItem('unitId'),
            status: -1,
            stageMark: 0,
          },
          total: 0,
          results: [],
        },
        daterange: [this.$moment().add(-1, 'month'), this.$moment().add(1, 'day')],
      },
      basicForm: this.$form.createForm(this),
      modal: {
        addvisible: false,
        detailvisible: false,
        detailrecord: {},
        maxCost: 0,
        imgUrl: ''
      },
      imports: {
        fileList: [],
        loading: false,
        disabled: false
      },
      remnant: 128,
      excelName: ['退费详情'],
      selName: [],
      excelData: [],
      columns: [{
        width: 75,
        title: "序号",
        align: "center",
        scopedSlots: {
          customRender: "SerialNumber"
        }
      }, {
        title: "业务编号",
        dataIndex: "stageMark",
        // scopedSlots: {
        //   customRender: "stageMark"
        // }
      }, {
        title: "客户名称",
        dataIndex: "client",
      }, {
        title: "客户类型",
        dataIndex: "clientType",
        customRender: (item => {
          // item == 0?"个人客户":"其他"
          return "个人客户"
        })
      }, {
        title: "联系电话",
        dataIndex: "phone",
      }, {
        title: "退费金额",
        dataIndex: "cost",
        customRender: (item) => {
          return item / 100;
        }
      }, {
        title: "申请人",
        dataIndex: "applyNickName",
      }, {
        title: "申请时间",
        dataIndex: "applyDate",
        customRender: (item) => {
          return this.transForm(item);
        }
      }, {
        title: "状态",
        dataIndex: "status",
        scopedSlots: {
          customRender: "status"
        } // 0 未审 1 审核 2 驳回
      }, {
        title: "操作",
        scopedSlots: {
          customRender: "operation"
        }
      }],
      previewVisible: false,
      previewImage: '',
      fileList: [],
    };
  },
  methods: {
    // 查询列表
    getCount() {
      let params = this.refundDetails.list.query;
      getCount(params).then(res => {
        this.refundDetails.list.total = res.data.rowCount;
        if (res.data.rowCount) {
          this.queryrefund();
        } else {
          this.refundDetails.list.results = [];
        }
      })
    },
    queryrefund() {
      let params = this.refundDetails.list.query;
      getRefundList(params).then(res => {
        this.refundDetails.list.results = res.data;
      })
    },
    getDate(){
      this.resetExport();
      let params = this.refundDetails.list.query;
      let serch = { ...params };
      serch.pageIndex = 1;
      serch.pageSize = this.refundDetails.list.total + 100;
      getRefundList(serch).then(res => {
        this.selName = [`业务编号：${this.refundDetails.list.query.stageMark}`, `查询日期: ${this.refundDetails.list.query.begin}-${this.refundDetails.list.query.end}`, `业务状态: ${this.$rformat.methods.r_refoundStatus(this.refundDetails.list.query.status)}`, " ", " ", " ", " ", " ", " "];
        let excelsource = this.comexport(this.columns, res.data);
        excelsource = this.exportDate(excelsource);
        this.excelData = [... this.excelData, ...excelsource];
        this.$refs.export.getExport(this.selName,this.excelName,this.excelData);
      })
    },
    // 新增退费
    addrefound() {
      this.modal.addvisible = true;
    },
    // 导出
    resetExport() {
      this.selName = [];
      this.excelData = [];
    },
    // 退费单号查询
    onSearch(e) {
      if (!e.target.value.trim()) {
        this.refundDetails.list.query.stageMark = 0;
      } else {
        this.refundDetails.list.query.stageMark = e.target.value;
      }
    },
    // 查看详情
    toDetail(record) {
      this.fileList = [];
      if (record.attachment != undefined) {
        if (record.attachment.length > 0) {
          this.fileList = [{
            uid: '-1',
            name: 'xxx.png',
            status: 'done',
            url: record.attachment,
          }]
        }
      }
      this.modal.detailrecord = { ...record }
      this.modal.detailrecord.applyDate = this.transForm(this.modal.detailrecord.applyDate);
      this.modal.detailvisible = true;
    },
    // 提交退费订单
    confirmOrder() {
      this.basicForm.validateFields((err, values) => {
        if (!err) {
          let params = {
            applyUserId: sessionStorage.getItem('userId'),
            attachMent: this.modal.imgUrl,
            client: values.client,
            cost: values.cost * 100,
            eId: sessionStorage.getItem('eId'),
            note: values.note,
            phone: values.phone,
            stageMark: values.stageMark,
          }
          refundApply(params).then(res => {
            if (!res.message) {
              this.resetInfo();
              this.$message.info('退费申请成功');
            } else {
              this.$message.info('申请失败,原因是：' + res.message);
            }
          })
        }
      });
    },
    descInput() {
      var txtVal = this.basicForm.getFieldValue('note').length;
      this.remnant = 128 - txtVal;
    },
    beforeUpload(file) {
      const isLt20M = file.size / 1024 / 1024 < 15;
      if (!isLt20M) {
        this.$message.error("文件不能大于15M");
      } else {
        const r = new FileReader();
        r.readAsDataURL(file);
        r.onload = e => {
          file.thumbUrl = e.target.result;
          this.imports.fileList = [file];
          this.getImgUrl(file.thumbUrl)
        };
        return false;
      }
      return false;
    },
    // 删除图片
    delImg(e) {
      this.imports.fileList = this.imports.fileList.filter(item => {
        return item.uid !== e.uid;
      })
    },
    // 失去焦点
    getrefMsg(e) {
      if (!e.target.value) {
        return;
      }
      let params = { eId: sessionStorage.getItem('eId'), stageMark: e.target.value }
      getStageMark(params).then(res => {
        if (!res.message) {
          this.modal.maxCost = res.data.cost
          this.basicForm.setFieldsValue({
            stageMark: res.data.stageMark,
            client: "",
            clientType: "个人客户",
            phone: res.data.phone,
            cost: res.data.cost
          });
        } else {
          this.$message.error(res.message);
        }
      })
    },
    resetInfo() {
      this.getCount();
      this.handleFormFieldsReset(this.basicForm);
      this.modal.addvisible = false;
      this.imports.fileList = [];
      this.remnant = 128;

    },
    // 获取图片url
    getImgUrl(thumbUrl) {
      this.imports.loading = true;
      this.imports.disabled = true
      axios.post("http://kctest.dlysfw.com/weixin/file/upload", thumbUrl.split(',')[1])
        .then(response => {
          this.modal.imgUrl = response.text;
          this.imports.loading = false;
          this.imports.disabled = false
        })
        .catch(error => {
          console.log('error', error);
          this.modal.imgUrl = error.text;
          this.imports.loading = false;
          this.imports.disabled = false
        });
    },
    dateChange(e) {
      this.refundDetails.daterange = e;
      let params = this.refundDetails.list.query;
      params.begin = e[0].format("YYYYMMDD");
      params.end = e[1].add(1, 'day').format("YYYYMMDD");
    },
    handleCancel() {
      this.previewVisible = false
    },
    handlePreview(file) {
      this.previewImage = file.url || file.thumbUrl
      this.previewVisible = true
    },
  },
  watch: {},
  created() { },
  mounted() {
    // if(!this.$route.meta.keepAlive){
    //   this.getCount();
    //     this.isFav();
    // }
    this.getCount();
    this.isFav();
  },
  activated() {
    // this.getCount();
    this.isFav();
  }
};