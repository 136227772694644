import {
    getVipCount,
    getVipList,
    validVip
} from "@/service/resManagement/index";
export default {
    //新增订单
    name: "phoneVipManagement",
    data() {
        return {
            //编辑页面数据
            phoneVipManagement: {
                list: {
                    query: {
                        pageIndex: 1,
                        pageSize: 10,
                        name: '',
                        phone: '',
                        oncePhone: '',
                        status: '',
                        unitId:sessionStorage.getItem('unitId'),
                        // 可为0,可为1，默认为1
                        vipType:1,
                        userId: sessionStorage.getItem('userId'),
                        valid:0
                    },
                    total: 0,
                    results: []
                },
                modifyTrain: false,
                modalTitle: '',
                optionItem: '',
                driverList:[],
                selectedInfo: [],
                selectedKeys: [],
                img:{
                    visible:false,
                    url:'',
                }
            },
            userId:sessionStorage.getItem('userId'),
            //表头
            columns: [{
                title: "姓名",
                dataIndex: "name"
            },
                {
                    title: "身份证号",
                    dataIndex: "certId"
                },
                {
                    title: "手机号",
                    dataIndex: "phone"
                },
                {
                    title: "驾校",
                    dataIndex: "shortId",
                    customRender:(item)=>{
                        let arr= this.phoneVipManagement.driverList.filter(index=>{
                            if (index.shortId === item){
                                return index
                            }
                        })
                        if(arr.length){
                            return arr[0].shortName
                        }else{
                            return "暂无";
                        }
                    }
                },
                {
                    title: "操作",
                    scopedSlots: {
                        customRender: "operation"
                    }
                }
            ]};
    },
    methods: {
        // 查询列表
        queryvipManage(queryBy) {
            let querys = this.phoneVipManagement.list.query;
            //判断是否为点击页码查询
            if (queryBy !== "pagination") {
                querys.pageIndex = 1;
            }
            getVipCount(querys).then((res) => {
                this.phoneVipManagement.list.total = res.data.rowCount;
                if (res.data.rowCount) {
                    // console.log('hcaxun',querys);
                    getVipList(querys).then((res) => {
                        this.phoneVipManagement.list.results = res.data;
                    });
                } else {
                    this.phoneVipManagement.list.results = [];
                    this.phoneVipManagement.list.total = 0;
                }
            });
        },
        // 审核函数isPass true 通过 false否决 isAll代表是一键通过还是一键否决 index数组长度
        isValid(record, isPass, isAll, index) {
            const { phone,unitId, valid, validUserId } = record;
            let params = {  phone,  unitId,valid,validUserId }
            isPass ? params.valid = 1 : params.valid = 2;
            validVip(params).then(res => {
                if (res.message.length) {
                    this.$message.error(res.message);
                } else {
                    let querys = this.phoneVipManagement.list.query;
                    this.$store.dispatch("StoreDataQuery/setQueryByNews",querys)
                    if (isPass && !isAll) {
                        this.queryvipManage();
                        this.$message.success('已通过');
                    } else if (!isPass && !isAll) {
                        this.queryvipManage();
                        this.$message.success('已否决');
                    } else if (isPass && isAll && index + 1 === this.phoneVipManagement.selectedKeys.length) {
                        this.queryvipManage();
                        this.$message.success('全部通过');
                    } else if (! isPass && isAll && index + 1 === this.phoneVipManagement.selectedKeys.length) {
                        this.queryvipManage();
                        this.$message.success('全部否决');
                    }
                }
            });
        },
        // 判断是否选中
        validVip(record, isPass) {
            this.isValid(record, isPass, false);
        },
        // 全部审核
        isAllPass(isPass) {
            if (this.phoneVipManagement.selectedInfo.length) {
                for (let [index, item] of new Map(this.phoneVipManagement.selectedInfo.map((item, index) => [index, item]))) {
                    this.isValid(item, isPass, true, index)
                }
            } else {
                this.$message.info("请先选择需要通过的数据");
            }
        },
        changeColor(){
            $('body').css("cssText","background-color:#fff!important");
        }
    },
    computed: {
        rowSelection() {
            const { selectedRowKeys } = this;
            return {
                onChange: (selectedRowKeys, selectedRows) => {
                    const phone = "phone";
                    this.phoneVipManagement.selectedKeys = [...selectedRowKeys];
                    selectedRows.map(item => {
                        this.phoneVipManagement.selectedInfo.push(item);
                    });
                    const r= this.phoneVipManagement.selectedInfo.reduce((all, next) => all.some((atom) => atom[phone] == next[phone]) ? all : [...all, next], []);
                    this.phoneVipManagement.selectedInfo= r.filter(item=>{
                        return  this.phoneVipManagement.selectedKeys.some((e)=>{
                            return item.phone === e;
                        });
                    });
                }
            };
        }
    },
    watch: {},
    created() {
        this.isFav();
    },
    activated() {
        this.isFav();
    },
    mounted(){
        this.changeColor();
        this.$store.dispatch("StoreDataQuery/setQueryBySchool",{'unitId':sessionStorage.getItem('unitId')}).then(res=>{
            this.phoneVipManagement.driverList=res.data;
            this.queryvipManage();
        });
    },
};