import {
  getCostList,
  addCost,
  delCost,
  modifyCost
} from "@/service/costSet/index";
export default {
  //新增订单
  name: "trainingCost",
  data() {
    return {
      //编辑页面数据
      trainingCost: {
        list: {
          query: {
            unitId: sessionStorage.getItem('unitId'),
            userId: sessionStorage.getItem('userId')
          },
          total: 0,
          results: []
        },
        costId: '',
        modifyTrain: false,
        modalTitle: '',
        optionItem: '',
      },
      userId: sessionStorage.getItem('userId'),
      basicForm: this.$form.createForm(this),
      //表头
      columns: [{
        width: 75,
        title: "序号",
        align: "center",
        scopedSlots: {
          customRender: "SerialNumber"
        }
      }, {
        title: "价格类型",
        dataIndex: "costType",
        customRender: (item) => {
          return this.$enums.costType[item];
        }
      },
      {
        title: "价格(元)",
        dataIndex: "price",
        customRender: (item) => {
          return item / 100;
        }
      },
      {
        title: "车型",
        dataIndex: "vehType",
      },
      {
        title: "科目",
        dataIndex: "stage",
        customRender: (item) => {
          return '科目' + item
        }
      },
      {
        title: "归属",
        dataIndex: "isSch",
        customRender: (item) => {
          return item ? '本校' : '非本校';
        }
      }, {
        title: "教练提成",
        dataIndex: "teaCommision",
        customRender: (item) => {
          return item / 100;
        }
      }, {
        title: "VIP优惠(元)",
        dataIndex: "vipPrice",
        customRender: (item) => {
          return item / 100;
        }
      }, {
        title: "团购优惠(元)",
        dataIndex: "groupPrice",
        customRender: (item) => {
          return item / 100;
        }
      },
      {
        title: "操作",
        scopedSlots: {
          customRender: "operation"
        }
      }
      ],
      excelName: ['训练费用'],
      selName: [],
      excelData: [],
    };
  },
  methods: {
    queryCost(queryBy) {
      let querys = this.trainingCost.list.query;
      //判断是否为点击页码查询
      if (queryBy !== "pagination") {
        querys.pageIndex = 1;
      }
      getCostList(querys).then((res) => {
        this.resetExport();
        this.trainingCost.list.results = [...res.data];
        for (let i = 0; i < this.columns.length -1; i++) {
          this.selName.push(" ")
        }
        let excelsource = this.comexport(this.columns, this.trainingCost.list.results);
        excelsource = this.exportDate(excelsource);
        this.excelData = [... this.excelData, ...excelsource];
      })
    },
    getDate() {
      this.$refs.export.getExport(this.selName,this.excelName,this.excelData);
    },
    modifyData(record) {
      this.trainingCost.costId = record.costId;
      this.trainingCost.modifyTrain = true;
      if (record) {
        this.trainingCost.modalTitle = '修改训练费用数据';
        this.trainingCost.optionItem = 'modifyData';
        this.$nextTick(() => {
          this.basicForm.setFieldsValue({
            isSch: record.isSch,
            teaCommision: record.teaCommision / 100,
            vehType: record.vehType,
            price: record.price / 100,
            groupPrice: record.groupPrice / 100,
            costType: record.costType,
            stage: record.stage,
            vipPrice: record.vipPrice / 100
          });
        })
      } else {
        this.trainingCost.modalTitle = '增加训练费用数据';
        this.trainingCost.optionItem = 'addData';
      }
    },
    // 确定按钮
    confirmRevision() {
      this.basicForm.validateFields((err, values) => {
        if (!err) {
          values.price = values.price * 100;
          values.vipPrice = values.vipPrice ? values.vipPrice * 100 : 0;
          values.groupPrice = values.groupPrice ? values.groupPrice * 100 : 0;
          values.teaCommision = values.teaCommision ? values.teaCommision * 100 : 0;
          values.unitId = this.trainingCost.list.query.unitId;
          values.costId = this.trainingCost.costId;
          this.saveDate(values);
        }
      })
    },
    saveDate(values) {
      if (this.trainingCost.optionItem === 'addData') {
        addCost(values).then((res) => {
          if (res.message.length) {
            this.$message.error(res.message)
          } else {
            this.resetInfo();
            this.$message.success('新增成功');
          }
        })
      } else {
        modifyCost(values).then((res) => {
          if (res.message.length) {
            this.$message.error(res.message)
          } else {
            this.resetInfo();
            this.$message.success('修改成功');
          }
        })
      }

      this.handleFormFieldsReset(this.basicForm);
      this.trainingCost.modifyTrain = false;
    },
    cancel() {
      this.handleFormFieldsReset(this.basicForm);
    },
    // 删除
    delData(record) {
      const { costId, unitId } = record;
      let params = { costId, unitId }
      delCost(params).then((res) => {
        if (res.message.length) {
          this.$message.error(res.message)
        } else {
          this.resetInfo();
          this.$message.success('删除成功');
        }
      })
    },
    resetInfo() {
      this.queryCost();
      this.trainingCost.modifyTrain = false;
      this.handleFormFieldsReset(this.basicForm);
    },
    resetExport() {
      this.selName = [];
      this.excelData = [];
    },
  },
  watch: {},
  created() { },
  mounted() {
    this.queryCost();
    this.isFav();
  },
  activated() {
    this.isFav();
  }
};