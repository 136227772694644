import { getTrainTotalByDateSpan } from "@/service/businessData/index";
export default {
    //报表
    name: "reportForm",
    data() {
        return {
            reportForm: {
                // 本月数
                list: {
                    query: {
                        begin: this.$moment().startOf('month').format('YYYYMMDD'),
                        end: this.$moment().format('YYYYMMDD'),
                        eId: sessionStorage.getItem('eId')
                    },
                    daterange: [this.$moment().startOf('month'), this.$moment()],
                    results: [],
                },
                //下月数 --- 相当于本月的起始时间-1，作为结束时间
                nextMonth: {
                    query: {
                        begin: this.$moment().add(-2, 'month').add(1, 'day').format('YYYYMMDD'),
                        end: this.$moment().add(-1, 'month').format('YYYYMMDD'),
                        eId: sessionStorage.getItem('eId')
                    }
                },
                // 本年累计--- 今年的1月1号
                yearDate: {
                    query: {
                        begin: this.$moment().startOf('year').format('YYYYMMDD'),
                        end: this.$moment().format('YYYYMMDD'),
                        eId: sessionStorage.getItem('eId')
                    }
                },
                lastyearDate: {
                    query: {
                        begin: this.$moment().add(-1, 'year').startOf('year').format('YYYYMMDD'),
                        end: this.$moment().add(-1, 'year').format('YYYYMMDD'),
                        eId: sessionStorage.getItem('eId')
                    }
                },
                columns: [{
                    title: '项目',
                    dataIndex: 'project',
                    key: 'project',
                    width: 100,
                    // fixed: 'left',
                    align: 'center',
                    customRender: item => {
                        return item;
                    }
                },
                {
                    title: '本月数',
                    children: [
                        {
                            title: '小时',
                            dataIndex: 'monthHour',
                            key: 'monthHour',
                            width: 100,
                            align: 'center'
                        },
                        {
                            title: '收费金额',
                            dataIndex: 'monthMoney',
                            key: 'monthMoney',
                            width: 100,
                            align: 'center'
                        },
                    ],
                },
                {
                    title: '上月数',
                    children: [
                        {
                            title: '小时',
                            dataIndex: 'lastmonthHour',
                            key: 'lastmonthHour',
                            width: 100,
                            align: 'center'
                        },
                        {
                            title: '收费金额',
                            dataIndex: 'lastmonthMoney',
                            key: 'lastmonthMoney',
                            width: 100,
                            align: 'center'
                        },
                    ],
                },
                {
                    title: '本年累计',
                    children: [
                        {
                            title: '小时',
                            dataIndex: 'yearHour',
                            key: 'yearHour',
                            width: 100,
                            align: 'center'
                        },
                        {
                            title: '收费金额',
                            dataIndex: 'yearMoney',
                            key: 'yearMoney',
                            width: 100,
                            align: 'center'
                        },
                    ],
                },
                {
                    title: '上年同期',
                    children: [
                        {
                            title: '小时',
                            dataIndex: 'lastyearHour',
                            key: 'lastyearHour',
                            width: 100,
                            align: 'center'
                        },
                        {
                            title: '收费金额',
                            dataIndex: 'lastyearMoney',
                            key: 'lastyearMoney',
                            width: 100,
                            align: 'center'
                        },
                    ],
                },
                {
                    title: '比上年增减额',
                    children: [
                        {
                            title: '小时',
                            dataIndex: 'compareHour',
                            key: 'compareHour',
                            width: 100,
                            align: 'center'
                        },
                        {
                            title: '收费金额',
                            dataIndex: 'compareMoney',
                            key: 'compareMoney',
                            width: 100,
                            align: 'center'
                        },
                    ],
                },
                {
                    title: '比上年增减率(%)',
                    children: [
                        {
                            title: '小时',
                            dataIndex: 'compareHourRatio',
                            key: 'compareHourRatio',
                            align: 'center',
                            width: 100,
                        },
                        {
                            title: '收费金额',
                            dataIndex: 'compareMoneyRatio',
                            key: 'compareMoneyRatio',
                            align: 'center',
                            width: 100,
                        }
                    ],
                },
                {
                    title: '分析',
                    dataIndex: 'analysis',
                    key: 'analysis',
                    // width: 80,
                    // fixed: 'right',
                    align: 'center'
                }],
                date: {}
            },
            newObj: [],
            fontArr:[{name:'7号',id:7},{name:'9号',id:9},{name:'11号',id:11},{name:'12号',id:12},{name:'14号',id:14},{name:'17号',id:17}],
            fontSize:9,
            userId:sessionStorage.getItem('userId'),
        };
    },
    methods: {
        // 本月数
        async  getCount() {
            let params = this.reportForm.list.query;
            await getTrainTotalByDateSpan(params).then(res => {
                this.chargeDate(res.data, this.reportForm.date, 'getCount');
            })
        },
        // 上月数
        async lastMonth() {
            let params = this.reportForm.nextMonth.query;
            this.reportForm.date.examination.lastmonthHour = '';
            this.reportForm.date.examination.lastmonthMoney = '';
            // await getTrainTotalByDateSpan(params).then(res => {
            //     // this.reportForm.date.examination.lastmonthHour = this.multie(res.data, 'costTime');
            //     // this.reportForm.date.examination.lastmonthMoney = this.multie(res.data, 'cost');
            // })
        },
        // 本年累计
        async yearDate() {
            let params = this.reportForm.yearDate.query;
            let query = this.reportForm.list.query;
            let begin = query.begin.substr(0, 4);
            let end = query.end.substr(0, 4);
            if (begin != end) {
                this.$message.warn('跨年只显示本月 详细统计只用于非跨年');
                return
            }
            await getTrainTotalByDateSpan(params).then(res => {
                this.chargeDate(res.data, this.reportForm.date, 'yearDate');
            })
        },
        // 上年同期
        async lastyearDate() {
            let params = this.reportForm.lastyearDate.query;
            let query = this.reportForm.list.query
            let begin = query.begin.substr(0, 4);
            let end = query.end.substr(0, 4);
            if (begin != end) {
                return
            }
            await getTrainTotalByDateSpan(params).then(res => {
                this.chargeDate(res.data, this.reportForm.date, 'lastyearDate');
            })
        },
        //分类统计
        chargeDate(data, element, columns) {
            if (columns == 'getCount') {
                this.typeTotal(element, 'monthHour', 'monthMoney', data)
            } else if (columns == 'yearDate') {
                this.typeTotal(element, 'yearHour', 'yearMoney', data);
            } else if (columns == 'lastyearDate') {
                this.typeTotal(element, 'lastyearHour', 'lastyearMoney', data);
            }
        },
        typeTotal(element, hour, money, data) {
            // 总合计
            element.total[hour] = this.sumToatl(data, null, 'costTime', null);
            element.total[money] = this.sumToatl(data, null, 'cost', null);
            // 银行pos
            element.bank[hour] = this.sumToatl(data, null, 'costTime', null);
            element.bank[money] = this.sumToatl(data, null, 'cost', null);
            // 考务中心本月小时和收费金额
            element.examination[hour] = this.sumToatl(data, null, 'costTime', null);
            element.examination[money] = this.sumToatl(data, null, 'cost', null);
            // 科目二本月小时和收费金额
            element.stage2count.total[hour] = this.sumToatl(data, 2, 'costTime', null);
            element.stage2count.total[money] = this.sumToatl(data, 2, 'cost', null);
            // 科目二劵本月小时和收费金额
            element.stage2count.coupon[hour] = this.sumToatl(data, 2, 'costTime', 'coupon');
            element.stage2count.coupon[money] = this.sumToatl(data, 2, 'cost', 'coupon');
            // 科目二收费本月小时和收费金额
            element.stage2count.charge[hour] = this.sumToatl(data, 2, 'costTime', 'charge');
            element.stage2count.charge[money] = this.sumToatl(data, 2, 'cost', 'charge');
            // 科目三本月小时和收费金额
            element.stage3count.total[hour] = this.sumToatl(data, 3, 'costTime', null);
            element.stage3count.total[money] = this.sumToatl(data, 3, 'cost', null);
            // 科目三劵本月小时和收费金额
            element.stage3count.coupon[hour] = this.sumToatl(data, 3, 'costTime', 'coupon');
            element.stage3count.coupon[money] = this.sumToatl(data, 3, 'cost', 'coupon');
            // 科目三收费本月小时和收费金额
            element.stage3count.charge[hour] = this.sumToatl(data, 3, 'costTime', 'charge');
            element.stage3count.charge[money] = this.sumToatl(data, 3, 'cost', 'charge');
        },
        sumToatl(data, stage, type, part) {
            let ret =  data.map(item => {
                if (!stage) {
                    return item[type];
                } else {
                    if (!part) {
                        if (item.stage == stage) {
                            return item[type];
                        }
                    } else {
                        if (part == 'coupon') {
                            if (item.stage == stage && item.cType == 2) {
                                return item[type];
                            }
                        } else {
                            if (item.stage == stage && (item.cType == 0 || item.cType == 3)) {
                                return item[type];
                            }
                        }

                    }
                }

            }).filter(item => { return item }).reduce((accumulator, currentValue) => {
                return accumulator + currentValue;
            }, 0)
            return type == 'costTime' ? ret.toFixed(1): ret.toFixed(2)
        },
        // 修改日期
        changeDate(e) {
            this.reportForm.list.query.begin = this.$moment(e[0]).format('YYYYMMDD');
            this.reportForm.list.query.end = this.$moment(e[1]).format('YYYYMMDD');
            this.reportForm.yearDate.query.begin = this.$moment(e[0]).startOf('year').format('YYYYMMDD');
            this.reportForm.yearDate.query.end = this.$moment(e[1]).format('YYYYMMDD');
            this.reportForm.lastyearDate.query.begin = this.$moment(e[0]).add(-1, 'year').startOf('year').format('YYYYMMDD');
            this.reportForm.lastyearDate.query.end = this.$moment(e[1]).add(-1, 'year').format('YYYYMMDD');
        },
        calculationVal(yearHour, lastyearHour, type) {
            let val = '';
            if (type == 'costTime') {
                val = (yearHour - lastyearHour).toFixed(1);
            } else if (type == 'cost') {
                val = (yearHour - lastyearHour).toFixed(2);
            } else if (type == 'hourRatio' || type == 'moneyRatio') {
                if(yearHour == 0 && lastyearHour == 0){
                    val = '0%';
                 }else{
                     if(lastyearHour == 0){
                        val = "--";
                     }else{
                        val = ((yearHour - lastyearHour) / lastyearHour * 100).toFixed(2) + "%";
                     }
                 }
            }
            return val;
        },
        calculationContact(element) {
            element.compareHour = this.calculationVal(element.yearHour, element.lastyearHour, 'costTime');
            element.compareMoney = this.calculationVal(element.yearMoney, element.lastyearMoney, 'cost');
            element.compareHourRatio = this.calculationVal(element.yearHour, element.lastyearHour, 'hourRatio');
            element.compareMoneyRatio = this.calculationVal(element.yearMoney, element.lastyearMoney, 'moneyRatio');
        },
        resetVal(element) {
            element.compareHour = '';
            element.compareMoney = '';
            element.compareHourRatio = '';
            element.compareMoneyRatio = '';
        },
        initVal() {
            this.reportForm.list.results = [];
            this.newObj = [];
            this.reportForm.date = {
                examination: { id: 1, project: '考务中心' , monthHour: '', monthMoney: '', lastmonthHour: '', lastmonthMoney: '', yearHour: '', yearMoney: '', lastyearHour: '', lastyearMoney: '', compareHour: '', compareMoney: '', compareHourRatio: '', compareMoneyRatio: ''},
                stage2count: {
                    total: { id: 2, project: '科二', monthHour: '', monthMoney: '', lastmonthHour: '', lastmonthMoney: '', yearHour: '', yearMoney: '', lastyearHour: '', lastyearMoney: '', compareHour: '', compareMoney: '', compareHourRatio: '', compareMoneyRatio: '' },
                    coupon: { id: 3, project: '其中：劵', monthHour: '', monthMoney: '', lastmonthHour: '', lastmonthMoney: '', yearHour: '', yearMoney: '', lastyearHour: '', lastyearMoney: '', compareHour: '', compareMoney: '', compareHourRatio: '', compareMoneyRatio: '' },
                    charge: { id: 4, project: '收费', monthHour: '', monthMoney: '', lastmonthHour: '', lastmonthMoney: '', yearHour: '', yearMoney: '', lastyearHour: '', lastyearMoney: '', compareHour: '', compareMoney: '', compareHourRatio: '', compareMoneyRatio: '' },
                },
                stage3count: {
                    total: { id: 5, project: '科三', monthHour: '', monthMoney: '', lastmonthHour: '', lastmonthMoney: '', yearHour: '', yearMoney: '', lastyearHour: '', lastyearMoney: '', compareHour: '', compareMoney: '', compareHourRatio: '', compareMoneyRatio: '' },
                    coupon: { id: 6, project: '其中：劵', monthHour: '', monthMoney: '', lastmonthHour: '', lastmonthMoney: '', yearHour: '', yearMoney: '', lastyearHour: '', lastyearMoney: '', compareHour: '', compareMoney: '', compareHourRatio: '', compareMoneyRatio: '' },
                    charge: { id: 7, project: '收费', monthHour: '', monthMoney: '', lastmonthHour: '', lastmonthMoney: '', yearHour: '', yearMoney: '', lastyearHour: '', lastyearMoney: '', compareHour: '', compareMoney: '', compareHourRatio: '', compareMoneyRatio: '' },
                },
                tx: { id: 15, project: '塘汛', monthHour: '', monthMoney: '', lastmonthHour: '', lastmonthMoney: '', yearHour: '', yearMoney: '', lastyearHour: '', lastyearMoney: '', compareHour: '', compareMoney: '', compareHourRatio: '', compareMoneyRatio: '' },
                other: {
                    total: { id: 8, project: '科二', monthHour: '', monthMoney: '', lastmonthHour: '', lastmonthMoney: '', yearHour: '', yearMoney: '', lastyearHour: '', lastyearMoney: '', compareHour: '', compareMoney: '', compareHourRatio: '', compareMoneyRatio: '' },
                    coupon: { id: 9, project: '其中：劵', monthHour: '', monthMoney: '', lastmonthHour: '', lastmonthMoney: '', yearHour: '', yearMoney: '', lastyearHour: '', lastyearMoney: '', compareHour: '', compareMoney: '', compareHourRatio: '', compareMoneyRatio: '' },
                    charge: { id: 10, project: '收费', monthHour: '', monthMoney: '', lastmonthHour: '', lastmonthMoney: '', yearHour: '', yearMoney: '', lastyearHour: '', lastyearMoney: '', compareHour: '', compareMoney: '', compareHourRatio: '', compareMoneyRatio: '' },
                },
                total: { id: 11, project: '总合计', monthHour: '', monthMoney: '', lastmonthHour: '', lastmonthMoney: '', yearHour: '', yearMoney: '', lastyearHour: '', lastyearMoney: '', compareHour: '', compareMoney: '', compareHourRatio: '', compareMoneyRatio: '' },
                cash: { id: 12, project: '其中：现金', monthHour: '', monthMoney: '', lastmonthHour: '', lastmonthMoney: '', yearHour: '', yearMoney: '', lastyearHour: '', lastyearMoney: '', compareHour: '', compareMoney: '', compareHourRatio: '', compareMoneyRatio: '' },
                bank: { id: 13, project: '银行(POS)', monthHour: '', monthMoney: '', lastmonthHour: '', lastmonthMoney: '', yearHour: '', yearMoney: '', lastyearHour: '', lastyearMoney: '', compareHour: '', compareMoney: '', compareHourRatio: '', compareMoneyRatio: '' }
            }
        },
        transEntry(entry) {
            for (let item in entry) {
                if (typeof entry[item] == 'object') {
                    this.transEntry(entry[item]);
                } else {
                    let doble = this.newObj.filter(item => { return item.id == entry.id }).length;
                    if (!doble) {
                        this.newObj.push(entry);
                    }
                }
            }
        },
        // 修改颜色
        trColor(record, index) {
            let className = 'defaultStyle'
            if (record.project != '其中：劵' && record.project != '收费') {
                className = 'bolderRow defaultStyle'
            }
            return className;
        },
        // 字体变化
        fontChange(val){
            $('.defaultStyle td').css('fontSize',val)
        },
        getExportDate() {
            this.$nextTick(()=>{
                let dateTime = this.reportForm.list.query;
                let strTime =`${dateTime.begin.substr(0, 4)}/${dateTime.begin.substr(4, 2)}/${dateTime.begin.substr(6, 2)} -${dateTime.end.substr(0, 4)}/${dateTime.end.substr(4, 2)}/${dateTime.end.substr(6, 2)}`
                this.$refs.export.getContact(this.reportForm.list.results, strTime,  this.fontSize);
            })
        },
        async getDate() {
            this.initVal();
            await this.getCount();
            await this.lastMonth();
            await this.yearDate();
            await this.lastyearDate();
            let query = this.reportForm.list.query
            let begin = query.begin.substr(0, 4);
            let end = query.end.substr(0, 4);
            if (begin == end) {
                this.calculationContact(this.reportForm.date.examination);
                this.calculationContact(this.reportForm.date.stage2count.total);
                this.calculationContact(this.reportForm.date.stage3count.total);
                this.calculationContact(this.reportForm.date.total);
                this.calculationContact(this.reportForm.date.bank);
            } else {
                this.resetVal(this.reportForm.date.examination);
            }
            this.transEntry(this.reportForm.date);
            this.reportForm.list.results = JSON.parse(JSON.stringify(this.newObj));
        }
    },
    watch: {},
    created() { },
    mounted() {
        this.getDate();
        this.isFav();
    },
    activated() {
   this.isFav();
    }
};